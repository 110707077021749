import { useCollectionFilterDialogStyle } from './useCollectionFilterDialogStyle';
import {
  Box, Drawer,
  Typography,
  // Checkbox
} from '@material-ui/core';
import { CloseIcon } from 'modules/Profile/assets/CloseIcon';
import Filters from 'modules/Profile/assets/filter.svg';
import classNames from 'classnames';
import Switch from '@material-ui/core/Switch';
import React, { useState, useEffect } from 'react';
// import { CollectionInfoInterface } from 'modules/Collection/interface/collectionInterface';
import { Currency } from 'modules/Explore/components/currency';
import { useDispatchRequest } from '@redux-requests/react';
// import { getExploreItems } from 'modules/Explore/actions/explore';
import { getCollectionNFTList } from '../../actions/collectionAction';
import { t } from '../../../i18n/utils/intl';
import { NftAttributes } from '../NftAttributes';
import { useHistory } from 'react-router-dom';

interface ICardsListProps {
  openDialog: boolean,
  onDialogClose: () => void,
  // collectionList: CollectionInfoInterface[],
  price: string,
  contractAddress: string;
  attributesObj: any
  setPage: React.Dispatch<React.SetStateAction<number>>
}

export const CollectionFilterDialog = (
  {
    openDialog,
    onDialogClose,
    // collectionList,
    price,
    contractAddress,
    attributesObj,
    setPage
  }: ICardsListProps) => {
  const classes = useCollectionFilterDialogStyle();
  const [openOnSale, setOpenOnSale] = useState(false);
  const [openAuction, setOpenAuction] = useState(false);
  const [saleType, setSaleType] = useState('');


  const [currency, setCurrency] = useState<{ [key in string]: boolean }>({});
  const [currencyList, setCurrencyList] = useState('');

  const [attributes, setAttributes] = useState<{ [key in string]: boolean }>({});
  const [attributesList, setAttributesList] = useState('');

  const dispatch = useDispatchRequest();
  const history = useHistory();

  const onSwitchSaleChange = () => {
    setOpenOnSale(!openOnSale);
  };
  const onSwitchAuctionChange = () => {
    setOpenAuction(!openAuction);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setParamets = (type: string, value: string) => {
    const queryParameters = new URLSearchParams(window.location.search);
    const collectedFilterOnSale = queryParameters.get('collectedFilterOnSale') ?? '';
    const collectedFilterAuction = queryParameters.get('collectedFilterAuction') ?? '';
    const collectedFilterType = queryParameters.get('collectedFilterType') ?? '';
    const collectedFilterCurrency = queryParameters.get('collectedFilterCurrency') ?? '';
    const collectedFilterCurrencyList = queryParameters.get('collectedFilterCurrencyList') ?? '';
    const collectedFilterAttributes = queryParameters.get('collectedFilterAttributes') ?? '';
    const collectedFilterattributesList = queryParameters.get('collectedFilterattributesList') ?? '';
    const tab = queryParameters.get('tab') ?? '';
    const address = queryParameters.get('address') ?? '';

    let params = new URLSearchParams();
    params.append('tab', tab);
    params.append('address', address);
    (collectedFilterOnSale || type === 'collectedFilterOnSale') && params.append('collectedFilterOnSale', type === 'collectedFilterOnSale' ? value : collectedFilterOnSale);
    (collectedFilterAuction || type === 'collectedFilterAuction') && params.append('collectedFilterAuction', type === 'collectedFilterAuction' ? value : collectedFilterAuction);
    (collectedFilterType || type === 'collectedFilterType') && params.append('collectedFilterType', type === 'collectedFilterType' ? value : collectedFilterType);
    (collectedFilterCurrency || type === 'collectedFilterCurrency') && params.append('collectedFilterCurrency', type === 'collectedFilterCurrency' ? value : collectedFilterCurrency);
    (collectedFilterCurrencyList || type === 'collectedFilterCurrencyList') && params.append('collectedFilterCurrencyList', type === 'collectedFilterCurrencyList' ? value : collectedFilterCurrencyList);
    (collectedFilterAttributes || type === 'collectedFilterAttributes') && params.append('collectedFilterAttributes', type === 'collectedFilterAttributes' ? value : collectedFilterAttributes);
    (collectedFilterattributesList || type === 'collectedFilterattributesList') && params.append('collectedFilterattributesList', type === 'collectedFilterattributesList' ? value : collectedFilterattributesList);
    history.replace(window.location.pathname + '?' + params)
  }

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const collectedFilterOnSale = queryParameters.get('collectedFilterOnSale') ?? '';
    const collectedFilterAuction = queryParameters.get('collectedFilterAuction') ?? '';
    const collectedFilterType = queryParameters.get('collectedFilterType') ?? '';
    const collectedFilterCurrency = queryParameters.get('collectedFilterCurrency') ?? '';
    const collectedFilterCurrencyList = queryParameters.get('collectedFilterCurrencyList') ?? '';
    const collectedFilterAttributes = queryParameters.get('collectedFilterAttributes') ?? '';
    const collectedFilterattributesList = queryParameters.get('collectedFilterattributesList') ?? '';

    collectedFilterOnSale && setOpenOnSale(collectedFilterOnSale === 'true' ? true : false);
    collectedFilterAuction && setOpenAuction(collectedFilterAuction === 'true' ? true : false);
    collectedFilterType && setSaleType(collectedFilterType);
    collectedFilterCurrency && setCurrency(JSON.parse(collectedFilterCurrency));
    collectedFilterCurrencyList && setCurrencyList(collectedFilterCurrencyList);
    collectedFilterAttributes && setAttributes(JSON.parse(collectedFilterAttributes));
    collectedFilterattributesList && setAttributesList(collectedFilterattributesList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let fillType = 'All';
    if (openOnSale && openAuction) {
      setSaleType('All');
      fillType = 'All'
    } else if (openOnSale) {
      setSaleType('Fixed');
      fillType = 'Fixed'
    } else if (openAuction) {
      setSaleType('Auction');
      fillType = 'Auction'
    } else if (!openOnSale && !openAuction) {
      setSaleType('');
      fillType = ''
      sessionStorage.setItem('collectedFilterType', 'All');
    }
    setParamets('collectedFilterType', fillType)
    setParamets('collectedFilterAuction', openAuction.toString())
    setParamets('collectedFilterOnSale', openOnSale.toString())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAuction, openOnSale]);

  useEffect(() => {
    setParamets('collectedFilterCurrencyList', currencyList);
    setParamets('collectedFilterattributesList', attributesList);
    setPage(1);
    dispatch(getCollectionNFTList({
      list_type: saleType,
      order_by: price,
      currency_addresses: currencyList,
      properties: attributesList,
      off_index: 1,
      off_size: 50,
      // addressList: addressList,
      address: contractAddress,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, saleType, currencyList, contractAddress, attributesList]);

  useEffect(() => {
    if (currency) {
      setParamets('collectedFilterCurrency', JSON.stringify(currency));
    }
    if (attributes) {
      setParamets('collectedFilterAttributes', JSON.stringify(attributes));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, attributes]);

  return (
    <Box className={classes.root}>
      <Drawer
        className={classes.drawer}
        ModalProps={{
          BackdropProps: {
            classes: {
              root: classes.drawerBackdrop,
            },
          },
        }}
        classes={{
          paperAnchorRight: classes.drawerPaper,
        }}
        elevation={0}
        anchor='right'
        open={openDialog}
        onClose={onDialogClose}
      >
        <Typography variant='h3' className={classes.titles}>
          <Typography className={classes.filterImg}><img src={Filters} alt='' onClick={onDialogClose} /></Typography>
        </Typography>
        <Box className={classes.close} onClick={onDialogClose}><Typography><CloseIcon /></Typography></Box>
        <div className={classNames(classes.filterRoot)}>
          <Box display={'flex'} justifyContent={'space-between'} className={classes.switchContent}>
            <Box>
              <Typography className={classes.saleTitle}>On Sale</Typography>
              <Switch
                classes={{
                  root: classes.switchRoot,
                  checked: classes.switchChecked,
                  track: classes.switchTrack,
                  switchBase: classes.switchBase,
                }}
                checked={openOnSale}
                onChange={onSwitchSaleChange}
              />
            </Box>
            <Box>
              <Typography className={classes.saleTitle}>{t('collection.onAuction')}</Typography>
              <Switch
                classes={{
                  root: classes.switchRoot,
                  checked: classes.switchChecked,
                  track: classes.switchTrack,
                  switchBase: classes.switchBase,
                }}
                checked={openAuction}
                onChange={onSwitchAuctionChange}
              />
            </Box>
          </Box>
          <Box>
          </Box>
          {/*<Box className={classes.filter}>*/}
          {/*  {collectionList.length > 0 && collectionList.map((item) => {*/}
          {/*    return <Box className={classes.collectionItem} key={item.contract}>*/}
          {/*      <Typography className={classes.collectionMsg}>*/}
          {/*        <Checkbox*/}
          {/*          checked={state[item.contract] || false}*/}
          {/*          className={classes.checkouts}*/}
          {/*          onChange={handleChange}*/}
          {/*          name={item.contract}*/}
          {/*          classes={{*/}
          {/*            root: classes.chectboxRoot,*/}
          {/*            checked: classes.chectboxChecked,*/}
          {/*          }}*/}
          {/*        />*/}
          {/*        <img src={item.logoImg} alt="" />{item.name}</Typography>*/}
          {/*      /!* <Typography className={classes.collectionNum}>{item.order}</Typography> *!/*/}
          {/*    </Box>*/}
          {/*  })}*/}
          {/*</Box>*/}
          <Currency setCurrency={setCurrency} currency={currency} setCurrencyList={setCurrencyList} />
          <NftAttributes
            setAttributes={setAttributes}
            attributes={attributes}
            attributesObj={attributesObj}
            setAttributesList={setAttributesList} />
        </div>
      </Drawer>
    </Box>
  );
};
