import { Route, Switch, Redirect } from 'react-router-dom';
import { DFduckLayout } from './modules/layout/components/DFduckLayout';
import { PageNotFound } from './modules/router/components/PageNotFound';
import { PageComingSoon } from './modules/router/components/PageComingSoon';
import { CurrentTheme } from './modules/themes/types';
import { DFdunkRoutes, DFdunkRoutesConfig } from 'modules/Home/Routes';
import { EventRoutes, EventRoutesConfig } from 'modules/Events/Routes';
import { CollectionRoutes, CollectionRoutesConfig } from './modules/Collection/Routes';
import { AnnouncementRoutes, AnnouncementRoutesConfig } from 'modules/Announcement/Routes';
import { ProfileRoutes, ProfileRoutesConfig } from 'modules/Profile/ProfileRoutes';
import { NftDetailRoutes, NftDetailRoutesConfig } from 'modules/NftDetail/NftDetailRoutes';
import { MarketRoutes, MarketRoutesConfig } from './modules/Market/Routes';
import { CollectionDetailRoutes, CollectionDetalilRoutesConfig } from 'modules/CollectionDetail/Routes';
import { CollectionListRoutesConfig, CollectionNFTListRoutes } from 'modules/Explore/Routes';
import { RichTextDetailRoutes, RichTextDetailRoutesConfig } from './modules/RichTextDetail/Routes';
import { DFdunkTermsRoutes,DFdunkPrivacyRoutes, DFdunkTermsRoutesConfig,DFdunkPrivacyRoutesConfig } from 'modules/Terms/Routes';

export function Routes() {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => <Redirect to={MarketRoutesConfig.Market.path} />}
      />
      <Route
        exact
        path={DFdunkRoutesConfig.Index.path}
        render={() => (
          <DFduckLayout headerTheme={CurrentTheme}>
            <DFdunkRoutes />
          </DFduckLayout>
        )}
      />
      <Route
        exact
        path={DFdunkTermsRoutesConfig.Index.path}
        render={() => (
          <DFduckLayout headerTheme={CurrentTheme}>
            <DFdunkTermsRoutes />
          </DFduckLayout>
        )}
      />
      <Route
        exact
        path={DFdunkPrivacyRoutesConfig.Index.path}
        render={() => (
          <DFduckLayout headerTheme={CurrentTheme}>
            <DFdunkPrivacyRoutes />
          </DFduckLayout>
        )}
      />
      <Route
        exact
        path={RichTextDetailRoutesConfig.Index.path}
        render={() => (
          <DFduckLayout headerTheme={CurrentTheme}>
            <RichTextDetailRoutes />
          </DFduckLayout>
        )}
      />

      <Route
        exact
        path={MarketRoutesConfig.Market.path}
        render={() => (
          <DFduckLayout headerTheme={CurrentTheme}>
            <MarketRoutes />
          </DFduckLayout>
        )}
      />
      <Route
        exact
        path={AnnouncementRoutesConfig.Announcement.path}
        render={() => (
          <DFduckLayout headerTheme={CurrentTheme}>
            <AnnouncementRoutes />
          </DFduckLayout>
        )}
      />

      <Route
        exact
        path={EventRoutesConfig.Event.path}
        render={() => (
          <DFduckLayout headerTheme={CurrentTheme}>
            <EventRoutes />
          </DFduckLayout>
        )}
      />
      <Route
        exact
        path={CollectionRoutesConfig.Collection.path}
        render={() => (
          <DFduckLayout headerTheme={CurrentTheme}>
            <CollectionRoutes />
          </DFduckLayout>
        )}
      />


      <Route
        exact
        path={[
          ProfileRoutesConfig.UserProfile.path,
          ProfileRoutesConfig.UserEdit.path
        ]}
        render={() => (
          <DFduckLayout headerTheme={CurrentTheme}>
            <ProfileRoutes />
          </DFduckLayout>
        )}
      />

      <Route
        exact
        path={NftDetailRoutesConfig.NftDetail.path}
        render={() => (
          <DFduckLayout headerTheme={CurrentTheme}>
            <NftDetailRoutes />
          </DFduckLayout>
        )}
      />
      <Route
        exact
        path={CollectionDetalilRoutesConfig.nftList.path}
        render={() => (
          <DFduckLayout headerTheme={CurrentTheme}>
            <CollectionDetailRoutes />
          </DFduckLayout>
        )}
      />
      <Route
        exact
        path={CollectionListRoutesConfig.nftList.path}
        render={() => (
          <DFduckLayout headerTheme={CurrentTheme}>
            <CollectionNFTListRoutes />
          </DFduckLayout>
        )}
      />


      <Route
        exact
        path={'/dfdunk/coming_soon'}
        render={() => (
          <DFduckLayout headerTheme={CurrentTheme}>
            <PageComingSoon />
          </DFduckLayout>
        )}
      />



      <Route
        render={() => (
          <DFduckLayout headerTheme={CurrentTheme}>
            <PageNotFound />
          </DFduckLayout>
        )}
      />
    </Switch>
  );
}
