import { useLogoStyles } from './useLogoStyle';
import { Link } from 'react-router-dom';
import { MarketRoutesConfig } from 'modules/Market/Routes';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useQuery } from '@redux-requests/react';
import { getDfdunkLogo, IDfdunkLogoData } from 'modules/common/actions/commonRequest';
// import { useLocale } from 'modules/i18n/utils/useLocale';

interface LogoCompInterface {
  setLogoData?: React.Dispatch<React.SetStateAction<IDfdunkLogoData | null>>
}
export const LogoComp = (
  { setLogoData }: LogoCompInterface
) => {
  const classes = useLogoStyles();
  // const { locale } = useLocale();
  const { data: logoData } = useQuery<IDfdunkLogoData | null>({
    type: getDfdunkLogo.toString(),
  });
  useEffect(() => {
    if (logoData) {
      setLogoData && setLogoData(logoData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoData])
  return (
    <Link
      to={MarketRoutesConfig.Market.path}
      className={classNames(classes.root)}
    >
      <img
        alt={process.env.REACT_APP_BASE_ENV_TITLE}
        className={classNames(
          classes.logo,
          // process.env.REACT_APP_BASE_ENV_BRANCH === 'ACTRESS' && classes.diflogo
        )}
        src={logoData ? logoData.logo : ''}
      />
      {/* <span className={classNames(classes.projectName)}>
        {locale === 'en-US' ? logoData?.nameEn : locale === 'zh-CN' ? logoData?.nameZh : locale === 'ja-JP' ? logoData?.nameJp : ''}
      </span> */}
    </Link>
  );
};
