import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { generatePath, Route, useParams } from 'react-router-dom';

const PATH_HISTORY_DETAIL = '/:page/detail/:id';

export const RichTextDetailRoutesConfig: { [key: string]: RouteConfiguration } = {
  Index: {
    path: PATH_HISTORY_DETAIL,
    generatePath: (page: any, id: any) =>
      generatePath(PATH_HISTORY_DETAIL, { page: page, id: id }),
    useParams: () => {
      const { page, id } = useParams<{ page: string, id: string }>();
      return {
        page,
        id,
      };
    },
  },
};

const LoadableRichTextDetailContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens/RichTextDetail').then(module => module.RichTextDetail),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export function RichTextDetailRoutes() {
  return (
    <Route
      path={RichTextDetailRoutesConfig.Index.path}
      exact={true}
      component={LoadableRichTextDetailContainer}
    />
  );
}
