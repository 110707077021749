import React from 'react';
import {t} from 'modules/i18n/utils/intl';
import {useShowMoreStyle} from './useShowMoreStyle';
// @ts-ignore
import ShowMoreText from "react-show-more-text";
interface ShowMoreInterface {
  description: string;
  lines?: number
}


export const ShowMoreComp = (
  {
    description,
    lines = 2
  }: ShowMoreInterface
) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const classes = useShowMoreStyle();
  return (
    <ShowMoreText
      lines={lines}
      more={t('public.showMore')}
      less={t('public.showLess')}
      className={classes.root}
      anchorClass={classes.showMoreText}
      onClick={() => {
        setCollapsed(!collapsed)
      }}
      expanded={collapsed}
      truncatedEndingComponent={"... "}
    >
      {description}
    </ShowMoreText>
  )
}
