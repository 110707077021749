import { makeStyles, Theme } from '@material-ui/core';
import { CurrentTheme, Themes } from '../../../themes/types';
import DarkBorder from 'assets/img/market/Border.png';
import LightBorder from 'assets/img/market/light-border.png';
export const useViewAllStyle = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: '16px',
    fontFamily: 'Pretendard',
    fontStyle: 'normal',
    color: theme.palette.common.white,
    padding: '6px 20px',
    cursor: 'pointer',
    backgroundImage: `url(${CurrentTheme === Themes.dark ? DarkBorder : LightBorder})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    backgroundColor: 'transparent',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  text: {
    // marginLeft: '10px',
  },
  loadMore: {
    width: '15px',
    height: '15px',
    '-webkit-transform': 'rotate(360deg)',
    transform: 'rotate(360deg)',
    '-webkit-transition': '-webkit-transform 1s linear',
    transition: 'transform 1s linear',
  },

}));
