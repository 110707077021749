import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { useUserAddressStyle } from './useUserAddressStyle';
import { CopyToClip } from '../../../CopyToClip';
import LightLogOutImg from 'assets/img/header/light-logout.png';
import DarkLogOutImg from 'assets/img/header/dark-logout.png';
import { useDispatch } from 'react-redux';
import { disconnect } from '../../../../../account/store/actions/disconnect';
import { useHistory } from 'react-router-dom';
import { IProfileData } from 'modules/Profile/actions/profile';
import AvatarImg from '../../image/avatar.png';
import { CurrentTheme, Themes } from 'modules/themes/types';
interface UserAddressInterface {
  address?: string;
  toggleDrawer?: (e: any) => void;
  dataItem: IProfileData
}

export const UserAddress = (
  {
    address,
    toggleDrawer,
    dataItem,
  }: UserAddressInterface) => {
  const classes = useUserAddressStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const disconnectClick = useCallback(() => {
    dispatch(disconnect());
    history.push('/');
    toggleDrawer && toggleDrawer('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history]);
  return (
    <Box className={classes.root}>

      <Box className={classes.left}>
        <img className={classes.avatar}
             src={(dataItem && dataItem.image) || AvatarImg} alt='' />
        {address && (
          <Box className={classes.addressWrap}>
            <Box
              className={classes.address}>
              {/*{address.substring(0, 6)}...{address.substring(address.length - 6, address.length)}*/}
              {(dataItem && dataItem.username) || 'Unnamed'}
            </Box>
            <CopyToClip address={address} className={'userAddress'} />
          </Box>
        )}
      </Box>
      <img onClick={disconnectClick} className={classes.logOutImg}
           src={CurrentTheme === Themes.dark ? DarkLogOutImg : LightLogOutImg} alt='' />
    </Box>
  );
};

