import classNames from 'classnames';
import { useCollectedFilterStyle } from './useCollectedFilterStyle';
import { Box, Typography, Checkbox } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { useState, useEffect } from 'react';
import { useDispatchRequest } from '@redux-requests/react';
import { useAccount } from 'modules/account/hooks/useAccount';
import { getUserCollected } from 'modules/Profile/actions/profile';
import { CollectionInfoInterface } from 'modules/Collection/interface/collectionInterface';
import { t } from '../../../i18n/utils/intl';
import { useHistory } from 'react-router-dom';
interface IHeaderProps {
  className?: string;
  collectionList: CollectionInfoInterface[]
}

export interface IDescFormValues {
  collectionName: string;
}

export const CollectedFilter = ({
  className,
  collectionList
}: IHeaderProps) => {
  const classes = useCollectedFilterStyle();
  const [openFixed, setOpenFixed] = useState(false);
  const [openAuction, setOpenAuction] = useState(false);
  const [saleType, setSaleType] = useState('none');
  const [state, setState] = useState<{ [key in string]: boolean }>({});

  const [addressList, setAddressList] = useState('');

  const { address } = useAccount();
  const dispatch = useDispatchRequest();
  const onSwitchChange = () => {
    setOpenFixed(!openFixed);
  };
  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setParamets = (type: string, value: string) => {
    const queryParameters = new URLSearchParams(window.location.search);
    const profileFilterOnSale = queryParameters.get('profileFilterOnSale') ?? '';
    const profileFilterAuction = queryParameters.get('profileFilterAuction') ?? '';
    const profileFilterType = queryParameters.get('profileFilterType') ?? '';
    const profileFilterState = queryParameters.get('profileFilterState') ?? '';
    const profileFilterAddressArr = queryParameters.get('profileFilterAddressArr') ?? '';
    const tab = queryParameters.get('tab') ?? 'collections';

    let params = new URLSearchParams();
    params.append('tab', tab);
    (profileFilterOnSale || type === 'profileFilterOnSale') && params.append('profileFilterOnSale', type === 'profileFilterOnSale' ? value : profileFilterOnSale);
    (profileFilterAuction || type === 'profileFilterAuction') && params.append('profileFilterAuction', type === 'profileFilterAuction' ? value : profileFilterAuction);
    (profileFilterType || type === 'profileFilterType') && params.append('profileFilterType', type === 'profileFilterType' ? value : profileFilterType);
    (profileFilterState || type === 'profileFilterState') && params.append('profileFilterState', type === 'profileFilterState' ? value : profileFilterState);
    (profileFilterAddressArr || type === 'profileFilterAddressArr') && params.append('profileFilterAddressArr', type === 'profileFilterAddressArr' ? value : profileFilterAddressArr);

    history.replace(window.location.pathname + '?' + params)
  }

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const profileFilterOnSale = queryParameters.get('profileFilterOnSale') ?? '';
    const profileFilterAuction = queryParameters.get('profileFilterAuction') ?? '';
    const profileFilterType = queryParameters.get('profileFilterType') ?? '';
    const profileFilterState = queryParameters.get('profileFilterState') ?? '';
    const profileFilterAddressArr = queryParameters.get('profileFilterAddressArr') ?? '';

    console.log(profileFilterState);
    profileFilterOnSale && setOpenFixed(profileFilterOnSale === 'true' ? true : false);
    profileFilterAuction && setOpenAuction(profileFilterAuction === 'true' ? true : false);
    profileFilterType && setSaleType(profileFilterType);
    profileFilterState && setState(JSON.parse(profileFilterState));
    profileFilterAddressArr && setAddressList(profileFilterAddressArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const profileFilterState = queryParameters.get('profileFilterState') ?? '';
    if (collectionList.length > 0) {
      let stateList: { [key in string]: boolean } = {}
      collectionList.map((item) => {
        stateList[item.contract] = false;
        return '';
      })
      if (!profileFilterState) {
        setState(stateList)
      }
    }
  }, [collectionList]);

  const onSwitchChangeAuction = () => {
    setOpenAuction(!openAuction);

  };
  // console.log(state);

  useEffect(() => {
    let fillType = 'All';
    if ((openFixed && openAuction)) {
      setSaleType('all');
      fillType = 'All';
    } else if (openFixed) {
      setSaleType('fixed')
      fillType = 'fixed';
    } else if (openAuction) {
      setSaleType('auction');
      fillType = 'auction';
    } else if (!openFixed && !openAuction) {
      setSaleType('none')
      fillType = 'none';
    }
    setParamets('profileFilterType', fillType);
    setParamets('profileFilterAuction', openAuction.toString());
    setParamets('profileFilterOnSale', openFixed.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAuction, openFixed]);

  useEffect(() => {
    if (saleType) {
      console.log(saleType);
      dispatch(getUserCollected({ address: address, saleType: saleType, page: 1, pagesize: 100, collectionAddress: addressList }));
    }
  }, [dispatch, saleType, address, addressList]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updateState = { ...state, [event.target.name]: event.target.checked };
    setState(updateState);
    setParamets('profileFilterState', JSON.stringify(updateState));
    let addressArr = '';
    for (let key in updateState) {
      if (updateState[key]) {
        if (addressArr === '') {
          addressArr += key;
        } else {
          addressArr += ('&collectionAddress=' + key);
        }

      }
    }
    console.log(encodeURI(addressArr));
    setParamets('profileFilterAddressArr', addressArr);
    setAddressList(addressArr);
  };

  return (
    <Box className={classNames(classes.root, className)} mr={1}>
      <Box display={'flex'} justifyContent={'space-between'} className={classes.switchContent}>
        <Box>
          <Typography className={classes.saleTitle}>On Sale</Typography>
          <Switch
            classes={{
              root: classes.switchRoot,
              checked: classes.switchChecked,
              track: classes.switchTrack,
            }}
            checked={openFixed}
            onChange={onSwitchChange}
          />
        </Box>
        <Box>
          <Typography className={classes.saleTitle}>{t('collection.onAuction')}</Typography>
          <Switch
            classes={{
              root: classes.switchRoot,
              checked: classes.switchChecked,
              track: classes.switchTrack,
            }}
            checked={openAuction}
            onChange={onSwitchChangeAuction}
          />
        </Box>
      </Box>
      <Box>
        {collectionList.length > 0 && collectionList.map((item) => {
          return <Box className={classes.collectionItem} key={item.contract}>
            <Typography className={classes.collectionMsg}>
              <Checkbox checked={state[item.contract] || false} className={classes.checkouts} onChange={handleChange} name={item.contract} />
              <img src={item.image} alt="" />{item.name}</Typography>
            {/* <Typography className={classes.collectionNum}>{item.order}</Typography> */}
          </Box>
        })}
      </Box>
    </Box>
  );
};
