import { makeStyles, Theme } from '@material-ui/core';

export const useCollectedStyles = makeStyles<Theme>(theme => ({
  root: {},
  result: {
    fontSize: '14px',
    color: theme.palette.common.white,
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  seconds: {
    fontSize: '10px',
    color: theme.palette.common.white,
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'right',
  },
  circle: {
    // width: '12px',
    // height: '12px',
    // borderRadius: '50%',
    // border:`2px solid ${fade(theme.palette.grey[200],.1)}`,
    // backgroundColor: theme.palette.grey[200],
    // marginLeft: '6px'
  },
  icons: {
    width: '22px',
    height: '22px',
    marginLeft: '6px',
  },
  borders: {
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: '0',
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    cursor: 'pointer',
    marginRight: '12px',
    '& img': {
      display: 'block',
      width: '20px',
      [theme.breakpoints.down('md')]: {
        width: '16px',
      },
    },
    [theme.breakpoints.down('md')]: {
      padding: '6px',
      marginRight: '6px',
    },
  },
  layoutMore: {
    marginLeft: '12px',
  },
  selectRoot: {
    color: theme.palette.common.white,
    minHeight: '20px',
    height: '40px',
    padding: '0 16px',
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      paddingRight: '20px !important',
      paddingLeft: '10px !important',
      minHeight: '30px',
      height: '30px',
      fontSize: "12px"
    },
  },
  selectIcon: {
    color: theme.palette.grey[200],
    fontSize: '15px',
    top: '50%',
    transform: 'translateY(-50%)',
    [theme.breakpoints.down('md')]: {
      right: '5px',
      width: '12px'
    },
  },

}));
