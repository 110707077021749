import { fade, makeStyles, Theme } from '@material-ui/core';
import { CurrentTheme, Themes } from 'modules/themes/types';

export const useTabsStyles = makeStyles<Theme>(
  theme => ({
    root: {
      position: 'relative',
      borderBottom: CurrentTheme === Themes.dark ? '1px solid #F8F050' : '1px solid #F5F5F5',
      '&:after': {
        content: `''`,
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        borderBottom: `2px solid ${fade(theme.palette.text.primary, 0.1)}`,
      },
      '& .MuiTab-textColorInherit': {
        color: '#fff',
        borderBottom: '2px solid transparent',
      },
      '& .MuiTab-root.Mui-selected': {
        color: CurrentTheme === Themes.dark ? '#2EF2F1' : '#EA3375',
        borderBottom: CurrentTheme === Themes.dark ? '2px solid #2EF2F1' : '2px solid #EA3375',
      }
    },

    tab: {
      fontSize: 18,
    },

    tabWrapper: {
      flexDirection: 'row',
      fontFamily: 'Pretendard',
      fontWeight: 400,
      fontSize: '16px'
    },

    tabCount: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 23,
      minWidth: 23,
      padding: theme.spacing(0, 0.75),
      marginLeft: theme.spacing(0.75),
      border: `2px solid ${fade(theme.palette.text.primary, 0.1)}`,
      borderRadius: 23,
      fontSize: 12,
      fontWeight: 700,
    },
  }),
  { index: 1 },
);
