import React from 'react';
import { Container } from '@material-ui/core';
import { useAccount } from 'modules/account/hooks/useAccount';
// import { t } from 'modules/i18n/utils/intl';
import { Button } from 'modules/uiKit/Button';
import { useHeaderStyles } from './HeaderStyles';
import { HeaderDrawer } from 'modules/common/components/HeaderDrawer';
import classNames from 'classnames';
import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { SelectChainDialog, useDialogState } from 'modules/layout/components/Header/components/SelectChainDialog';
import { LocaleSwitcher } from 'modules/common/components/LocaleSwitcher';
import { getDfdunkTab, IDfdunkTabDate, fetchCurrencyRate } from 'modules/common/actions/commonRequest';
import { useQuery } from '@redux-requests/react';
import { AvatarDropDown } from 'modules/common/components/AvatarDropDown';
import { useLocale } from 'modules/i18n/utils/useLocale';
import { LogoComp } from './components/Logo';
import { FilterSearch } from './components/FilterSearch';
import { HeaderNavationDrawer } from './components/HeaderNavationDrawer';
import { currencyRateInterface } from 'modules/common/components/HeaderDrawer/components/HeaderBalance';
import { useIsMDDown } from 'modules/themes/useTheme';

const lindSpaceStyles = {};
export const Header = () => {
  const {
    isConnected, handleConnect, loading,
    chainId,
  } = useAccount();
  const { locale } = useLocale();
  const classes = useHeaderStyles();
  const {
    opened: openedSelectChainDialog,
    close: closeSelectChainDialog,
  } = useDialogState();
  const { data: navData } = useQuery<IDfdunkTabDate[]>({
    type: getDfdunkTab.toString(),
  });

  const { data: currencyRate } = useQuery<currencyRateInterface[]>({
    type: fetchCurrencyRate.toString(),
  });
  localStorage.setItem('currencyRate', JSON.stringify(currencyRate));
  const isMobile = useIsMDDown();
  const renderedDesktop = (
    <>
      {!isConnected && (
        <Button onClick={handleConnect} className={classNames(classes.connect)}
                loading={loading}>
          {/*{t('header.connect')}*/}
        </Button>
      )}
      {isConnected && <AvatarDropDown />}
    </>
  );
  return (
    <header className={classNames(classes.root)}>
      <Container className={classes.container}>
        <SelectChainDialog
          isOpen={openedSelectChainDialog}
          onClose={closeSelectChainDialog}
          currentChain={chainId}
        />
        <Box className={classes.left}>
          <LogoComp />
          <FilterSearch currencyRate={currencyRate} />
        </Box>
        <Box className={classes.right}>
          {
            navData?.map(d => {
              return (
                d.status ? <Button
                  key={d.link}
                  component={NavLink}
                  variant='text'
                  style={lindSpaceStyles}
                  activeClassName={classes.activeLink}
                  className={classes.link}
                  to={d.link}
                >
                  {locale === 'en-US' ? d.nameEn : locale === 'zh-CN' ? d.nameZh : locale === 'ja-JP' ? d.nameJp : ''}
                </Button> : null
              );
            })
          }

          {
            !isMobile && <LocaleSwitcher />
          }

          {(isConnected && <HeaderDrawer />)}
          {renderedDesktop}

          {
            isMobile && <LocaleSwitcher />
          }


          <HeaderNavationDrawer navData={navData} />
        </Box>
      </Container>
    </header>
  );
};
