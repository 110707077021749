// import { useQuery } from '@redux-requests/react';
// import { NoItems } from 'modules/common/components/NoItems';
import { uid } from 'react-uid';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { ProductCards } from 'modules/Profile/components/ProductCards';
// import { ProductCardSkeleton } from 'modules/Profile/components/ProductCards/ProductCardSkeleton';
import { NftItemCard } from 'modules/Profile/components/NftItemCard';
import { Box, Typography } from '@material-ui/core';
import { useCollectedStyles } from './useCollectedStyles';
import Filters from 'modules/Profile/assets/filter.svg';
import LightFilter from 'modules/Profile/assets/light-filter.svg';
// import Layout from 'modules/Profile/assets/layout.svg';
// import LayoutMore from 'modules/Profile/assets/layoutMore.svg';
import { CollectedFilter } from 'modules/Explore/components/CollectedFilter';
import { Select } from 'modules/uiKit/Select';
import { getExploreItems } from 'modules/Explore/actions/explore';
import { useDispatchRequest, useQuery } from '@redux-requests/react';
import { useInterval } from 'modules/common/hooks/useInterval';
import { CollectionInfoInterface } from 'modules/Collection/interface/collectionInterface';
import { getCollectionsList } from 'modules/Collection/actions/collection';
import { useIsSMDown } from 'modules/themes/useTheme';
import { ExploreFilterDialog } from 'modules/Explore/components/ExploreFilterDialog/ExploreFilterDialog';
import { NoDataComp } from 'modules/common/components/NoData';
import { CurrentTheme, Themes } from '../../../themes/types';
import LightLiveFeed from 'modules/CollectionDetail/assets/light-down.png';
import DarkLiveFeed from 'modules/CollectionDetail/assets/down.png';
import { t } from '../../../i18n/utils/intl';

// const priceList = [
//   {
//     value: 'price_asc',
//     label: 'Price: Low to High',
//   },
//   {
//     value: 'price_desc',
//     label: 'Price: High to Low',
//   },
//   {
//     value: 'create_time_desc',
//     label: 'Time: Recently List ',
//   },
//   {
//     value: 'deadline_time_asc',
//     label: 'Time: Ending Soon ',
//   },
// ]

export const Collected: React.FC<{

}> = function () {
  const [layoutNum, setLayoutNum] = useState(sessionStorage.getItem('exploreFilterFlag') === 'true' ? 4 : 6);
  const [filterFlag, setFilterFlag] = useState(sessionStorage.getItem('exploreFilterFlag') === 'true' ? true : false);
  const [price, setPrice] = React.useState('price_asc');
  const classes = useCollectedStyles();
  const [timers, setTimers] = React.useState(t('public.secsAgo',{count:1}));
  const [timerBegin, setTimerBegin] = React.useState(0);
  const [collectionList, setCollectionList] = useState<CollectionInfoInterface[]>([]);
  const [dialogFlag, setDialogFlag] = useState(sessionStorage.getItem('exploreDialogFlag') === 'true' ? true : false);
  const dispatch = useDispatchRequest();

  const isMobile = useIsSMDown();


  const priceList = useMemo(
    () => [
      {
        value: 'price_asc',
        label: t('collectionDetail.lowToHigh'),
      },
      {
        value: 'price_desc',
        label: t('collectionDetail.highToLow'),
      },
      {
        value: 'create_time_desc',
        label: t('collectionDetail.recentlyList'),
      },
      {
        value: 'deadline_time_asc',
        label: t('collectionDetail.endingSoon'),
      },
    ],
    [],
  );

  const handleChains = useCallback((event: ChangeEvent<{ value: unknown }>) => {
    const queryParameters = new URLSearchParams(window.location.search);
    const exploreFilterType = queryParameters.get('exploreFilterType') ?? '';
    const exploreFilterCurrencyList = queryParameters.get('exploreFilterCurrencyList') ?? '';
    const exploreFilterAddressArr = queryParameters.get('exploreFilterAddressArr') ?? '';
    setPrice(event.target.value as string);
    dispatch(getExploreItems({
      list_type: exploreFilterType,
      order_by: event.target.value as string || '',
      currency_addresses: exploreFilterCurrencyList,
      off_index: 1,
      off_size: 50,
      addressList: exploreFilterAddressArr
    }))
  },
    [dispatch],
  );
  const { data: dataItem } = useQuery<any>({
    type: getExploreItems.toString(),
  });

  useEffect(() => {
    async function loadData() {
      const data: any = await getCollectionsList({ page: 1, page_size: 100 });
      if (data.code === 200) {
        setCollectionList(data.data);
      }
    }

    loadData();
  }, []);

  useEffect(() => {
    setTimerBegin(1);
  }, [setTimerBegin]);
  useInterval(() => {
    if (timerBegin !== 0) {
      if (timerBegin >= 60 * 60) {
        // setTimers((parseInt((timerBegin / (60 * 60)).toString()) + ' h ago'))
        setTimers(t('public.hAgo',{count:parseInt((timerBegin / (60 * 60)).toString())}))
      } else if (timerBegin >= 60) {
        // setTimers((parseInt((timerBegin / 60).toString()) + ' min ago'))
        setTimers(t('public.minAgo',{count:parseInt((timerBegin / 60).toString())}))
      } else {
        // setTimers(timerBegin + ' secs ago')
        setTimers(t('public.secsAgo',{count:timerBegin}))
      }
      setTimerBegin(timerBegin + 1);
    }
  }, 1000);

  const close = () => {
    setDialogFlag(false);
    if (isMobile) {
      sessionStorage.setItem('exploreDialogFlag', (false).toString())
    } else {
      sessionStorage.setItem('exploreFilterFlag', (false).toString())
    }
  }

  // const dataList = [{}, {}, {}, {}, {}, {}, {}]
  return (
    <>
      <Box display={'flex'} mt={3}>
        <Box>
          {filterFlag && <CollectedFilter collectionList={collectionList} price={price} />}
        </Box>
        <Box style={{ width: '100%' }}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={3}>
            <Box display={'flex'} alignItems={'center'}>
              <Typography className={classes.borders}>
                <img src={CurrentTheme === Themes.dark ? Filters : LightFilter} alt=""
                onClick={() => {
                  setLayoutNum(filterFlag ? 6 : 4);
                  if (isMobile) {
                    sessionStorage.setItem('exploreDialogFlag', (!dialogFlag).toString())
                    setDialogFlag(!dialogFlag);
                  } else {
                    sessionStorage.setItem('exploreFilterFlag', (!filterFlag).toString())
                    setFilterFlag(!filterFlag);
                  }
                }}
              /></Typography>
              {/* <Typography className={classes.borders}>
                <img src={Layout} onClick={() => { setLayoutNum(4) }} alt="" />
                <img src={LayoutMore} onClick={() => { setLayoutNum(6) }} className={classes.layoutMore} alt="" />
              </Typography> */}
              <Box>
                <Select
                  value={price}
                  onChange={handleChains}
                  options={priceList}
                  classes={{
                    root: classes.selectRoot,
                    icon: classes.selectIcon,
                  }}
                />
              </Box>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Box>
                <Typography className={classes.result}>{dataItem && dataItem.total} {t('public.results')}</Typography>
                <Typography className={classes.seconds}>{timers}</Typography>
              </Box>
              {/*<Typography className={classes.circle}></Typography>*/}
              <img src={CurrentTheme === Themes.dark ? DarkLiveFeed: LightLiveFeed} className={classes.icons} alt="" />
            </Box>
          </Box>
          <ProductCards
            // isLoading={loading}
            layoutNum={layoutNum}
          >
            {
              //   (!dataItem || !dataItem.nft_list) ? (
              //   <ProductCardSkeleton />
              // ) : (
              dataItem && dataItem.nft_list && dataItem.nft_list.map((item: any) => (
                <NftItemCard
                  key={uid(item)}
                  item={item}
                  layoutNum={layoutNum}
                />
              ))
              // )
            }
          </ProductCards>
          {
            (!dataItem || !dataItem.nft_list) && <NoDataComp />
          }
        </Box>
        <ExploreFilterDialog
          openDialog={dialogFlag}
          onDialogClose={close}
          collectionList={collectionList}
          price={price}
        />
      </Box>

    </>
  );
};
