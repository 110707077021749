import { makeStyles, Theme, fade } from '@material-ui/core';

export const PROFILE_AVATAR_SIZE = 145;

export const useProfileStyles = makeStyles<Theme>(theme => ({
  root: {
    paddingTop: 0,
    // backgroundColor: '#fff'
  },

  avatar: {
    marginTop: -PROFILE_AVATAR_SIZE / 2,
    // marginBottom: theme.spacing(5),
    zIndex: 2,
    width: 125,
    height: 125,
  },

  tabs: {
    marginBottom: theme.spacing(3),
  },
  namse: {
    marginTop: '32px',
    marginBottom: '32px',
    color: theme.palette.common.white,
    flex:1,
    [theme.breakpoints.down('md')]: {
      marginBottom: '0',
    },
  },
  userName:{
    wordBreak: 'break-word',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: '40px',
    color: theme.palette.common.white,
    paddingBottom:'15px',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  },
  address: {
    fontSize: '14px',
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    cursor:'pointer',
    '& img': {
      marginRight: '4px'
    }
  },
  // describe: {
  //   fontSize: '12px',
  //   color: theme.palette.common.white,
  //   marginTop: '12px',
  //   fontWeight: 400,
  //   fontFamily: 'Roboto Condensed'
  // },
  joined: {
    fontSize: '14px',
    color: fade(theme.palette.common.white, .8),
    marginLeft: '10px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0',
    },
  },
  tabContent: {
    position: 'relative',
  },
  tips: {
    color: 'red',
    position: 'absolute',
    top: '14px',
    left: '290px',
    [theme.breakpoints.down('sm')]: {
      top: '56px',
      left: 0,
    },
  },
  link: {
    marginLeft: '6px',
    fontWeight: 800,
    fontFamily: '"Graphik",sans-serif',
    textDecoration: 'underline',
    color: 'rgb(0,0,238)',
    '&:hover': {
      color: 'rgb(0,0,238) !important',
      opacity: '0.7',
    },
  },
  // verify: {
  //   margin: '0 20px'
  // },
  setting: {
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 16px',
    // borderLeft: '1px solid #E6E8EC',
    marginLeft: '-8px',
    cursor: 'pointer',
    '& img': {
      width: '24px'
    }
  },
  addressContent: {
    '& div': {
      border: '1px solid #F8F050',
      paddingLeft: '10px',
      paddingRight: '6px',
      // height: '32px',
    },
    '& p': {
      color: theme.palette.common.white,
      lineHeight: '30px',
    },
    '& svg': {
      color: '#2EF2F1'
    }
  },
  hederMsg: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginBottom: '32px',
    },
  },
  social: {
    minWidth: '20px',
    '& a': {
      height: '32px',
      width: '32px'
    },
    '& svg': {
      width: '16px',
      height: '16px'
    }
  }
}));
