import { usePropertiesStyle } from 'modules/NftDetail/components/properties/usePropertiesStyle';
import { useCurrencyStyle } from './useCurrencyStyle';
import { Box, Typography, Checkbox } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { ExpandMoreIcon } from 'modules/NftDetail/assets/expandMoreIcon';
import FilterIcon from 'modules/Explore/assets/fitters.svg';
import { fetchCurrencyRate } from 'modules/common/actions/commonRequest';
import { useQuery } from '@redux-requests/react';
import { uid } from 'react-uid';
import { t } from '../../../i18n/utils/intl';
import {TokenImgComp} from 'modules/common/components/TokenImg';

export const Currency = (
  {
    setCurrency,
    currency,
    setCurrencyList
  }: {
    setCurrency: React.Dispatch<React.SetStateAction<{ [x: string]: boolean; }>>;
    setCurrencyList: React.Dispatch<React.SetStateAction<string>>;
    currency: { [x: string]: boolean; }
  }
) => {
  const accordionClasses = usePropertiesStyle();
  const classes = useCurrencyStyle();

  const { data: currencyRate } = useQuery<any>({
    type: fetchCurrencyRate.toString(),
  });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updateState = { ...currency, [event.target.name]: event.target.checked };
    setCurrency(updateState);
    let addressArr = '';
    for (let key in updateState) {
      if (updateState[key]) {
        if (addressArr === '') {
          addressArr += key;
        } else {
          addressArr += ('&currency_addresses=' + key);
        }
      }
    }
    setCurrencyList(addressArr);
  };

  return (
    <Box className={classes.root}>
      <Accordion
        classes={{
          root: classes.accordion,
        }}
        defaultExpanded={true}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          // aria-controls="panel1a-content"
          // id="panel1a-header"
          classes={{
            root: classes.itemSummary,
            expanded: classes.itemExpanded,
            expandIcon: accordionClasses.expandIcon,
          }}
        >
          <Box >
            <Typography className={accordionClasses.heading}>
              <img src={FilterIcon} alt="" />
              {t('collection.currency')}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: classes.accordionDetail,
          }}
        >
          <Box className={classes.activity}>
            {currencyRate && currencyRate.length > 0 && currencyRate.map((item: any) => {
              return <Box key={uid(item)} display={'flex'} justifyContent={'space-between'} alignItems={'center'} className={classes.activityItem}>
                <Typography className={classes.collectionNum}>
                  <Box className={classes.tokenImgBox}>
                    <TokenImgComp token={item.currency_symbol}/>
                  </Box>
                  {item.currency_symbol}
                </Typography>
                <Typography className={classes.collectionMsg}>
                  <Checkbox
                    checked={currency[item.currency_address] || false}
                    onChange={handleChange}
                    name={item.currency_address}
                    classes={{
                      root: classes.chectboxRoot,
                      checked: classes.chectboxChecked,
                    }}
                  /></Typography>
              </Box>
            })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
