import classNames from 'classnames';
import { useCollectedFilterStyle } from './useCollectedFilterStyle';
import {
  Box, Typography
  // , IconButton
  , Checkbox
} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import React, {
  // useCallback,
  useState, useEffect
} from 'react';
// import { InputField } from 'modules/form/components/InputField';
// import {Field, Form, FormRenderProps} from 'react-final-form';
// import { SearchIcon } from 'modules/Profile/assets/SearchIcon';
import { Currency } from 'modules/Explore/components/currency';
import { CollectionInfoInterface } from 'modules/Collection/interface/collectionInterface';
import { useDispatchRequest } from '@redux-requests/react';
import { getExploreItems } from 'modules/Explore/actions/explore';
import { t } from '../../../i18n/utils/intl';
import { useHistory } from 'react-router-dom';
import { useCurrencyStyle } from 'modules/Explore/components/currency/useCurrencyStyle';
interface IHeaderProps {
  className?: string;
  collectionList: CollectionInfoInterface[];
  price: string;
}

export interface IDescFormValues {
  collectionName: string;
}

export const CollectedFilter = ({
  className,
  collectionList,
  price
}: IHeaderProps) => {
  const classes = useCollectedFilterStyle();
  const currencyStyle = useCurrencyStyle();
  const history = useHistory();
  const [openOnSale, setOpenOnSale] = useState(false);
  const [openAuction, setOpenAuction] = useState(false);
  const [state, setState] = useState<{ [key in string]: boolean }>({});
  const [addressList, setAddressList] = useState('');

  const [saleType, setSaleType] = useState('All');


  const [currency, setCurrency] = useState<{ [key in string]: boolean }>({});
  const [currencyList, setCurrencyList] = useState('');

  const dispatch = useDispatchRequest();

  const onSwitchSaleChange = () => {
    setOpenOnSale(!openOnSale);
  };
  const onSwitchAuctionChange = () => {
    setOpenAuction(!openAuction);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setParamets = (type: string, value: string) => {
    const queryParameters = new URLSearchParams(window.location.search);
    const exploreFilterType = queryParameters.get('exploreFilterType') ?? '';
    const openAuction = queryParameters.get('openAuction') ?? '';
    const openOnSale = queryParameters.get('openOnSale') ?? '';
    const exploreFilterState = queryParameters.get('exploreFilterState') ?? '';
    const exploreFilterAddressArr = queryParameters.get('exploreFilterAddressArr') ?? '';
    const exploreFilterCurrencyList = queryParameters.get('exploreFilterCurrencyList') ?? '';
    const exploreFilterCurrency = queryParameters.get('exploreFilterCurrency') ?? '';
    let params = new URLSearchParams();
    (exploreFilterType || type === 'exploreFilterType') && params.append('exploreFilterType', type === 'exploreFilterType' ? value : exploreFilterType);
    (openAuction || type === 'openAuction') && params.append('openAuction', type === 'openAuction' ? value : openAuction);
    (openOnSale || type === 'openOnSale') && params.append('openOnSale', type === 'openOnSale' ? value : openOnSale);
    (exploreFilterState || type === 'exploreFilterState') && params.append('exploreFilterState', type === 'exploreFilterState' ? value : exploreFilterState);
    (exploreFilterAddressArr || type === 'exploreFilterAddressArr') && params.append('exploreFilterAddressArr', type === 'exploreFilterAddressArr' ? value : exploreFilterAddressArr);
    (exploreFilterCurrencyList || type === 'exploreFilterCurrencyList') && params.append('exploreFilterCurrencyList', type === 'exploreFilterCurrencyList' ? value : exploreFilterCurrencyList);
    (exploreFilterCurrency || type === 'exploreFilterCurrency') && params.append('exploreFilterCurrency', type === 'exploreFilterCurrency' ? value : exploreFilterCurrency);
    // history.push(params)
    history.replace(window.location.pathname + '?' + params)
  }

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const exploreFilterType = queryParameters.get('exploreFilterType') ?? '';
    const openAuction = queryParameters.get('openAuction') ?? '';
    const openOnSale = queryParameters.get('openOnSale') ?? '';
    const exploreFilterState = queryParameters.get('exploreFilterState') ?? '';
    const exploreFilterAddressArr = queryParameters.get('exploreFilterAddressArr') ?? '';
    const exploreFilterCurrencyList = queryParameters.get('exploreFilterCurrencyList') ?? '';
    const exploreFilterCurrency = queryParameters.get('exploreFilterCurrency') ?? '';
    openOnSale && setOpenOnSale(openOnSale === 'true' ? true : false);
    openAuction && setOpenAuction(openAuction === 'true' ? true : false);
    exploreFilterState && setState(JSON.parse(exploreFilterState));
    exploreFilterAddressArr && setAddressList(exploreFilterAddressArr);
    exploreFilterType && setSaleType(exploreFilterType);
    exploreFilterCurrencyList && setCurrencyList(exploreFilterCurrencyList);
    exploreFilterCurrency && setCurrency(JSON.parse(exploreFilterCurrency));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    let fillType = 'All';
    if ((openOnSale && openAuction)) {
      setSaleType('All');
      fillType = 'All';
    } else if (openOnSale) {
      fillType = 'Fixed';
      setSaleType('Fixed')
    } else if (openAuction) {
      fillType = 'Auction';
      setSaleType('Auction')
    } else if (!openOnSale && !openAuction) {
      fillType = 'All';
      setSaleType('All');
    }
    setParamets('exploreFilterType', fillType);
    setParamets('openAuction', openAuction.toString());
    setParamets('openOnSale', openOnSale.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAuction, openOnSale]);




  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updateState = { ...state, [event.target.name]: event.target.checked };
    setState(updateState);
    setParamets('exploreFilterState', JSON.stringify(updateState))
    let addressArr = '';
    for (let key in updateState) {
      if (updateState[key]) {
        if (addressArr === '') {
          addressArr += key;
        } else {
          addressArr += ('&collection_addresses=' + key);
        }
      }
    }
    console.log(encodeURI(addressArr));
    setParamets('exploreFilterAddressArr', addressArr);
    setAddressList(addressArr);
  };

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const exploreFilterState = queryParameters.get('exploreFilterState') ?? '';
    if (collectionList.length > 0) {
      let stateList: { [key in string]: boolean } = {}
      collectionList.map((item) => {
        stateList[item.contract] = false;
        return '';
      })
      if (!exploreFilterState) {
        setState(stateList)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionList]);


  useEffect(() => {
    setParamets('exploreFilterCurrencyList', currencyList);
    dispatch(getExploreItems({
      list_type: saleType,
      order_by: price,
      currency_addresses: currencyList,
      off_index: 1,
      off_size: 50,
      addressList: addressList
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, saleType, currencyList, addressList]);

  useEffect(() => {
    if (currency) {
      setParamets('exploreFilterCurrency', JSON.stringify(currency));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency]);


  return (
    <div className={classNames(classes.root)}>
      <Box display={'flex'} justifyContent={'space-between'} className={classes.switchContent}>
        <Box>
          <Typography className={classes.saleTitle}>{t('collection.fixedPrice')}</Typography>
          <Switch
            classes={{
              root: classes.switchRoot,
              checked: classes.switchChecked,
              track: classes.switchTrack,
            }}
            checked={openOnSale}
            onChange={onSwitchSaleChange}
          />
        </Box>
        <Box>
          <Typography className={classes.saleTitle}>{t('collection.onAuction')}</Typography>
          <Switch
            classes={{
              root: classes.switchRoot,
              checked: classes.switchChecked,
              track: classes.switchTrack,
            }}
            checked={openAuction}
            onChange={onSwitchAuctionChange}
          />
        </Box>
      </Box>
      <Box>
      </Box>
      <Box className={classes.filter}>
        {collectionList.length > 0 && collectionList.map((item) => {
          return <Box className={classes.collectionItem} key={item.contract}>
            <Typography className={classes.collectionMsg}>
              <Checkbox
                checked={state[item.contract] || false}
                className={classes.checkouts}
                onChange={handleChange}
                classes={{
                  root: currencyStyle.chectboxRoot,
                  checked: currencyStyle.chectboxChecked,
                }}
                name={item.contract} />
              <img src={item.logoImg} alt="" />{item.name}</Typography>
            {/* <Typography className={classes.collectionNum}>{item.order}</Typography> */}
          </Box>
        })}
      </Box>
      <Currency setCurrency={setCurrency} currency={currency} setCurrencyList={setCurrencyList} />
    </div>
  );
};
