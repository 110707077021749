import { Container } from '@material-ui/core';
import { useAccount } from 'modules/account/hooks/useAccount';
import { truncateWalletAddr } from 'modules/common/utils/truncateWalletAddr';
import {
  Box,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { uid } from 'react-uid';
import { useProfileStyles } from './useProfileStyles';
import { ChangeWallet } from 'modules/router/components/ChangeWallet';
import { ConnectWallet } from 'modules/router/components/ConnectWallet';
// import { BlockchainNetworkId } from 'modules/common/conts';
import { TabPanel } from 'modules/Profile/components/TabPanel';
import { Tabs } from 'modules/Profile/components/Tabs';
import { Tab } from 'modules/Profile/components/Tabs/Tab';
import { Section } from 'modules/uiKit/Section';
import { Header } from 'modules/Profile/components/Header';
import { SetAvatarModal } from 'modules/Profile/components/SetAvatarModal';
import { SetBgImgModal } from 'modules/Profile/components/SetBgImgModal';
import { Avatar } from 'modules/Profile/components/Avatar';
// import Verify from 'modules/Profile/assets/verify.svg';
// import VerifyRed from 'modules/Profile/assets/verifyRed.svg';
import Eth from 'modules/Profile/assets/eth.svg';
import { Social } from 'modules/Profile/components/Social';
import { ProfileRoutesConfig } from '../ProfileRoutes';
import { Collected } from './collected';
import { Favorited } from './favorited';
import { Activities } from './activities';
import { getProfile, IProfileData, fromatTimers, getUserActivities, getUserCollection, getUserCollected, getUserFavorite } from 'modules/Profile/actions/profile';
// import { useDispatch } from 'react-redux';
// import Setting from 'modules/Profile/assets/setting.svg';
import { useQuery, useDispatchRequest } from '@redux-requests/react';
// import { CopyToClip } from 'modules/common/components/CopyToClip';
import { useIsSMDown } from 'modules/themes/useTheme';
import { getBlockChainExplorerAddress } from '../../common/conts';
import CopyToClipboard from 'react-copy-to-clipboard';
import { t } from '../../i18n/utils/intl';
// import { CurrentTheme, Themes } from 'modules/themes/types';
import { VerifyComp } from '../../common/components/Verify';
import { ShowMoreComp } from '../../CollectionDetail/components/ShowMore';
export enum ProfileTab {
  collections = 'collections',
  activity = 'activity',
  favorited = 'favorited',
}

export const Profile = () => {
  const isMobile = useIsSMDown();
  const { tab } = ProfileRoutesConfig.UserProfile.useParams();
  const [isAvatarModalOpened, setAvatarModalOpened] = useState(false);
  const [isBgImgModalOpened, setBgImgModalOpened] = useState(false);
  const classes = useProfileStyles();
  const { address, chainId, isConnected, isChainSupported } = useAccount();
  const [isCopy, setCopy] = useState<boolean>(false);
  const { push } = useHistory();
  const dispatch = useDispatchRequest();
  const [showAvatarImg, setShowAvatarImg] = useState('');
  const changeShowAvatarImg = (imgSrc: string) => {
    setShowAvatarImg(imgSrc);
  }
  const history = useHistory();
  const { data: dataItem } = useQuery<IProfileData>({
    type: getProfile.toString(),
  });

  const toggleAvatarModal = useCallback(
    (isOpen: boolean) => () => {
      setAvatarModalOpened(isOpen);
    },
    [],
  );

  const toggleBgImgModal = useCallback(
    (isOpen: boolean) => () => {
      history.push('/profile_edit');
      setBgImgModalOpened(false);
    },
    [history],
  );

  useEffect(() => {
    if (!address) {
      return;
    }
    dispatch(getProfile({ address: address }))
    dispatch(getUserCollection({ address: address }))
  }, [address, dispatch]);

  const updateData = useCallback(
    (value: ProfileTab) => {
      if (!address) {
        return;
      }
      switch (value) {
        case ProfileTab.collections: {
          dispatch(getUserCollected({ address: address, page: 1, pagesize: 100 }));
          break;
        }
        case ProfileTab.favorited: {
          dispatch(getUserFavorite({ address: address, page: 1, pagesize: 100 }));
          break;
        }
        case ProfileTab.activity: {
          dispatch(getUserActivities({ address: address }));
          break;
        }
        default: {
          console.error('not match tab', value);
        }
      }
    },
    [address, dispatch
      // , chainId
    ],
  );

  const onTabsChange = useCallback((_, value) => {
    push(ProfileRoutesConfig.UserProfile.generatePath(value));
    updateData(value);
  },
    [push, updateData],
  );

  useEffect(() => {
    updateData(tab);
  }, [updateData, tab, chainId]);

  const reload = () => () => {

  };

  const tabs = useMemo(
    () => [
      {
        value: ProfileTab.collections,
        label: t('proFile.collected'),
      },
      {
        value: ProfileTab.favorited,
        label: t('proFile.favorited'),
      }, {
        value: ProfileTab.activity,
        label: t('proFile.activities'),
      },
    ],
    [],
  );
  useEffect(() => {
    if (isCopy) {
      setTimeout(() => {
        setCopy(false);
        (document.activeElement as any)?.blur();
      }, 1000);
    }
  }, [isCopy]);
  return (
    (isConnected && ((isChainSupported.indexOf(chainId) < 0))) ? <ChangeWallet /> :
      !isConnected ? (
        <ConnectWallet />
      ) :
        <Section className={classes.root}>
          <Header
            img={(dataItem && dataItem.banner) || ''}
            onEditClick={toggleBgImgModal(true)}
          />
          <SetBgImgModal
            isOpen={isBgImgModalOpened}
            onClose={toggleBgImgModal(false)}
            fileType={''}
          />
          <Container>
            <Avatar
              className={classes.avatar}
              src={showAvatarImg || (dataItem && dataItem.image) || ""}
              onEditClick={toggleAvatarModal(true)}
              isEditable
              isVerified={false}
            />

            
            <SetAvatarModal
              isOpen={isAvatarModalOpened}
              onClose={toggleAvatarModal(false)}
              successCallback={changeShowAvatarImg}
            />
            <Box display={'flex'} justifyContent='space-between' className={classes.hederMsg}>
              <Box className={classes.namse}>
                <Typography className={classes.userName} variant="h2">
                  {(dataItem && dataItem.username) || 'Unnamed'}
                  <VerifyComp/>
                  {/*<img src={CurrentTheme === Themes.dark ? Verify : VerifyRed} alt="" className={classes.verify} />*/}
                  <CopyToClipboard text={address} onCopy={() => setCopy(true)}>
                    <Tooltip arrow placement="bottom"
                      title={isCopy ? t('common.copied') : t('common.copy-to-clipboard')}
                    >
                      <span className={classes.address}><img src={Eth} alt="" />{truncateWalletAddr(address)}</span>
                    </Tooltip>
                  </CopyToClipboard>
                  {!isMobile && <span className={classes.joined}>Joined {dataItem && fromatTimers(dataItem.registerTime)}</span>}
                </Typography>
                {isMobile && <Typography className={classes.joined}>Joined {dataItem && fromatTimers(dataItem.registerTime)}</Typography>}
                {/*<Typography className={classes.describe}>{dataItem && dataItem.bio}</Typography>*/}
                <ShowMoreComp description={dataItem && dataItem.bio} lines={2}/>
              </Box>

              <Box mt={4} display={'flex'} alignItems={'center'}>
                {/*<Box className={classes.addressContent}>*/}
                {/*  <CopyToClip address={address} />*/}
                {/*</Box>*/}
                <Box className={classes.social}>
                  <Social
                    block={getBlockChainExplorerAddress(chainId) + 'address/' + address || ''}
                    twitter={dataItem ? dataItem.twitter : '123'}
                    instagram={dataItem ? dataItem.instagram : '123'}
                    facebook={dataItem ? dataItem.facebook : '123'}
                  />
                </Box>
                {/*<Typography className={classes.setting} onClick={() => { push(ProfileRoutesConfig.UserEdit.path) }}><img src={Setting} alt="" /></Typography>*/}
              </Box>
            </Box>

            <Box className={classes.tabContent}>
              <Tabs
                className={classes.tabs}
                onChange={onTabsChange as any}
                value={tab}
              >
                {tabs.map(tabProps => (
                  <Tab key={uid(tabProps)} {...tabProps} />
                ))}
              </Tabs>
            </Box>

            <TabPanel value={tab} index={ProfileTab.collections}>
              <Collected reload={reload()} />
            </TabPanel>

            <TabPanel value={tab} index={ProfileTab.favorited}>
              <Favorited reload={reload()} />
            </TabPanel>

            <TabPanel value={tab} index={ProfileTab.activity}>
              <Activities reload={reload()} />
            </TabPanel>
          </Container>
        </Section>
  );
};
