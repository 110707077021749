import { makeStyles, Theme, fade } from '@material-ui/core';

export const useActivitiesStyles = makeStyles<Theme>(theme => ({
  root: {
    position: 'relative',
    width: '100%',
    // paddingRight: '140px'
  },
  liveFeed: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: '30px',
    top: '-2px',
    color: theme.palette.common.white,
    fontSize: '10px',
  },
  liveText: {
    color: fade(theme.palette.common.white, .9),
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'right',
  },
  seconds: {
    color: fade(theme.palette.common.white, .9),
    fontSize: '10px',
    lineHeight: '16px',
    textAlign: 'right',
  },
  icons: {
    width: '22px',
    height: '22px',
    marginLeft: '6px',
  },

  activitiesList: {
    width: '100%',
  },
  types: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#fff',
    width: '240px',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: '12px',
      display: 'block',
    },
    [theme.breakpoints.down('md')]: {
      width: '200px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '50px',
    },
  },
  collectionContent: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    minWidth: '280px',
    maxWidth: "280px",
    overflow: 'hidden',
    '& img': {
      // height: '48px',
      width: '35px',
      borderRadius: '6px',
      display: 'block',
      marginRight: '12px',
    },
  },
  tokenId: {
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '24px',
    color: '#fff',
  },
  collectionDetail: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-word',
    '& img': {
      width: '16px',
      height: '16px',
      marginLeft: '4px',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '130px',
    },
  },
  addressList: {
    width: '600px',
    display: 'flex',
    justifyContent: 'space-around',
    marginLeft: '0',
    [theme.breakpoints.down('md')]: {
      marginLeft: '50px',
    },
  },
  fromAddress: {
    minWidth: '120px',
    maxWidth: '120px',
    color: '#fff',
    fontSize: '15px',
  },
  address: {
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: '12px',
  },

  values: {
    width: '200px',
    [theme.breakpoints.down('md')]: {
      width: '100px',
    },
  },
  ethValues: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: '15px',
    fontWeight: 400,
    justifyContent: 'end',
    cursor: 'pointer',
    '& img': {
      marginLeft: '4px',
      margin: '0 6px',
      maxWidth: '18px',
    },
  },

  usdtValues: {
    color: '#fff',
    fontSize: '12px',
    fontWeight: 400,
    textAlign: 'right',
  },
}));
