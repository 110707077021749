import React from 'react';
import {
  Box,
  Dialog,
  Typography
} from '@material-ui/core';
import { useContactUsDialogStyles } from './useContactUsDialogStyles';
import { Button } from 'modules/uiKit/Button';
import { LogoComp } from '../../../DFduckHeader/components/Logo';
import { t } from 'modules/i18n/utils/intl';

export const ContactUsDialog = ({
    open,
    setOpen,
  }: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
  }) => {
  const classes = useContactUsDialogStyles();
  
  const onClose = ()=>{
    setOpen(false);
  }
  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={() => {
          onClose();
        }}
        classes={{
          paper: classes.root,
        }}
        PaperProps={{
          elevation: 0,
        }}
        maxWidth="md"
      >
        <Box mb={3} className={classes.dialogHead}>
          <LogoComp/>
        </Box>
        <Box display={'flex'} className={classes.contactmsg}>
          <Typography variant="h4">
            {t('contact.msg')}
            <span>info@houtluuu.io</span>
          </Typography>
        </Box>
        <Box>
          <Button fullWidth className={classes.buyNFT}
            type='submit'
            onClick={onClose}>
          </Button>
        </Box>
      </Dialog>
    </>
  );
};
