import { Box, Typography } from '@material-ui/core';
import { useActivitiesStyles } from './useActivitiesStyles';
import { getBlockChainExplorerAddress } from 'modules/common/conts';
import Share from 'modules/Profile/assets/share.svg';
import Buy from 'modules/Profile/assets/buy.svg';
import Sale from 'modules/Profile/assets/sale.svg';
import ShareRed from 'modules/Profile/assets/shareRed.svg';
import BuyRed from 'modules/Profile/assets/buyRed.svg';
import SaleRed from 'modules/Profile/assets/saleRed.svg';
import Verify from 'modules/Profile/assets/verify.svg';
import VerifyRed from 'modules/Profile/assets/verifyRed.svg';
import { useQuery } from '@redux-requests/react';
import { getUserActivities } from 'modules/Profile/actions/profile';
import { useAccount } from 'modules/account/hooks/useAccount';
import WETHIcon from 'modules/NftDetail/assets/weth.svg';
import ETHIcon from 'modules/NftDetail/assets/eth.svg';
import USDTIcon from 'modules/NftDetail/assets/usdt.svg';
import BUSDIcon from 'modules/NftDetail/assets/busd.svg';
import { getCurrentSymbol } from 'modules/common/utils/getUSDTprice';
import { useIsSMDown, useIsMDDown } from 'modules/themes/useTheme';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CurrentTheme, Themes } from 'modules/themes/types';
import { t } from 'modules/i18n/utils/intl';
export const Activities: React.FC<{
  reload?: () => void;
}> = function ({ reload }) {
  const classes = useActivitiesStyles();
  const history = useHistory();
  const { data: activitesList } = useQuery<any>({
    type: getUserActivities.toString(),
  });
  const { address, chainId } = useAccount();
  const isMobile = useIsSMDown();
  const mdMobile = useIsMDDown();
  return (
    <>
      <Box display={'flex'} className={classes.root}>
        <Box className={classes.activitiesList}>
          {activitesList && activitesList.activityList && activitesList.activityList.map((item: any) => {
            const chainsSymbol = getCurrentSymbol(item.priceToken);
            return isMobile ? <Box display={'flex'} flexDirection={'column'} mb={4} key={item.transactionHash}>
              <Box mb={1}>
                {item.to.toLocaleLowerCase() === address.toLocaleLowerCase() ?
                  <Typography className={classes.types}><img src={CurrentTheme === Themes.dark ? Buy : BuyRed} alt="" />
                    {t('proFile.buy')}
                  </Typography> :
                  <Typography className={classes.types}><img src={CurrentTheme === Themes.dark ? Sale : SaleRed} alt="" />
                    {t('proFile.sell')}
                  </Typography>
                }
              </Box>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box display={'flex'} alignItems={'center'}>
                  <Box className={classes.collectionContent} onClick={() => {
                    history.push(`/nft-detail/${item.contract}/${item && item.tokenId}/type`);
                  }}>
                    <img src={item.image} alt="" />
                    <Box>
                      <Typography className={classes.tokenId}>{item.metadataName}</Typography>
                      <Typography className={classes.collectionDetail} >{item.name}<img src={CurrentTheme === Themes.dark ? Verify : VerifyRed} alt="" /></Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.values} alignItems={'center'}>
                  <Box className={classes.contentVal}>
                    <Typography className={classes.ethValues}>
                      <img className={classes.chainIcon} src={chainsSymbol === 'ETH' ? ETHIcon : chainsSymbol === 'USDT' ? USDTIcon : chainsSymbol === 'WETH' ? WETHIcon : BUSDIcon} alt="" />{item.priceAmount}
                      <img src={CurrentTheme === Themes.dark ? Share : ShareRed} alt="" onClick={() => {
                        window.open(getBlockChainExplorerAddress(chainId) + 'tx/' + item.transactionHash, "_blank")
                      }} /></Typography>
                    <Typography className={classes.usdtValues}>${item.usPrice.toFixed(2)}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
              :
              <Box display={'flex'} mb={4} key={item.transactionHash} alignItems={'center'} justifyContent={'space-between'}>
                <Box display={'flex'} alignItems={'center'}>
                  {item.to.toLocaleLowerCase() === address.toLocaleLowerCase() ?
                    <Typography className={classes.types}><img src={CurrentTheme === Themes.dark ? Buy : BuyRed} alt="" />
                      {t('proFile.buy')}
                    </Typography> :
                    <Typography className={classes.types}><img src={CurrentTheme === Themes.dark ? Sale : SaleRed} alt="" />
                      {t('proFile.sell')}
                    </Typography>
                  }
                  <Box className={classes.collectionContent} onClick={() => {
                    history.push(`/nft-detail/${item.contract}/${item && item.tokenId}/type`);
                  }}>
                    <img src={item.image} alt="" />
                    <Box>
                      <Typography className={classes.tokenId}>{item.metadataName}</Typography>
                      <Typography className={classes.collectionDetail} >{item.name}<img src={CurrentTheme === Themes.dark ? Verify : VerifyRed} alt="" /></Typography>
                    </Box>
                  </Box>
                </Box>
                {!mdMobile && <Box className={classes.addressList}>
                  <Box>
                    <Typography className={classes.fromAddress}>{item.from.slice(0, 6) + '...' + item.from.slice(item.from.length - 4)}</Typography>
                    <Typography className={classes.address}>From</Typography>
                  </Box>
                  <Box>
                    <Typography className={classes.fromAddress}>{item.to.slice(0, 6) + '...' + item.to.slice(item.to.length - 4)}</Typography>
                    <Typography className={classes.address}>To</Typography>
                  </Box>
                </Box>}
                <Box className={classes.values} alignItems={'center'}>
                  <Box className={classes.contentVal}>
                    <Typography className={classes.ethValues}>
                      <img className={classes.chainIcon} src={chainsSymbol === 'ETH' ? ETHIcon : chainsSymbol === 'USDT' ? USDTIcon : chainsSymbol === 'WETH' ? WETHIcon : BUSDIcon} alt="" />{item.priceAmount}
                      <img src={CurrentTheme === Themes.dark ? Share : ShareRed} alt="" onClick={() => {
                        window.open(getBlockChainExplorerAddress(chainId) + 'tx/' + item.transactionHash, "_blank")
                      }} /></Typography>
                    <Typography className={classes.usdtValues}>${item.usPrice.toFixed(2)}</Typography>
                  </Box>
                </Box>
              </Box>
          })}
        </Box>
      </Box>

    </>
  );
};
