import BigNumber from "bignumber.js";
import { useQuery } from '@redux-requests/react';
import { useCount } from 'modules/common/hooks/useTimer';
import { useEffect, useState } from 'react';
import { ISetAccountData, setAccount } from 'modules/account/store/actions/setAccount';
import { ERC20ABI } from 'modules/web3/contracts';

export function getUSDTPrice(address: string, price: number) {
    const currencyRate = JSON.parse(localStorage.getItem('currencyRate') || '');
    let usdtPrice = 0;
    currencyRate && currencyRate.map((item: any) => {
        if (item.currency_address.toLocaleLowerCase() === address.toLocaleLowerCase()) {
            usdtPrice = new BigNumber(item.rate_usd).multipliedBy(price).decimalPlaces(2).toNumber()
        }
        return '';
    })
    return usdtPrice;
}

export function getCurrentSymbol(address: string) {
    const currencyRate = JSON.parse(localStorage.getItem('currencyRate') || '');
    let currentSymbol = 'ETH';
    address && currencyRate && currencyRate.map((item: any) => {
        if (item.currency_address.toLocaleLowerCase() === address.toLocaleLowerCase()) {
            currentSymbol = item.currency_symbol
        }
        return '';
    })
    return currentSymbol;
}

export const useBalance = () => {
    const { loading, data } = useQuery<ISetAccountData | null>({
        type: setAccount.toString(),
    });
    const web3 = data?.web3;
    const address = data?.address;

    const count = useCount(5e3);
    const [balance, setBalance] = useState<any>();
    useEffect(() => {
        if (web3 && address && !loading) {
            (async () => {
                let WETHAddress = '', USDTAddress = '';
                const currencyRate = JSON.parse(localStorage.getItem('currencyRate') || '');
                currencyRate && currencyRate.map((item: any) => {
                    if (item.currency_symbol === 'WETH') {
                        WETHAddress = item.currency_address
                    } else if (item.currency_symbol === 'USDT') {
                        USDTAddress = item.currency_address
                    }
                    return '';
                })
                const WETHERC20Contract = new web3.eth.Contract(
                    ERC20ABI,
                    WETHAddress,
                );
                const USDTERC20Contract = new web3.eth.Contract(
                    ERC20ABI,
                    USDTAddress,
                );
                // const BUSDERC20Contract = new web3.eth.Contract(
                //     ERC20ABI,
                //     BUSDAddress,
                // );
                const wethDecimals = await WETHERC20Contract.methods.decimals().call();
                const wethBalanceRes = await WETHERC20Contract.methods.balanceOf(address).call();
                const wethBalance = new BigNumber(wethBalanceRes).dividedBy(
                    new BigNumber(10).pow(wethDecimals),
                );

                const usdtDecimals = await USDTERC20Contract.methods.decimals().call();
                const usdtBalanceRes = await USDTERC20Contract.methods.balanceOf(address).call();
                const usdtBalance = new BigNumber(usdtBalanceRes).dividedBy(
                    new BigNumber(10).pow(usdtDecimals),
                );

                // const busdDecimals = await BUSDERC20Contract.methods.decimals().call();
                // const busdBalanceRes = await BUSDERC20Contract.methods.balanceOf(address).call();
                // const busdBalance = new BigNumber(busdBalanceRes).dividedBy(
                //     new BigNumber(10).pow(busdDecimals),
                // );
                setBalance({
                    WETH: wethBalance || 0,
                    USDT: usdtBalance || 0,
                    // BUSD: busdBalance || 0,
                })
            })();
        }
        // eslint-disable-next-line
    }, [count, address, loading, web3]);
    return { balance };
};