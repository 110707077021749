import {getJWTToken} from '../../../../common/utils/localStorage';
import axios from 'axios';


export const getSearchMarket = async (request: any) => {
  const token = getJWTToken() ?? '';
  let res;
  await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_BASE}/client/market/v1/fuzzy_search`,
    params: request,
    headers: {
      token: token ?? '',
    },
  }).then((response) => {
    res = response.data;
  }, error => {
    res = error.response.data;
  });
  return res;
};
// https://test-backend.hotluuu.io/client/market/v1/fuzzy_search?q=0x
