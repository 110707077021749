import { makeStyles, Theme } from '@material-ui/core';

export const useCollectionNftListStyle = makeStyles<Theme>((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '0 auto',
    // paddingBottom: '144px',
    backgroundColor: '#000',
    color: theme.palette.common.white,
    minHeight: '700px',
  },
  title: {
    // fontSize: '50px',
    letterSpacing: '-0.01em',
    color: theme.palette.common.white,
    fontWeight: 700,
    paddingTop: '50px',
    fontFamily: 'Pretendard',
  },
  content: {
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.palette.common.white,
    paddingTop: '25px',
    maxWidth: '643px',
    fontFamily: 'Roboto Condensed',
  },
  showMore: {
    color: theme.palette.common.white,
    fontSize: '16px',
    lineHeight: '24px',
    cursor: 'pointer',
    fontFamily: 'Roboto Condensed',
  },
}));

