import { useQuery } from '@redux-requests/react';
import { NoItems } from 'modules/common/components/NoItems';
import { uid } from 'react-uid';
import { useState, useEffect } from 'react';
import { ProductCards } from 'modules/Profile/components/ProductCards';
import { ProductCardSkeleton } from 'modules/Profile/components/ProductCards/ProductCardSkeleton';
import { NftItemCard } from 'modules/Profile/components/NftItemCard';
import { Box, Typography } from '@material-ui/core';
import { useCollectedStyles } from './useCollectedStyles';
import Filters from 'modules/Profile/assets/filter.svg';
import FiltersRed from 'modules/Profile/assets/filterRed.svg';
// import Layout from 'modules/Profile/assets/layout.svg';
// import LayoutMore from 'modules/Profile/assets/layoutMore.svg';
import { CollectedFilter } from 'modules/Profile/components/CollectedFilter';
import { getUserCollected } from 'modules/Profile/actions/profile';
import { CollectionRoutesConfig } from 'modules/Collection/Routes';
import { CollectionInfoInterface } from 'modules/Collection/interface/collectionInterface';
import { getUserCollectionsList } from 'modules/Collection/actions/collection';
import { ProfileFoterDialog } from 'modules/Profile/components/ProfileFilterDialog/ProfileFoterDialog';
import { useIsMDDown } from 'modules/themes/useTheme';
import { useAccount } from 'modules/account/hooks/useAccount';
import { CurrentTheme, Themes } from 'modules/themes/types';
import { ViewAll } from 'modules/Market/components/ViewAll';
import { t } from 'modules/i18n/utils/intl';
import { useDispatchRequest } from '@redux-requests/react';

export const Collected: React.FC<{
  reload?: () => void;
}> = function ({ reload }) {
  const [layoutNum, setLayoutNum] = useState(sessionStorage.getItem('profileFilterFlag') === 'true' ? 4 : 6);
  const [filterFlag, setFilterFlag] = useState(sessionStorage.getItem('profileFilterFlag') === 'true' ? true : false);
  const [dialogFlag, setDialogFlag] = useState(sessionStorage.getItem('profileDialogFlag') === 'true' ? true : false);
  const [collectionList, setCollectionList] = useState<CollectionInfoInterface[]>([]);
  const [page, setPage] = useState(1);
  const classes = useCollectedStyles();
  const { address } = useAccount();
  const isMobile = useIsMDDown();

  const { data: dataCollected } = useQuery<any>({
    type: getUserCollected.toString(),
  });

  const dispatch = useDispatchRequest();

  useEffect(() => {
    if (!address) {
      return;
    }
    async function loadData() {
      const data: any = await getUserCollectionsList({ address: address });
      if (data.code === 200 && data.data && data.data.collectionList) {
        setCollectionList(data.data.collectionList);
      }
    }
    loadData();
  }, [address]);
  const close = () => {
    setDialogFlag(false);
    if (isMobile) {
      sessionStorage.setItem('profileDialogFlag', (false).toString())
    } else {
      sessionStorage.setItem('profileFilterFlag', (false).toString())
    }
  }

  const loadingMore = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const profileFilterType = queryParameters.get('profileFilterType') ?? '';
    const profileFilterAddressArr = queryParameters.get('profileFilterAddressArr') ?? '';
    const pageSize = 100 * (page + 1);
    console.log(pageSize)
    dispatch(getUserCollected({
      address: address,
      saleType: profileFilterType,
      page: 1,
      pagesize: pageSize,
      collectionAddress: profileFilterAddressArr
    }));
    setPage(page + 1);
  }

  return (
    <>
      <Box display={'flex'} style={{ width: '100%' }}>
        <Box>
          {filterFlag && !isMobile && <CollectedFilter collectionList={collectionList} />}
        </Box>
        <Box style={{ width: '100%' }}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={3}>
            <Box display={'flex'} alignItems={'center'}>
              <Typography className={classes.borders}><img src={CurrentTheme === Themes.dark ? Filters : FiltersRed} alt=""
                onClick={() => {
                  setLayoutNum(filterFlag ? 6 : 4);
                  if (isMobile) {
                    setDialogFlag(!dialogFlag)
                    sessionStorage.setItem('profileDialogFlag', (!dialogFlag).toString())
                  } else {
                    setFilterFlag(!filterFlag);
                    sessionStorage.setItem('profileFilterFlag', (!filterFlag).toString())
                  }
                }}
              /></Typography>
              {/* <Typography className={classes.borders}>
                <img src={Layout} onClick={() => { setLayoutNum(4) }} alt="" />
                <img src={LayoutMore} onClick={() => { setLayoutNum(6) }} className={classes.layoutMore} alt="" />
              </Typography> */}
            </Box>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Box>
                <Typography className={classes.result}>{dataCollected && dataCollected.total} {t('public.results')}</Typography>
                {/* <Typography className={classes.seconds}>{timers}</Typography> */}
              </Box>
              <Typography className={classes.circle}></Typography>
            </Box>
          </Box>
          {dataCollected && dataCollected.assetList && dataCollected.assetList.length > 0 ? <ProductCards
            layoutNum={layoutNum}
          >
            {(false) ? (
              <ProductCardSkeleton />
            ) : (
              dataCollected.assetList.map((item: any) => (
                <NftItemCard
                  key={uid(item)}
                  layoutNum={layoutNum}
                  item={item}
                  types={true}
                />
              ))
            )}
          </ProductCards> : <NoItems
            href={CollectionRoutesConfig.Collection.generatePath()}
          />}
        </Box>
        <ProfileFoterDialog
          openDialog={dialogFlag}
          onDialogClose={close}
          collectionList={collectionList}
        />
      </Box>
      {dataCollected && dataCollected.assetList && dataCollected.assetList.length > 99 && <Box display={'flex'} justifyContent={'center'} mt={8}>
        <ViewAll text={t('public.loadMore')} emitClick={loadingMore} />
      </Box>
      }
    </>
  );
};
