import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { CurrentTheme } from 'modules/themes/types';
import DarkDiscordPng from 'assets/img/footer/darkDiscord.png';
import DarkTwitterPng from '../assets/darkTwitter.png';
import DarkInstagramPng from 'assets/img/footer/darkInstagram.png';
import DarkFacebookPng from 'assets/img/footer/darkFacebook.png';
import DarkTelegramPng from 'assets/img/footer/darkTelegram.png';
import { useQuery } from '@redux-requests/react';
import { getDfdunkSns, IDfdunkSnsData } from 'modules/common/actions/commonRequest';
import { useFooterIconStyles } from './useFooterIconStyle';
import {t} from 'modules/i18n/utils/intl';
import {ContactUsDialog} from './contactUsDialog/contactUsDialog';

import classNames from 'classnames';
export const FooterIcon = () => {
  const classes = useFooterIconStyles();
  const [open, setOpen] = useState(false);
  const { data } = useQuery<IDfdunkSnsData | null>({
    type: getDfdunkSns.toString(),
  });
  return (
    <Box className={classes.rightContent}>
      <ContactUsDialog open={open} setOpen={setOpen}/>
      <Box className={classes.root}>
        {data?.discord && <a href={data.discord} rel='noreferrer' target={'_blank'}>
          <img className={classes.discord} src={CurrentTheme === 'dark' ? DarkDiscordPng : DarkDiscordPng} alt='' />
        </a>}
        {data?.twitter && <a href={data.twitter} rel='noreferrer' target={'_blank'}>
          <img className={classes.twitter} src={CurrentTheme === 'dark' ? DarkTwitterPng : DarkTwitterPng} alt='' />
        </a>}
        {data?.instagram && <a href={data.instagram} rel='noreferrer' target={'_blank'}>
          <img className={classes.instagram} src={CurrentTheme === 'dark' ? DarkInstagramPng : DarkInstagramPng} alt='' />
        </a>}
        {data?.facebook && <a href={data.facebook} rel='noreferrer' target={'_blank'}>
          <img className={classes.facebook} src={CurrentTheme === 'dark' ? DarkFacebookPng : DarkFacebookPng} alt='' />
        </a>}
        {data?.telegram && <a href={data.telegram} rel='noreferrer' target={'_blank'}>
          <img className={classes.telegram} src={CurrentTheme === 'dark' ? DarkTelegramPng : DarkTelegramPng} alt='' />
        </a>}
      </Box>
      <Box className={classNames(classes.root,classes.rootH5)}>
        <Box display={'flex'}>
          <Box className={classes.contact} onClick={()=>{setOpen(true)}}>{t('footer.contactUs')}</Box>
          {/* <a href='/'>{t('footer.docs')}</a> */}
          <a href='/Audit-Report.pdf' target='_blank'>{t('footer.auditReport')}</a>
        </Box>
        <Box>
          {/* <a href='/'>{t('footer.brand')}</a> */}
          <a href='/terms'>{t('footer.terms')}</a>
          <a href='/privacy'>{t('footer.privacy')}</a>
        </Box>
      </Box>
    </Box>
  )
};
