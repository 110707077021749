import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { Route } from 'react-router-dom';

const PATH_INFEX = '/collection';

export const CollectionRoutesConfig: { [key: string]: RouteConfiguration } = {
  Collection: {
    path: PATH_INFEX,
    generatePath: () => PATH_INFEX,
  },
};

const LoadableDFdunkContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens').then(module => module.Collection),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export function CollectionRoutes() {
  return (
    <Route
      path={CollectionRoutesConfig.Collection.path}
      exact={true}
      component={LoadableDFdunkContainer}
    />
  );
}
