import { makeStyles, Theme, fade } from '@material-ui/core';
import Continue from 'modules/layout/components/DFduckFooter/assets/confirm.png';
import ContinueRed from 'modules/layout/components/DFduckFooter/assets/confirmRed.png';
import { CurrentTheme, Themes } from 'modules/themes/types';

export const useContactUsDialogStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'block',
    maxWidth: 540,
    padding: theme.spacing(4, 2.5),
    border: CurrentTheme === Themes.dark ? '1px solid #F8F050' : '1px solid #FCFFE7',
    borderRadius: 0,
    color: '#fff',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 4),
    },
    backgroundColor: '#000'
  },
  close: {
    position: 'absolute',
    top: 5,
    right: 5,
    width: 40,
    height: 40,
    padding: 0,
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      top: 20,
      right: 20,
    },
    '& svg': {
      width: '28px',
      height: '28px',
      color: '#fff'
    },
  },
  title: {
    marginBottom: theme.spacing(3),
    fontSize: '32px',
  },
  content: {
    paddingBottom: '24px',
  },
  nftImg: {
    width: '72px',
  },
  nftMsg: {
    display: 'flex',
    alignItems: 'center'
  },
  collectionName: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: '14px',
    lineHeight: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
    '& img': {
      width: '12px',
      marginLeft: '6px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0px',
      },
    }
  },
  nftName: {
    color: '#fff',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
    marginTop: '8px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  usdtValues: {
    color: '#697077',
    fontSize: '14px',
    letterSpacing: '0.22px',
    lineHeight: '14px',

  },
  goWallet: {
    color: '#fff',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
    marginTop: '24px',
    cursor: 'pointer',
    '& span': {
      color: CurrentTheme === Themes.dark ? '#2EF2F1' : '#EA3375',
    }
  },
  approve: {
    color: '#fff',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '16px',
    marginTop: '12px'
  },
  ethValues: {
    color: '#fff',
    fontSize: '18px',
    lineHeight: '32px',
    marginTop: '2px',
    fontFamily: 'Pretendard',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '& span': {
      color: '#fff',
      letterSpacing: '0.22px',
      fontSize: '11px',
      fontWeight: 400,
      marginLeft: '6px',
      fontStyle: 'normal',
      fontFamily: '"Roboto Condensed",Pretendard',
    },
    '& img': {
      height: '18px',
      marginRight: '4px'
    }
  },
  continue: {
    color: '#fff',
    fontWeight: 700,
    width: '144px',
    fontSize: '16px',
    backgroundColor: 'transparent',
    backgroundImage: CurrentTheme === Themes.dark ? `url('${Continue}')` : `url('${ContinueRed}')`,
    backgroundRepeat: 'no-repeat',
    fontFamily: 'Pretendard',
    borderRadius: 0,
    '&:hover': {
      opacity: '0.8',
      backgroundColor: 'transparent',
    },
  },
  pricesList: {
    border: CurrentTheme === Themes.dark ? '1px solid #F8F050' : '1px solid #FCFFE7',
    marginTop: '12px',
    padding: '14px 18px',
    color: fade(theme.palette.common.white, .8),
    '& span': {
      fontSize: '12px',
      lineHeight: '12px',
    },

  },
  pricesItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '12px',
    '&:first-child': {
      marginTop: '0',
    },
  },
  buyNFT: {
    margin: '40px auto 10px',
    color: theme.palette.common.black,
    width: '192px',
    fontWeight: 700,
    fontSize: '16px',
    height: '46px',
    backgroundColor: 'transparent',
    backgroundImage: CurrentTheme === Themes.dark ? `url('${Continue}')` : `url('${ContinueRed}')`,
    backgroundSize: 'contain',
    padding: '0',
    borderRadius: 0,
    display: 'block',
    position: 'relative',
    fontFamily:'Pretendard,"Roboto Condensed"',
    '&:hover': {
      opacity: '0.8',
      backgroundColor: 'transparent',
    },
  },
  dialogHead:{
    display:'flex',
    justifyContent:'center',
    '& img': {
      maxHeight:'80px',
      maxWidth:'410px',
      [theme.breakpoints.down('sm')]: {
        maxWidth:'340px',
      },
    },
    '& a': {
      paddingRight:'0',
    },
  },
  contactmsg:{
    margin:"0 auto",
    
    maxWidth:'340px',
    '& span':{
      display:'inline-block',
      marginLeft:'6px'
    },
    '& h4':{
      fontSize:'18px',
      [theme.breakpoints.down('sm')]: {
        fontSize:'16px',
      },
    },
  }
}));
