import { makeStyles, Theme } from '@material-ui/core';


export const useLogoStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '20px',
    [theme.breakpoints.up('xs')]: {
      paddingRight: '5px',
    },
    [theme.breakpoints.up('sm')]: {
      paddingRight: '5px',
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: '8px',
    },
    [theme.breakpoints.up('lg')]: {
      paddingRight: '12px',
    },
    [theme.breakpoints.up('xl')]: {
      paddingRight: '14px',
    },
    [theme.breakpoints.up('HD')]: {
      paddingRight: '16px',
    },
    [theme.breakpoints.up('WXGAPlus')]: {
      paddingRight: '20px',
    },
  },
  logo: {
    maxHeight: '42px',
    maxWidth:'300px',
    [theme.breakpoints.down('sm')]: {
      maxWidth:'190px'
    },
  },
  // diflogo: {
  //   height: '42px',
  // },
  projectName: {
    color: theme.palette.common.white,
    fontFamily: 'Pretendard',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '24px',
    paddingLeft: '8px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('xs')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '20px',
    },
  },

}));
