import * as React from 'react';
import { ChangeEvent, useCallback } from 'react';
import { useFilterSearchStyle } from './useFilterSearch';

import { Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { getSearchMarket } from '../../actions/headerApi';
import { useHistory } from 'react-router-dom';
import { CollectionDetalilRoutesConfig } from 'modules/CollectionDetail/Routes';
import BigNumber from 'bignumber.js';
import CollectorPng from 'modules/layout/components/DFduckHeader/assets/collector.png';
import LightCollectorPng from 'modules/layout/components/DFduckHeader/assets/light-collector.png';
import { CurrentTheme, Themes } from 'modules/themes/types';

// import MagnifierImg from '../../assets/magnifier.png';
import { currencyRateInterface } from 'modules/common/components/HeaderDrawer/components/HeaderBalance';
import { uid } from 'react-uid';
import {t} from 'modules/i18n/utils/intl';
//
// function sleep(delay = 0) {
//   return new Promise((resolve) => {
//     setTimeout(resolve, delay);
//   });
// }

interface topSearchInterface {
  address: string;
  floor_price: number;
  logo: string;
  name: string;
  nft_count: number;
  price_address: string;
  price_amount: number;
  sale_id: string;
}

interface FilterSearchInterface {
  currencyRate: currencyRateInterface[]
}

export const FilterSearch = (
  {
    currencyRate,
  }: FilterSearchInterface,
) => {
  const classes = useFilterSearchStyle();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<topSearchInterface[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  // const [first, setFirst] = React.useState(true);
  // const [iptValue, setFilterValue] = React.useState('');
  const history = useHistory();

  const handleExpiration = useCallback((event: ChangeEvent<{ value: unknown }>) => {
    // setFilterValue(event.target.value as string);
    // setFirst(false);
    setLoading(true);
    getSearchMarket({ q: event.target.value as string }).then((res: any) => {
      if (res.code === 200) {
        setOptions([]);
        setOptions([...res.data]);
      }
      setLoading(false);
    });
  }, [setOptions]);


  // React.useEffect(() => {

  //   // let active = true;
  //   if (loading) {
  //     return undefined;
  //   }
  //   (async () => {
  //     if (first) {
  //       return;
  //     }
  //     await sleep(1e3); // For demo purposes.
  //     // if (active) {
  //     setLoading(true);
  //     getSearchMarket({ q: iptValue }).then((res: any) => {
  //       if (res.code === 200) {
  //         console.log(res);
  //         setOptions([]);
  //         setOptions(res.data);
  //       }
  //       setLoading(false);
  //     });
  //     // }
  //   })();
  //
  //   // return () => {
  //   //   active = false;
  //   // };
  // }, [iptValue, first, setFilterValue]);


  return (
    <Autocomplete
      classes={{
        root: classes.root,
        paper: classes.paper,
        listbox: classes.listbox,
        loading: classes.loading,
        noOptions: classes.noOptions,
        endAdornment: classes.endAdornment,
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onBlur={() => {
        setInputValue('');
      }}
      onFocus={() => {
        // setFirst(false);
      }}
      getOptionLabel={(option: topSearchInterface) => option.name}
      filterOptions={(x) => x}
      options={options}
      loading={loading}
      noOptionsText={t('public.noResult')}
      inputValue={inputValue}
      selectOnFocus
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderOption={(props, option: any) => (
        <>
          <Box key={uid(props)} className={classes.item} component='div' {...props} onClick={() => {
            history.push(CollectionDetalilRoutesConfig.nftList.generatePath('Items', props.address));
            setTimeout(() => {
              setInputValue('');
            }, 100)
          }}>
            <Box className={classes.collectorLogoWrap}>
              <img
                className={classes.collectorLogo}
                loading='lazy'
                width='20'
                src={props.logo}
                srcSet={props.logo}
                alt=''
              />
            </Box>
            <Box className={classes.collectorRight}>
              <Box className={classes.collectorName}>
                {props.name}
                <img src={CurrentTheme === Themes.dark ? CollectorPng : LightCollectorPng} alt='' />
              </Box>
              <Box className={classes.collectorInfo}>
                {props.nft_count}&nbsp;items
                <Box className={classes.collectorPrice}>
                  「
                  {new BigNumber(props.price_amount).toFormat(4, 2)}
                  {
                    currencyRate && currencyRate.map((d: currencyRateInterface) => {
                      if (d.currency_address.toLowerCase() === props.price_address.toLowerCase()) {
                        return (
                          <Box className={classes.baseName}>{d.currency_symbol}</Box>
                        );
                      } else {
                        return null
                      }
                    })
                  }
                  」</Box>
              </Box>
            </Box>
          </Box>
        </>

      )}
      renderInput={(params) => (
        <TextField
          onChange={handleExpiration}
          className={classes.searchInput}
          {...params}
          placeholder={t('public.search')}
          InputProps={{
            ...params.InputProps,
            type: 'search',
            style: {
              height: '40px',
              border: 'none',
              color: '#000000',
              padding: 0,
            },
            // startAdornment: (
            //   <InputAdornment position="start">
            //     <AccountCircle />
            //   </InputAdornment>
            // ),
          }}
        />
      )}
    />
  );
};
