import { Grid } from '@material-ui/core';
import React, { ReactNode, useCallback } from 'react';
import { uid } from 'react-uid';
import { ProductCardSkeleton } from './ProductCardSkeleton';
import { useProductCardsStyles } from './useProductCardsStyles';
import { getPercentage } from 'modules/common/utils/styleUtils';

interface IProductCardsProps {
  className?: string;
  children?: ReactNode;
  isLoading?: boolean;
  skeletonsCount?: number;
  layoutNum?: number;
}

export const ProductCards = ({
  children,
  isLoading,
  skeletonsCount = 6,
  layoutNum
}: IProductCardsProps) => {
  const classes = useProductCardsStyles();
  const modifyChildren = useCallback(
    (child: any) => {
      if (child) {
        return (
          <Grid item xs={6} sm={6} lg={4} xl={3} className={classes.col} style={{
            width: getPercentage(1, layoutNum || 6),
            paddingLeft: (layoutNum && layoutNum > 5) ? 8 : 16,
            paddingRight: (layoutNum && layoutNum > 5) ? 8 : 16,
          }}>
            {child}
          </Grid>
        );
      }
    },
    [classes.col, layoutNum],
  );

  const renderedSkeletons = Array(skeletonsCount)
    .fill(0)
    .map((_, i) => <ProductCardSkeleton key={uid(i)} />);

  return (
    <Grid container className={classes.row}>
      {React.Children.map(isLoading ? renderedSkeletons : children, child =>
        modifyChildren(child),
      )}
    </Grid>
  );
};
