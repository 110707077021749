import { useNftItemCardStyle } from './useNftItemCardStyle';
import { Box, Typography } from '@material-ui/core';
import Sell from 'modules/Profile/assets/sell.svg';
import SellRed from 'modules/Profile/assets/sellRed.svg';
import { useHistory } from 'react-router-dom';
// import WETHIcon from 'modules/NftDetail/assets/weth.svg';
// import ETHIcon from 'modules/NftDetail/assets/eth.svg';
// import USDTIcon from 'modules/NftDetail/assets/usdt.svg';
// import BUSDIcon from 'modules/NftDetail/assets/busd.svg';
import { TokenImgComp } from 'modules/common/components/TokenImg';
import React, { useEffect } from 'react';
import { getCurrentSymbol } from 'modules/common/utils/getUSDTprice';
import { fromatTimersStyle } from '../../../common/utils/getTimeRemaining';
import { CurrentTheme, Themes } from 'modules/themes/types';
import { t } from 'modules/i18n/utils/intl';
import { Skeleton } from '@material-ui/lab';

export const NftItemCard = ({
  item,
  layoutNum,
  types,
}: {
  item: any;
  layoutNum: number,
  types?: boolean,
}) => {
  const classes = useNftItemCardStyle();
  const history = useHistory();
  const [chainsSymbol, setChainsSymbol] = React.useState("ETH");
  useEffect(() => {
    if (item && (item.priceToken || item.price_address)) {
      // setChainsSymbol(getCurrentSymbol(item.priceToken || item.price_address));
      if (item.priceToken) {
        setChainsSymbol(item.priceToken)
      } else if (item.price_address) {
        setChainsSymbol(item.price_address)
      }
    }
  }, [setChainsSymbol, item]);
  // const lastPriceAmount = item.last_price_amount || item.LastPrice || item.lastPrice || 0;
  // const lastPriceADDress = item.last_price_address || item.LastPriceToken || item.lastPriceToken;

  return (
    <Box className={classes.root} onClick={() => {
      if (item && item.collectionAddress) {
        history.push(`/nft-detail/${item.collectionAddress}/${item.tokenId}/type`)
      } else {
        history.push(`/nft-detail/${item?.collection_address}/${item?.token_id}/type`)
      }
    }

    }>
      <Box className={classes.card}>
        <Box className={classes.imgBox}>
          {/* <img
            src={item?.image}
            // src="https://i.seadn.io/gae/ZqkbKR_XVwAFUJIDP_MRRw49IsQefDwzJHYVwJJAb1TGUOZt5az9uQV9Kh9dqzhN14h5n8mRdgvZd9siaZf8tntXhc8tQ9Y1jASOhgs?auto=format&w=384"
            className={classes.nft} style={{ height: layoutNum > 5 ? '190px' : '288px' }} alt="" /> */}
            {(item && item.image)? <img
            src={item?.image}
            // src="https://i.seadn.io/gae/ZqkbKR_XVwAFUJIDP_MRRw49IsQefDwzJHYVwJJAb1TGUOZt5az9uQV9Kh9dqzhN14h5n8mRdgvZd9siaZf8tntXhc8tQ9Y1jASOhgs?auto=format&w=384"
            className={classes.nft} style={{ height: layoutNum > 5 ? '190px' : '288px' }} alt="" />
            :<Skeleton className={classes.media} variant="rect" animation="wave"  width={'100%'} height={'100%'}/>}
        </Box>
      </Box>
      <Box className={classes.box}>
        <Typography className={classes.collectionName}>{item?.collection_name}</Typography>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ height: '20px' }}>
          <Typography className={classes.id}>{item?.name}</Typography>
          {(item?.price || item.price_amount) && <Typography className={classes.price}>
            {/*{item?.price_address && <TokenImgComp token={item?.price_address} size={'mini'}/>}*/}
            {/*{item?.priceToken && <TokenImgComp token={item?.priceToken} size={'mini'}/>}*/}
            {<TokenImgComp token={chainsSymbol} size={'mini'} />}
            {/*<img src={chainsSymbol === 'ETH' ? ETHIcon : chainsSymbol === 'WETH' ? WETHIcon : chainsSymbol === 'USDT' ? USDTIcon : BUSDIcon} alt="" />*/}
            {item?.price || item.price_amount}
          </Typography>}
        </Box>
        <Box className={classes.sellBox}>
          {/* {console.log(item && item.bestPrice && item.bestPrice > 0)} */}
          {
            (item && item.list_deadline && item.list_deadline !== 0) ? <Typography className={classes.lastPrice}>
              Sale ends at {fromatTimersStyle(parseInt(String(item.list_deadline) + '000') || 0)}
            </Typography> :
              (item && item?.bestPrice > 0) ?
                <Typography className={classes.lastPrice}>
                  Best offer {item.bestPrice}{getCurrentSymbol(item.bestPriceToken)}
                </Typography> :
                (item && item?.lastPrice > 0) && <Typography className={classes.lastPrice}>
                  Last sale {item.lastPrice}{getCurrentSymbol(item.lastPriceToken)}
                </Typography>
          }
          <Box className={classes.sell}>
            <Typography>
              <img src={CurrentTheme === Themes.dark ? Sell : SellRed} alt="" />
              {/*{types ? 'Sell' : 'Buy'}*/}
              {t(types ? 'proFile.sell' : 'proFile.buy')}
            </Typography>
            {item.floor_price && <Typography>
              Floor:&nbsp;&nbsp;
              <TokenImgComp size={'mini'} token={'ETH'} />
              {item.floor_price.floor_price_eth.toFixed(4) < 0.00001 ? '<0.0001' : item.floor_price?.floor_price_eth.toFixed(4)}
            </Typography>
            }
          </Box>
        </Box >
      </Box >
    </Box >
  );
};
