import { fade, makeStyles, Theme } from '@material-ui/core';

export const useSelectStyles = makeStyles<Theme>(theme => ({
  menuPaper: {
    border: `1px solid ${theme.palette.grey[50]}`,
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 0,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.common.white,
    '& .MuiListItem-button': {
      fontSize: 'inherit',

      '&:hover': {
        background: fade(theme.palette.common.white, 0.04),
      },

      '&.Mui-selected': {
        color: fade(theme.palette.common.white,.9),
        background: 'none',
        cursor: 'default',
      },
    },
  },
}));
