import { usePropertiesStyle } from 'modules/NftDetail/components/properties/usePropertiesStyle';
import { useNftAttributesStyle } from './useNftAttributesStyle';
import { Box, Typography, Checkbox } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { ExpandMoreIcon } from 'modules/NftDetail/assets/expandMoreIcon';
import FilterIcon from 'modules/Explore/assets/fitters.svg';
import { useCurrencyStyle } from 'modules/Explore/components/currency/useCurrencyStyle';
import { uid } from 'react-uid';
// import { t } from '../../../i18n/utils/intl';
// const list = [
//   {
//     label: 'Set',
//     value: 'set',
//     active:false,
//   },
//   {
//     label: 'Rarity',
//     value: 'rarity',
//     active:false,
//   },
//   {
//     label: 'Series',
//     value: 'series',
//     active:false,
//   },
// ];
const show = process.env.REACT_APP_BASE_ENV_BRANCH === 'DFDUNK' ? ['series', 'set', 'rarity'] : ['series_name', 'clothes', 'point', 'clothes_test', 'series_name_test'];
export const NftAttributes = (
  {
    setAttributes,
    attributes,
    setAttributesList,
    attributesObj
  }: {
    setAttributes: React.Dispatch<React.SetStateAction<{ [x: string]: boolean; }>>;
    setAttributesList: React.Dispatch<React.SetStateAction<string>>;
    attributes: { [x: string]: boolean; }
    attributesObj?: { [x: string]: any }
  }
) => {
  const accordionClasses = usePropertiesStyle();
  const classes = useNftAttributesStyle();
  const currencyStyle = useCurrencyStyle();


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updateState = { ...attributes, [event.target.name]: event.target.checked };
    console.log(updateState)
    setAttributes(updateState);
    let addressArr = '';
    let series = '';
    let set = '';
    let rarity = '';
    let series_name = '',
      clothes = '',
      point = '',
      clothes_test = '',
      series_name_test = '';
    for (let key in updateState) {
      if (updateState[key]) {
        let start = key.split(':::::');
        if ('series' === start[0]) {
          series += `${start[1]},`
        }
        if ('set' === start[0]) {
          set += `${start[1]},`
        }
        if ('rarity' === start[0]) {
          rarity += `${start[1]},`
        }
        if ('series_name' === start[0]) {
          series_name += `${start[1]},`
        }
        if ('clothes' === start[0]) {
          clothes += `${start[1]},`
        }
        if ('point' === start[0]) {
          point += `${start[1]},`
        }
        if ('clothes_test' === start[0]) {
          clothes_test += `${start[1]},`
        }
        if ('series_name_test' === start[0]) {
          series_name_test += `${start[1]},`
        }
        // addressArr+=`&properties[${start[0]}]=${start[1]}`;
      }
    }

    series = series.substring(0, series.lastIndexOf(','))
    set = set.substring(0, set.lastIndexOf(','))
    rarity = rarity.substring(0, rarity.lastIndexOf(','))
    series_name = series_name.substring(0, series_name.lastIndexOf(','));
    clothes = clothes.substring(0, clothes.lastIndexOf(','));
    point = point.substring(0, point.lastIndexOf(','));
    clothes_test = clothes_test.substring(0, clothes_test.lastIndexOf(','));
    series_name_test = series_name_test.substring(0, series_name_test.lastIndexOf(','));
    console.log('clothes-------', encodeURI(clothes))
    let a = series ? `&properties[series]=${series}` : '';
    let b = set ? `&properties[set]=${set}` : '';
    let c = rarity ? `&properties[rarity]=${rarity}` : '';
    let d = series_name ? `&properties[series_name]=${series_name}` : '';
    let e = clothes ? `&properties[clothes]=${encodeURIComponent(clothes)}` : '';
    let f = point ? `&properties[point]=${point}` : '';
    let h = clothes_test ? `&properties[clothes_test]=${clothes_test}` : '';
    let i = series_name_test ? `&properties[series_name_test]=${series_name_test}` : '';
    addressArr = a + b + c + d + e + f + h + i;
    console.log(encodeURI(addressArr));
    setAttributesList(addressArr);
  };

  return (
    <Box className={classes.root}>
      {
        attributesObj && Object.keys(attributesObj).map((d: any) => {
          return show.some(i => i === d) && attributesObj[d].length > 0 && <Accordion
            key={uid(d)}
            classes={{
              root: classes.accordion,
            }}
            defaultExpanded={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              // aria-controls="panel1a-content"
              // id="panel1a-header"
              classes={{
                root: classes.itemSummary,
                expanded: classes.itemExpanded,
                expandIcon: accordionClasses.expandIcon,
              }}
            >
              <Box>
                <Typography className={accordionClasses.heading}><img src={FilterIcon} alt="" />
                  {/*{t('collection.attrbutes')}*/}
                  {d}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              classes={{
                root: classes.accordionDetail,
              }}
            >
              <Box className={classes.activity}>
                {attributesObj && attributesObj[d] && attributesObj[d].map((item: any) => {
                  return <Box key={uid(item)}
                    className={classes.activityItem}>
                    <Typography className={classes.collectionNum}>
                      {item.label}
                    </Typography>
                    <Typography className={classes.collectionMsg}>
                      <Checkbox
                        checked={attributes[item.active] || false}
                        onChange={handleChange}
                        name={item.active}
                        classes={{
                          root: currencyStyle.chectboxRoot,
                          checked: currencyStyle.chectboxChecked,
                        }}
                      /></Typography>
                  </Box>
                })}
              </Box>
            </AccordionDetails>
          </Accordion>
        })
      }
      {/*<Accordion*/}
      {/*  classes={{*/}
      {/*    root: classes.accordion,*/}
      {/*  }}*/}
      {/*  defaultExpanded={true}*/}
      {/*>*/}
      {/*  <AccordionSummary*/}
      {/*    expandIcon={<ExpandMoreIcon />}*/}
      {/*    // aria-controls="panel1a-content"*/}
      {/*    // id="panel1a-header"*/}
      {/*    classes={{*/}
      {/*      root: classes.itemSummary,*/}
      {/*      expanded: classes.itemExpanded,*/}
      {/*      expandIcon: accordionClasses.expandIcon,*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Box>*/}
      {/*      <Typography className={accordionClasses.heading}><img src={FilterIcon} alt="" />*/}
      {/*        {t('collection.attrbutes')}*/}
      {/*      </Typography>*/}
      {/*    </Box>*/}
      {/*  </AccordionSummary>*/}
      {/*  <AccordionDetails*/}
      {/*    classes={{*/}
      {/*      root: classes.accordionDetail,*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Box className={classes.activity}>*/}
      {/*      {list.map((item: any) => {*/}
      {/*        return <Box key={uid(item)}*/}
      {/*                    className={classes.activityItem}>*/}
      {/*          <Typography className={classes.collectionNum}>*/}
      {/*            {item.label}*/}
      {/*          </Typography>*/}
      {/*          <Typography className={classes.collectionMsg}>*/}
      {/*            <Checkbox*/}
      {/*              checked={attributes[item.value] || false}*/}
      {/*              onChange={handleChange}*/}
      {/*              name={item.value}*/}
      {/*              classes={{*/}
      {/*                root: classes.chectboxRoot,*/}
      {/*                checked: classes.chectboxChecked,*/}
      {/*              }}*/}
      {/*            /></Typography>*/}
      {/*        </Box>*/}
      {/*      })}*/}
      {/*    </Box>*/}
      {/*  </AccordionDetails>*/}
      {/*</Accordion>*/}
    </Box>
  );
};
