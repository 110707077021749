import { makeStyles, Theme, fade } from '@material-ui/core/styles';

export const useNftItemCardStyle = makeStyles<Theme>(theme => ({
  root: {
    position: 'relative',
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: '0',
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover $sell': {
      display: 'flex'
    },
    '&:hover $lastPrice': {
      display: 'none'
    },
  },
  // nft: {
  //   display: 'block',
  //   width: 'auto',
  //   height: '208px',
  //   margin: '10px auto'
  // },
  box: {
    padding: '12px'
  },
  collectionName: {
    fontSize: '12px',
    color: fade(theme.palette.common.white, .9),
    fontWeight: 400,
    lineHeight: '16px'
  },
  id: {
    fontSize: '12px',
    color: theme.palette.common.white,
    fontWeight: 400,
    lineHeight: '20px'
  },
  price: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '11px',
    color: theme.palette.common.white,
    fontWeight: 400,
    lineHeight: '20px',
  },
  sellBox: {
    borderTop: '1px solid #E6E8EC',
    height: '30px',
    marginTop: '8px',
  },
  sell: {
    display: 'none',
    alignItems: 'center',
    justifyContent:'space-between',
    marginRight: '4px',
    paddingTop: '8px',
    color: theme.palette.grey[200],
    fontSize: '14px',
    fontWeight: 700,
    '& img': {
      marginRight: '4px'
    }
  },
  flex:{
    display:'flex',
    alignItems:'center'
  },
  lastPrice: {
    fontSize: '12px',
    lineHeight: '38px',
    color: '#d3cf7a'
  },
  card: {
    height: '100%',
    paddingBottom: '100%',
    overflow: 'hidden',
    position: 'relative'
  },
  imgBox: {
    boxSizing: 'border-box',
    display: 'block',
    overflow: 'hidden',
    width: 'initial',
    height: 'initial',
    background: 'none',
    opacity: 1,
    border: 0,
    margin: 0,
    padding: 0,
    position: 'absolute',
    inset: 0
  },
  nft: {
    position: 'absolute',
    inset: 0,
    boxSizing: 'border-box',
    padding: 0,
    border: 'none',
    margin: 'auto',
    display: 'block',
    width: 0,
    height: 0,
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: '100%',
    maxHeight: '100%',
    objectFit: 'contain'
  },
  media:{
    backgroundColor:'rgba(256, 256, 256, 0.2)'
  }
}));
