import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { Route } from 'react-router-dom';
import { ProfileTab } from 'modules/Profile/screens/Profile';
import { useQueryParams } from 'modules/common/hooks/useQueryParams';
import { generatePath } from 'react-router-dom';


const PATH_PROFILE = '/profile';
const PATH_PROFILE_EDIT = '/profile_edit';
export const PATH_OTHER_PROFILE_TABS = `${PATH_PROFILE}?tab=:tab`;
export const defaultOtherProfileTab = ProfileTab.collections;

export const ProfileRoutesConfig: { [key: string]: RouteConfiguration } = {
  UserProfile: {
    path: PATH_PROFILE,
    generatePath: (tab?: ProfileTab) =>
      generatePath(PATH_OTHER_PROFILE_TABS, {
        tab: tab ?? defaultOtherProfileTab,
      }),
    useParams: () => {
      const query = useQueryParams();
      // const { address } = useParams<{
      //   address: string;
      // }>();
      return {
        tab: query.get('tab') ?? defaultOtherProfileTab,
      };
    },
  },
  UserEdit: {
    path: PATH_PROFILE_EDIT,
    generatePath: () => PATH_PROFILE_EDIT,
  }
};


const LoadableProfileContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens').then(module => module.Profile),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

const LoadableProfileEdit: LoadableComponent<any> = loadable(
  async () =>
    import('./screens/editProfile').then(module => module.EditProfile),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export function ProfileRoutes() {
  return (
    <>
      <Route
        path={ProfileRoutesConfig.UserProfile.path}
        exact={true}
        component={LoadableProfileContainer}
      />
      <Route
        path={ProfileRoutesConfig.UserEdit.path}
        exact={true}
        component={LoadableProfileEdit}
      />
    </>
  );
}
