import { makeStyles, Theme, fade } from '@material-ui/core';

export const FOOTER_MIN_HEIGHT = 64;

export const useFooterStyles = makeStyles<Theme>(theme => ({
  root: {
    paddingTop: '45px',
    paddingBottom: '50px',
    [theme.breakpoints.up('md')]: {
      minHeight: FOOTER_MIN_HEIGHT,
    },
    color: theme.palette.common.white,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  copyright: {
    textAlign: 'left',
    '& p': {
      fontSize: 12,
      lineHeight: '16px',
      marginBottom: '5px',
    },
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    },
  },
  none: {
    paddingLeft: '10px',
    color: fade(theme.palette.common.white, .8),
  },
  projectContent:{
    textAlign:'left',
    [theme.breakpoints.down('md')]: {
      textAlign:'center',
    },
  },
  fastNft: {
    color: fade(theme.palette.common.white, .8),
  },

}));
