import { IconButton } from '@material-ui/core';
import { Discord } from 'modules/Profile/assets/discord';
import { DiscordRed } from 'modules/Profile/assets/discordRed';
import { Instagram } from 'modules/Profile/assets/instagram';
import { InstagramRed } from 'modules/Profile/assets/instagramRed';
import { Facebook } from 'modules/Profile/assets/facebook';
import { FacebookRed } from 'modules/Profile/assets/facebookRed';
import { Twitter } from 'modules/Profile/assets/twitter';
import { TwitterRed } from 'modules/Profile/assets/twitterRed';
import { Telegram } from 'modules/Profile/assets/telegram';
import { TelegramRed } from 'modules/Profile/assets/telegramRed';
import { Website } from 'modules/Profile/assets/website';
import { WebsiteRed } from 'modules/Profile/assets/websiteRed';
import { Block } from 'modules/Profile/assets/block';
import { BlockRed } from 'modules/Profile/assets/blockRed';
import { t } from 'modules/i18n/utils/intl';
import { uid } from 'react-uid';
import { useSocialStyles } from './useSocialStyles';
import { CurrentTheme, Themes } from 'modules/themes/types';

interface ISocialProps {
  className?: string;
  twitter?: string;
  instagram?: string;
  discord?: string;
  facebook?: string;
  website?: string;
  telegram?: string;
  block?: string;
}

export const Social = ({
  className,
  twitter,
  instagram,
  discord,
  facebook,
  website,
  telegram,
  block,
}: ISocialProps) => {
  const classes = useSocialStyles();

  const items = [
    {
      title: t('social.block'),
      href: block,
      icon: CurrentTheme === Themes.dark ? Block : BlockRed,
    },
    {
      title: t('social.instagram'),
      href: instagram,
      icon: CurrentTheme === Themes.dark ? Instagram : InstagramRed,
    },
    {
      title: t('social.twitter'),
      href: twitter,
      icon: CurrentTheme === Themes.dark ? Twitter : TwitterRed,
    },
    {
      title: t('social.discord'),
      href: discord,
      icon: CurrentTheme === Themes.dark ? Discord : DiscordRed,
    },
    {
      title: t('social.facebook'),
      href: facebook,
      icon: CurrentTheme === Themes.dark ? Facebook : FacebookRed,
    },
    {
      title: t('social.telegram'),
      href: telegram,
      icon: CurrentTheme === Themes.dark ? Telegram : TelegramRed,
    },
    {
      title: t('social.website'),
      href: website,
      icon: CurrentTheme === Themes.dark ? Website : WebsiteRed,
    },

  ];//.filter(item => item.href);

  return (
    <div className={className}>
      <ul className={classes.list}>
        {items.map(({ title, href, icon: Icon }, i) => (
          href && <li className={classes.item} key={uid(i)}>
            <IconButton
              href={href as string}
              target='_blank'
              title={title}
              className={classes.icon}
            >
              <Icon />
            </IconButton>
          </li>
        ))}
      </ul>
    </div>
  );
};
