import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { Route } from 'react-router-dom';

const PATH_TERMS = '/terms';
const PATH_Privacy = '/privacy';

export const DFdunkTermsRoutesConfig: { [key: string]: RouteConfiguration } = {
  Index: {
    path: PATH_TERMS,
    generatePath: () => PATH_TERMS,
  },
};

export const DFdunkPrivacyRoutesConfig: { [key: string]: RouteConfiguration } = {
  Index: {
    path: PATH_Privacy,
    generatePath: () => PATH_Privacy,
  },
};

const LoadableDFdunkContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens/terms').then(module => module.Terms),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

const LoadableDFdunkPrivacy: LoadableComponent<any> = loadable(
  async () =>
    import('./screens/Privacy').then(module => module.Privacy),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export function DFdunkTermsRoutes() {
  return (
    <Route
      path={DFdunkTermsRoutesConfig.Index.path}
      exact={true}
      component={LoadableDFdunkContainer}
    />
  );
}
export function DFdunkPrivacyRoutes() {
  return (
    <Route
      path={DFdunkPrivacyRoutesConfig.Index.path}
      exact={true}
      component={LoadableDFdunkPrivacy}
    />
  );
}
