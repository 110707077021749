import { Box, Container, Typography } from '@material-ui/core';
import { useAccount } from 'modules/account/hooks/useAccount';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { uid } from 'react-uid';
import { useCollectionNftListStyle } from './useCollectionNftListStyle';
import { TabPanel } from 'modules/Profile/components/TabPanel';
import { Tabs } from 'modules/Profile/components/Tabs';
import { Tab } from 'modules/Profile/components/Tabs/Tab';
import { Section } from 'modules/uiKit/Section';
import { CollectionDetalilRoutesConfig } from '../Routes';
import { Collected } from '../components/collected';
import { Activities } from '../components/activities';
import { Header } from '../components/Header';
import { Avatar } from 'modules/Profile/components/Avatar';
import { VerifyComp } from 'modules/common/components/Verify';
import { Social } from 'modules/Profile/components/Social';
import { CurrentTheme, Themes } from 'modules/themes/types';
// import MsgIcon from '../assets/msg.svg';
import DarkMsgIcon from '../assets/darkSvg.svg';
// import { CopyToClip } from 'modules/common/components/CopyToClip';
import { useDispatchRequest, useQuery } from '@redux-requests/react';
import { getCollectionActivies, getCollectionDetail } from 'modules/CollectionDetail/actions/collectionAction';
// import { truncateWalletAddr } from 'modules/common/utils/truncateWalletAddr';
import { useInterval } from 'modules/common/hooks/useInterval';
import { fromatTimers } from 'modules/Profile/actions/profile';
import { useIsMDDown, useIsSMDown } from 'modules/themes/useTheme';
import { getBlockChainExplorerAddress } from '../../common/conts';
import {ShowMoreComp} from '../components/ShowMore';
import {t} from 'modules/i18n/utils/intl';
export enum CollectionTab {
  collections = 'Items',
  activity = 'Activity',
}

export const CollectionNftList = () => {

  const { tab, address: contractAddress } = CollectionDetalilRoutesConfig.nftList.useParams();
  const classes = useCollectionNftListStyle();
  const { chainId } = useAccount();
  const isMobile = useIsMDDown();
  const isSmDown = useIsSMDown();
  const [page, setPage] = useState(1);
  const { push } = useHistory();
  const dispatch = useDispatchRequest();
  const [filterVal, setFilterVal] = React.useState('price_asc');
  const [timers, setTimers] = React.useState('1 sec ago');
  const [timerBegin, setTimerBegin] = React.useState(0);

  const { data: collectionItem } = useQuery<any>({
    type: getCollectionDetail.toString(),
  });


  useEffect(() => {
    if (!contractAddress) {
      return;
    }
    dispatch(getCollectionDetail(contractAddress));
    // dispatch(getCollectionNFTList({ address: contractAddress, off_index: 1, off_size: 50, order_by: '1' }));
    setTimerBegin(1);
  }, [contractAddress, dispatch]);

  const updateData = useCallback(
    (value: CollectionTab) => {
      if (!contractAddress) {
        return;
      }
      switch (value) {
        case CollectionTab.collections: {
          setPage(1);
          // dispatch(getCollectionNFTList({ address: contractAddress, off_index: 1, off_size: 50, order_by: '1' }));
          break;
        }
        case CollectionTab.activity: {
          setPage(1);
          dispatch(getCollectionActivies({ address: contractAddress, page: 1, pageSize: 50 }));

          // setTab(CollectionTab.activity);
          break;
        }
        default: {
          console.error('not match tab', value);
        }
      }
    },
    [contractAddress, dispatch],
  );

  // const fromatTimers = () => {
  //   let timerBegin = 1;
  //   const timer = setInterval(() => {

  //     setTimers('')
  //   }, 1000);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }

  useInterval(() => {
    if (timerBegin !== 0) {
      if (timerBegin >= 60 * 60) {
        setTimers((parseInt((timerBegin / (60 * 60)).toString()) + ' h ago'));
      } else if (timerBegin >= 60) {
        setTimers((parseInt((timerBegin / 60).toString()) + ' min ago'));
      } else {
        setTimers(timerBegin + ' secs ago');
      }
      setTimerBegin(timerBegin + 1);
    }
  }, 1000);


  const onTabsChange = useCallback((_, value) => {
    setPage(1);
    push(CollectionDetalilRoutesConfig.nftList.generatePath(value, contractAddress));
    updateData(value);
    setTimerBegin(1);
  },
    [push, updateData, contractAddress],
  );


  useEffect(() => {
    updateData(tab);
  }, [updateData, tab]);
  // }, [updateData, tab, chainId]);

  useEffect(() => {
    if (filterVal) {
      setPage(1);
      // dispatch(getCollectionNFTList({ address: contractAddress, off_index: 1, off_size: 50, order_by: filterVal }));
      setTimerBegin(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVal]);

  const tabs = useMemo(
    () => [
      {
        value: CollectionTab.collections,
        label: t('collectionDetail.items'),
      }
      , {
        value: CollectionTab.activity,
        label: t('collectionDetail.activities'),
      },
    ],
    [],
  );

  return (
    <>
      <Box>
        <Header
          img={collectionItem?.bannerImg || ''}
        />
        <Container>
          <Avatar
            className={classes.avatar}
            src={collectionItem?.logoImg || ''}
            isVerified={false}
          />
          <Box display={'flex'} justifyContent='space-between' alignItems={'start'}>
            <Box className={classes.namse}>
              <Typography className={classes.collectionItemName} variant='h2'>
                {collectionItem?.name || ''}
                <VerifyComp />

                {/*<Tooltip title={address} arrow placement='top'>*/}
                {/*  <span className={classes.address}>*/}
                {/*    By/!* {truncateWalletAddr(address)} *!/*/}
                {/*    <span>{truncateWalletAddr(contractAddress)}</span>*/}
                {/*  </span>*/}
                {/*</Tooltip>*/}
              </Typography>
              <Typography className={classes.detail}>
                Created&nbsp;
                <span>
                  {fromatTimers(parseInt(collectionItem?.created + '000'))}
                </span> · Creator fee
                <span>{' ' + (parseInt(collectionItem?.marketFee) + parseInt(collectionItem?.ipFee) + parseInt(collectionItem?.projectFee)) / 100}%</span> ·
                Chain <span>Ethereum</span></Typography>
              {isMobile && <>
                <Box mt={1} mb={1} display={'flex'} alignItems={'center'}>
                  {/*<CopyToClip address={collectionItem?.contract || ''} />*/}
                  <Box>
                    <Social
                      block={getBlockChainExplorerAddress(chainId) + 'address/' + collectionItem?.contract || ''}
                      twitter={collectionItem?.twitter || ''}
                      instagram={collectionItem?.instagram || ''}
                      facebook={collectionItem?.facebook || ''}
                      discord={collectionItem?.discord || ''}
                      telegram={collectionItem?.telegram || ''}
                      website={collectionItem?.websiteURL || ''}
                    />
                  </Box>
                </Box>
                {/*<Box mt={1} ml={-2}>*/}
                {/*<Social*/}
                {/*  twitter={collectionItem?.ipTwitter || ''}*/}
                {/*  instagram={collectionItem?.ipInstagram || ''}*/}
                {/*  facebook={collectionItem?.ipFacebook || ''}*/}
                {/*  discord={collectionItem?.ipDiscord || ''}*/}
                {/*  telegram={collectionItem?.ipTelegram || ''}*/}
                {/*/>*/}
                {/*</Box>*/}
              </>
              }
              <ShowMoreComp description={collectionItem?.description || '0'} />
              {/*<Typography className={classes.describe}>{collectionItem?.description || '0'}</Typography>*/}
              <Box display={'flex'} mt={4} alignItems={'center'} flexWrap={isMobile ? 'wrap' : ''}>
                <Box className={classNames(classes.msgBox, classes.diffBox)}>
                  <Typography className={classes.msgtitles}>Items</Typography>
                  <Typography className={classes.msgVal}>{collectionItem?.items || '0'}</Typography>
                </Box>
                <Typography className={classes.lines} />
                <Box className={classes.msgBox}>
                  <Typography className={classes.msgtitles}>Owners</Typography>
                  <Typography className={classes.msgVal}>{collectionItem?.owners || '0'}</Typography>
                </Box>
                <Typography className={classes.lines} />
                <Box className={classNames(classes.msgBox, isSmDown && classes.diffBox)}>
                  <Typography className={classes.msgtitles}>Vol.</Typography>
                  <Typography
                    className={classes.msgVal}>{collectionItem && collectionItem.saleInfo && (collectionItem.saleInfo.vol.toFixed(2) || '0')}
                    <img src={CurrentTheme === Themes.dark ? DarkMsgIcon : DarkMsgIcon} alt='' />
                  </Typography>
                </Box>
                <Typography className={classes.lines} />
                <Box className={classNames(classes.msgBox)}>
                  <Typography className={classes.msgtitles}>Floor</Typography>
                  <Typography
                    className={classes.msgVal}>
                    {
                      collectionItem?.saleInfo?.floorETH < 0.00001 ? '<0.0001' : collectionItem?.saleInfo?.floorETH.toFixed(4)
                    }
                    <img src={CurrentTheme === Themes.dark ? DarkMsgIcon : DarkMsgIcon} alt='' />
                  </Typography>
                </Box>
                <Typography className={classes.lines} />
                <Box className={classNames(classes.msgBox, isSmDown && classes.diffBox)}>
                  <Typography className={classes.msgtitles}>Best offer</Typography>
                  <Typography
                    className={classes.msgVal}>
                    {collectionItem?.saleInfo?.bestOfferETH.toFixed(4) < 0.00001 ? '<0.0001' : collectionItem?.saleInfo?.bestOfferETH.toFixed(4)}
                    <img src={CurrentTheme === Themes.dark ? DarkMsgIcon : DarkMsgIcon} alt='' />
                  </Typography>
                </Box>
                <Typography className={classes.lines} />
              </Box>
            </Box>
            {!isMobile && <Box mt={4.5} display={'flex'} alignItems={'start'}>
              {/*<CopyToClip address={collectionItem?.contract || ''} />*/}
              <Box>
                <Box>
                  <Social
                    block={getBlockChainExplorerAddress(chainId) + 'address/' + collectionItem?.contract || ''}
                    twitter={collectionItem?.twitter || ''}
                    instagram={collectionItem?.instagram || ''}
                    facebook={collectionItem?.facebook || ''}
                    discord={collectionItem?.discord || ''}
                    telegram={collectionItem?.telegram || ''}
                    website={collectionItem?.websiteURL || ''}
                  />
                </Box>
                {/*<Box mt={1}>*/}
                {/*  <Social*/}
                {/*    twitter={collectionItem?.ipTwitter || ''}*/}
                {/*    instagram={collectionItem?.ipInstagram || ''}*/}
                {/*    facebook={collectionItem?.ipFacebook || ''}*/}
                {/*    discord={collectionItem?.ipDiscord || ''}*/}
                {/*    telegram={collectionItem?.ipTelegram || ''}*/}
                {/*  />*/}
                {/*</Box>*/}
              </Box>

            </Box>
            }
          </Box>
        </Container>

      </Box>
      <Section className={classes.root}>
        <Container>
          <Box className={classes.tabContent}>
            <Tabs
              className={classes.tabs}
              onChange={onTabsChange as any}
              value={tab}
            >
              {tabs.map(tabProps => (
                <Tab key={uid(tabProps)} {...tabProps} />
              ))}
            </Tabs>
          </Box>

          <TabPanel value={tab} index={CollectionTab.collections}>
            <Collected filterVal={filterVal} setFilterVal={setFilterVal} setPage={setPage} page={page} />
          </TabPanel>

          <TabPanel value={tab} index={CollectionTab.activity}>
            <Activities setPage={setPage} page={page} timers={timers} />
          </TabPanel>
        </Container>
      </Section>
    </>

  );
};
