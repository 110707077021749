import { createAction as createSmartAction } from 'redux-smart-actions';
import { RequestAction } from '@redux-requests/core';


export interface IProfileData {
    address: string,
    banner: string,
    bio: string,
    email: string,
    facebook: string,
    image: string,
    instagram: string,
    registerTime: string,
    twitter: string,
    username: string
}

export const getExploreItems = createSmartAction<
    any, [
        {
            list_type: string;
            order_by: string;
            properties?: string;
            currency_addresses?: string;
            off_index: number;
            off_size: number;
            addressList?: string;
        }
    ]
>('getExploreItems', ({
    list_type,
    order_by,
    properties,
    currency_addresses,
    off_index,
    off_size,
    addressList
}) => ({
    request: {
        url: currency_addresses && addressList ? `${process.env.REACT_APP_API_BASE}/client/market/v1/explore/items?off_index=${off_index}&off_size=${off_size}&list_type=${list_type || 'All'}&order_by=${order_by}&currency_addresses=${currency_addresses}&collection_addresses=${addressList}` :
            currency_addresses ? `${process.env.REACT_APP_API_BASE}/client/market/v1/explore/items?off_index=${off_index}&off_size=${off_size}&list_type=${list_type || 'All'}&order_by=${order_by}&currency_addresses=${currency_addresses}` :
                addressList ? `${process.env.REACT_APP_API_BASE}/client/market/v1/explore/items?off_index=${off_index}&off_size=${off_size}&list_type=${list_type || 'All'}&order_by=${order_by}&collection_addresses=${addressList}` :
                    `${process.env.REACT_APP_API_BASE}/client/market/v1/explore/items?off_index=${off_index}&off_size=${off_size}&list_type=${list_type || 'All'}&order_by=${order_by}`,
        method: 'get',
        params: {},
    },
    meta: {
        auth: false,
        driver: 'axios',
        asMutation: false,
        getData: (data: any) => {
            console.log(data)
            if (data.code === 200) {
                return data.data;
            } else {
                console.log(data)
                return {};
            }
        },
        onSuccess: (
            response: any,
            action: RequestAction,
        ) => {
            return response;
        },
    },
}));


export const getExploreActivities = createSmartAction<
    any, [{
        index: string;
        size: string;
    }]
>('getExploreActivities', ({ index, size }) => ({
    request: {
        url: `${process.env.REACT_APP_API_BASE}/client/market/v1/explore/activities`,
        method: 'get',
        params: {
            index: index,
            size: size
        },
    },
    meta: {
        auth: false,
        driver: 'axios',
        asMutation: false,
        getData: (data: any) => {
            if (data.code === 200) {
                return data.data;
            } else {
                console.log(data)
                return {};
            }
        },
        onSuccess: (
            response: any,
            action: RequestAction,
        ) => {
            return response;
        },
    },
}));
