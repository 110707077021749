import { makeStyles, Theme } from '@material-ui/core';
import { CurrentTheme, Themes } from 'modules/themes/types';

export const usePropertiesStyle = makeStyles<Theme>((theme: any) => ({
  root: {
    width: '100%',
    marginTop: '12px',

  },
  accordion: {
    margin: '0!important',
    border: CurrentTheme === Themes.dark ? '1px solid #F8F050' : '1px solid #FCFFE7',
    overflow: 'hidden',
    borderRadius: '0!important',
  },
  itemSummary: {
    background: '#000',
    height: '58px',
    lineHeight: '58px',
  },
  itemExpanded: {
    minHeight: '58px!important',
    borderBottom: CurrentTheme === Themes.dark ? '1px solid #F8F050' : '1px solid #FCFFE7',
    '& p': {
      lineHeight: '58px',
    },
    // [theme.breakpoints.down('md')]: {
    //   borderBottom: `none`,
    // },
  },
  expandIcon: {
    border: 'none'
  },
  accordionDetail: {
    padding: '24px 16px',
    backgroundColor: '#111'
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 400,
    textTransform: 'capitalize',
    '& img': {
      marginRight: '16px'
    }
  },
  propertiesBox: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridRowGap: '12px',
    gridColumnGap: '12px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      // gridTemplateColumns: '2fr 1fr',
    },
  },
  propertiesItem: {
    padding: '12px 8px',
    background: '#000',
  },
  propertiesTitle: {
    color: '#fff',
    fontSize: '13px',
    letterSpacing: '0.22px',
    lineHeight: '18px'
  },
  propertiesVal: {
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: '15px',
    lineHeight: '22px'
  }
}));
