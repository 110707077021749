import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { useDrawerRouterStyle } from './useDrawerRouterStyle';
import LightArrowImg from 'assets/img/header/light-arrow.png';
import DarkArrowImg from 'assets/img/header/dark-arrow.png';
import { useHistory } from 'react-router-dom';
import { ProfileRoutesConfig } from 'modules/Profile/ProfileRoutes';
import { CurrentTheme, Themes } from 'modules/themes/types';
import DarkUserIcon from 'assets/img/header/dark-info.png';
import DarkFavorite from 'assets/img/header/dark-favorite.png';
import DarkSetting from 'assets/img/header/dark-setting.png';
import LightUserIcon from 'assets/img/header/light-info.png';
import LightFavorite from 'assets/img/header/light-favorite.png';
import LightSetting from 'assets/img/header/light-setting.png';
import { t } from 'modules/i18n/utils/intl';
interface DrawerRouterCompInterface {
  toggleDrawer?: (e: any) => void;
}
export const DrawerRouterComp = (
  {toggleDrawer}:DrawerRouterCompInterface
) => {
  const list = useMemo(
    () => [
      {
        path: ProfileRoutesConfig.UserProfile.path,
        title: t('header.profile'),
        darkImg: DarkUserIcon,
        lightImg: LightUserIcon,
      },
      {
        path: ProfileRoutesConfig.UserProfile.generatePath('favorited'),
        title: t('header.favorite'),
        darkImg: DarkFavorite,
        lightImg: LightFavorite,
      },
      {
        path: ProfileRoutesConfig.UserEdit.path,
        title: t('header.setting'),
        darkImg: DarkSetting,
        lightImg: LightSetting,
      },
    ],
    [],
  );
  const history = useHistory();

  const classes = useDrawerRouterStyle();
  return (
    <Box className={classes.root}>
      {
        list.map((d: any, index: number) => (

          <Box key={index} className={classes.flex} onClick={() => {
            history.push(d.path);
            toggleDrawer && toggleDrawer('');
          }}>
            <Box className={classes.left}>
              <Box className={classes.ingWrap}>
                <img className={classes.profileImg} src={CurrentTheme === Themes.dark ? d.darkImg : d.lightImg}
                     alt='' />
              </Box>
              <Box className={classes.title}>{d.title}</Box>
            </Box>
            <img className={classes.arrowImg} src={CurrentTheme === Themes.dark ? DarkArrowImg : LightArrowImg} alt='' />
          </Box>
        ))
      }
    </Box>
  );
};
