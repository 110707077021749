import { makeStyles, Theme } from '@material-ui/core';
import { CurrentTheme, Themes } from 'modules/themes/types';

export const useCollectedStyles = makeStyles<Theme>(theme => ({
  root: {

  },
  result: {
    fontSize: '14px',
    color: '#fff',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'right',
  },
  seconds: {
    fontSize: '10px',
    color: '#697077',
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'right',
  },
  circle: {
    width: '22px',
    height: '22px',
    borderRadius: '50%',
    backgroundColor: CurrentTheme === Themes.dark ? '#58BD7D' : '#EA3375',
    marginLeft: '6px'
  },
  borders: {
    border: CurrentTheme === Themes.dark ? '2px solid #F8F050' : '2px solid #FCFFE7',
    borderRadius: '0',
    display: 'flex',
    alignItems: 'center',
    padding: "10px",
    cursor: 'pointer',
    marginRight: "12px",
    '& img': {
      display: 'block',
      width: '20px'
    }
  },
  layoutMore: {
    marginLeft: '12px'
  }

}));
