import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { Route } from 'react-router-dom';
import { useQueryParams } from 'modules/common/hooks/useQueryParams';
import { generatePath } from 'react-router-dom';
import { CollectionTab } from './screens';

const PATH_COLLECTION_LIST = '/collection/detail';
export const PATH_OTHER_PROFILE_TABS = `${PATH_COLLECTION_LIST}?tab=:tab&address=:address`;
export const defaultOtherProfileTab = CollectionTab.collections;

export const CollectionDetalilRoutesConfig: { [key: string]: RouteConfiguration } = {
  nftList: {
    path: PATH_COLLECTION_LIST,
    generatePath: (tab?: string, address?: string) =>
      generatePath(PATH_OTHER_PROFILE_TABS, {
        tab: tab ?? defaultOtherProfileTab,
        address: address ?? '',
      }),
    useParams: () => {
      const query = useQueryParams();
      const address = query.get('address');
      return {
        address: address,
        tab: query.get('tab') ?? defaultOtherProfileTab
      };
    },
  },
};

const LoadableCollectionListContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens').then(module => module.CollectionNftList),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export function CollectionDetailRoutes() {
  return (
    <Route
      path={CollectionDetalilRoutesConfig.nftList.path}
      exact={true}
      component={LoadableCollectionListContainer}
    />
  );
}
