import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { Route } from 'react-router-dom';

const PATH_INFEX = '/market';

export const MarketRoutesConfig: { [key: string]: RouteConfiguration } = {
  Market: {
    path: PATH_INFEX,
    generatePath: () => PATH_INFEX,
  },
};

const LoadableDFdunkContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens').then(module => module.Market),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export function MarketRoutes() {
  return (
    <Route
      path={MarketRoutesConfig.Market.path}
      exact={true}
      component={LoadableDFdunkContainer}
    />
  );
}
