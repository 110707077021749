import { createAction as createSmartAction } from 'redux-smart-actions';
import { RequestAction } from '@redux-requests/core';
import { getJWTToken } from '../../common/utils/localStorage';
import axios from 'axios';


export interface IProfileData {
  address: string,
  banner: string,
  bio: string,
  email: string,
  facebook: string,
  image: string,
  instagram: string,
  registerTime: string,
  twitter: string,
  username: string
}
// https://test-backend.hotluuu.io/client/market/v1/collection/0x93b38db5c4652a23770f2979b0be03035b8317e2/properties
export const getCollectionProperties = async (request: any) => {
  const token = getJWTToken() ?? '';
  let res;
  await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_BASE}/client/market/v1/collection/${request.address}/properties`,
    params: request,
    headers: {
      token: token ?? '',
    },
  }).then((response) => {
    res = response.data;
  }, error => {
    res = error.response.data;
  });
  return res;
};
export const getCollectionDetail = createSmartAction<any, [{
  address: string;
},]>('getCollectionDetail', (address) => ({
  request: {
    url: `${process.env.REACT_APP_API_BASE}/client/market/v1/collection/${address}`,
    method: 'get',
  },
  meta: {
    auth: false,
    driver: 'axios',
    asMutation: false,
    getData: (data: any) => {
      if (data.code === 200) {
        return data.data;
      } else {
        console.log(data);
        return {};
      }
    },
    onSuccess: (
      response: any,
      action: RequestAction,
    ) => {
      return response;
    },
  },
}));


export const getCollectionActivies = createSmartAction<any, [{
  address: string;
  page: number;
  pageSize: number;
},]>('getCollectionActivies', ({ address, page, pageSize }) => ({
  request: {
    url: `${process.env.REACT_APP_API_BASE}/client/market/v1/collection/activities/${address}`,
    method: 'get',
    params: {
      page: page,
      page_size: pageSize,
    },
  },
  meta: {
    auth: false,
    driver: 'axios',
    asMutation: false,
    getData: (data: any) => {
      console.log(data.data);
      if (data.code === 200) {
        return data.data || [];
      } else {
        console.log(data);
        return [];
      }
    },
    onSuccess: (
      response: any,
      action: RequestAction,
    ) => {
      return response;
    },
  },
}));

export const getCollectionNFTList = createSmartAction<any, [
  {
    list_type?: string;
    order_by?: string;
    properties?: string;
    currency_addresses?: string;
    off_index?: number;
    off_size?: number;
    addressList?: string;
    address?: string;
  }
]>('getExploreItems', (
  {
    list_type,
    order_by,
    properties,
    currency_addresses,
    off_index,
    off_size,
    addressList,
    address,

  }) => ({
    request: {
      url: `${process.env.REACT_APP_API_BASE}/client/market/v1/nfts/${address}?off_index=${off_index}&off_size=${off_size}${list_type && '&list_type=' + list_type}&order_by=${order_by}${currency_addresses ? '&currency_addresses=' + currency_addresses : ''}${addressList ? '&collection_addresses=' + addressList : ''}${properties}`,
      method: 'get',
      params: {},
    },
    meta: {
      auth: false,
      driver: 'axios',
      asMutation: false,
      getData: (data: any) => {

        if (data.code === 200) {
          return data.data;
        } else {

          return {};
        }
      },
      onSuccess: (
        response: any,
        action: RequestAction,
      ) => {
        return response;
      },
    },
  }));
// export const getCollectionNFTList = createSmartAction<
//     any, [{
//         address: string;
//         page: number;
//         pageSize: number;
//         price_sort?: string;
//     },]
// >('getCollectionNFTList', ({ address, page, pageSize, price_sort }) => ({
//     request: {
//         url: `${process.env.REACT_APP_API_BASE}/client/market/v1/nfts/${address}`,
//         method: 'get',
//         params: {
//             page: page,
//             page_size: pageSize,
//             price_sort: price_sort === '1'
//         }
//     },
//     meta: {
//         auth: false,
//         driver: 'axios',
//         asMutation: false,
//         getData: (data: any) => {
//             if (data.code === 200) {
//                 return data.data;
//             } else {
//                 console.log(data)
//                 return {};
//             }
//         },
//         onSuccess: (
//             response: any,
//             action: RequestAction,
//         ) => {
//             return response;
//         },
//     },
// }));
