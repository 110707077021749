import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useHeaderBalanceStyle } from './useHeaderBalanceStyle';
import { useWeb3Balance } from '../../../../../account/hooks/useWeb3React';
import ETHTokenImg from '../../image/eth.png';
import WETHTokenImg from '../../image/weth.png';
import USDTTokenImg from '../../image/USDT.png';
import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import { t } from 'modules/i18n/utils/intl';
interface HeaderBalanceInterface {
  tokensBalance: any
}

export interface currencyRateInterface {
  currency_address: string;
  currency_symbol: string;
  rate_usd: number;
}

export const HeaderBalance = (
  {
    tokensBalance,
  }: HeaderBalanceInterface,
) => {
  const classes = useHeaderBalanceStyle();

  const currencyRate: currencyRateInterface[] = JSON.parse(localStorage.getItem('currencyRate') || '');
  const { balance } = useWeb3Balance();
  const IMAGE: any = {
    ETH: ETHTokenImg,
    WETH: WETHTokenImg,
    USDT: USDTTokenImg,
    BUSD: WETHTokenImg,
  };
  const tokenFullName: any = {
    ETH: 'Ethereum',
    WETH: 'Wrapped Ethereum',
    USDT: 'Tether USD',
    BUSD: 'BUSD',
  };
  const [assetsToken, setAssetsToken] = useState(0);
  // const [eth_rate_usd, setEthRateUsd] = useState(0);
  // console.log(eth_rate_usd);
  useEffect(() => {
    async function loadData() {
      let total = 0;
      currencyRate && currencyRate.forEach((d: currencyRateInterface) => {

        if (tokensBalance && d.currency_symbol === 'ETH') {
          total += balance.toNumber() * d.rate_usd;
          // setEthRateUsd(d.rate_usd);
        }
        if (tokensBalance && d.currency_symbol === 'BUSD') {
          total += tokensBalance.BUSD.toNumber() * d.rate_usd;
        }
        if (tokensBalance && d.currency_symbol === 'WETH') {
          total += tokensBalance.WETH.toNumber() * d.rate_usd;
        }
        if (tokensBalance && d.currency_symbol === 'USDT') {
          total += tokensBalance.USDT.toNumber() * d.rate_usd;
        }
      });
      setAssetsToken(total);
    }

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokensBalance]);
  return (
    <Box className={classes.root}>
      <Box className={classes.wrap}>
        <Box className={classes.title}>
          <Box>{t('header.totalBalance')}</Box>
        </Box>
        <Box className={classes.body}>
          <Box className={classNames(classes.row, 'center')}>
            {/*<img className={classes.usdtBaseImage} src={USDTTokenImg} alt='' />*/}
            <Box className={classNames(classes.usdtBaseImage, classes.base)}>
              $
            </Box>
            <Box className={classNames(classes.flex, 'pd_bt')}>
              <Box className={classes.baseName}>
                USD
              </Box>
              <Box className={classes.baseName}>
                $&nbsp;{new BigNumber(assetsToken).toFormat(4, 1)}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={classes.wrap}>
        <Box className={classes.title}>
          {t('header.wallet')}
        </Box>
        <Box className={classes.body}>


          {
            currencyRate && currencyRate.map((item: any) => {
              return (
                <Box className={classes.row} key={item.currency_symbol}>
                  <img className={item.currency_symbol === 'USDT' ? classes.usdtBaseImage : classes.baseImg}
                    src={item.currency_symbol === 'ETH' ? ETHTokenImg : IMAGE && IMAGE[item.currency_symbol]}
                    alt='' />
                  <Box className={classes.flex}>
                    <Box className={classes.baseName}>
                      {item.currency_symbol === 'ETH' ? 'ETH' : item.currency_symbol}
                    </Box>
                    <Box
                      className={classes.baseName}>
                      {item.currency_symbol === 'ETH' ? balance.toFixed(4, 1) : tokensBalance && tokensBalance[item.currency_symbol] && tokensBalance[item.currency_symbol].toFixed(4, 1)}
                    </Box>
                  </Box>
                  <Box className={classes.flex}>
                    <Box className={classes.fullName}>
                      {tokenFullName && tokenFullName[item.currency_symbol]}
                    </Box>
                    <Box
                      className={classes.fullName}>
                      $&nbsp;{item.currency_symbol === 'ETH' ? new BigNumber(balance.toNumber() * item.rate_usd).toFixed(4, 1) : new BigNumber(tokensBalance && tokensBalance[item.currency_symbol] && tokensBalance[item.currency_symbol].toNumber() * item.rate_usd).toFixed(4, 1)}</Box>
                  </Box>
                </Box>
              );
            })
          }
        </Box>
      </Box>
    </Box>
  );
};
