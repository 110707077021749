import { getJWTToken } from '../../common/utils/localStorage';
import axios from 'axios';


export const getCollectionsList = async (request: any) => {
  const token = getJWTToken() ?? '';
  let res;
  await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_BASE}/client/market/v1/collections`,
    params: request,
    headers: {
      token: token ?? '',
    },
  }).then((response) => {
    res = response.data;
  }, error => {
    res = error.response.data;
  });
  return res;
};
export const getUserCollectionsList = async (request: any) => {
  const token = getJWTToken() ?? '';
  let res;
  await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_BASE}/client/market/v1/assets/collection/${request.address}?address=${request.address}`,
    // params: request,
    headers: {
      token: token ?? '',
    },
  }).then((response) => {
    res = response.data;
  }, error => {
    res = error.response.data;
  });
  return res;
};
