import { SvgIconProps } from '@material-ui/core';
import { CurrentTheme, Themes } from '../../themes/types';

export const ExpandMoreIcon = (props: SvgIconProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 7.5L19.5 15L18.45 16.05L12 9.6L5.55 16.05L4.5 15L12 7.5Z"
                  fill={CurrentTheme === Themes.dark ? '#2EF2F1' : 'rgba(255, 255, 255, 0.8)'}
            />
        </svg>

    );
};
