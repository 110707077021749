import { makeStyles, Theme, fade } from '@material-ui/core';

export const useHeaderBalanceStyle = makeStyles<Theme>((theme: Theme) => ({
  root: {
    width: '100%',
    paddingTop: '40px',
  },
  wrap: {
    width: '100%',
    border: `1px solid ${theme.palette.grey[50]}`,
    overflow: 'hidden',
    marginBottom: '40px',
  },
  title: {
    height: '45px',
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
    fontFamily: 'Pretendard',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '16px',
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 17px',
  },
  addFounds: {
    fontFamily: 'Pretendard',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#2865EA',
    cursor: 'pointer',
    position: 'relative',
  },
  addFoundsImg: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '-20px',
    width: '15.48px',
    height: '14.51px',

  },
  body: {
    padding: '0 25px',
  },
  row: {
    height: '40px',
    paddingLeft: '30px',
    position: 'relative',
    padding: '0 25px',
    margin: '20px 0 15px 0',
    '&.center': {
      display: 'flex',
      alignItems: 'center',
    },

  },
  baseImg: {
    position: 'absolute',
    top: '50%',
    left: '0',
    width: '15px',
    height: '24px',
    transform: 'translateY(-50%)',
  },
  usdtBaseImage: {
    position: 'absolute',
    top: '50%',
    left: '-2px',
    width: '20px',
    height: '20px',
    transform: 'translateY(-50%)',
  },
  base: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    backgroundColor: '#2CFB5B',
    borderRadius:'50px',
    color:fade(theme.palette.common.white,.9),
    fontWeight:500
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingBottom: '6px',
    '&.pd_bt': {
      paddingBottom: '0',
    },
  },
  baseName: {
    fontFamily: 'Pretendard',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
    color: theme.palette.common.white,
  },
  fullName: {
    fontFamily: 'Pretendard',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: fade(theme.palette.common.white, .9),
  },
}));

