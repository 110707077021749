import { makeStyles, Theme } from '@material-ui/core';

export const useSocialStyles = makeStyles<Theme>(theme => ({
  list: {
    display: 'flex',
    margin: 0,
    listStyle: 'none',
    borderRadius: '0',
    padding:0,
    '& svg': {
      width: '12px',
      height: '12px',
    }
  },

  item: {
    marginLeft: '16px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      '&:first-child':{
        marginLeft: '0',
      }
    },

  },

  icon: {
    height: '24px',
    width:'24px',
    maxWidth:'24px',
    maxHeight:'24px',
    border: `1px solid ${theme.palette.grey[50]}`,
    padding:0,
    borderRadius: 0,
    '&:hover': {
      opacity: '0.7',
      border: `1px solid ${theme.palette.grey[50]}`,
    },
  },
}));
