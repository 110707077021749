import { Box, Typography } from '@material-ui/core';
import { useActivitiesStyles } from './useActivitiesStyles';
// import ETH from 'modules/Profile/assets/eth.svg';
import DarkShare from 'modules/Profile/assets/share.svg';
import LightShare from 'modules/Profile/assets/shareRed.svg';
import CollectionIcon from 'modules/Profile/assets/collectionIcon.png';
import DarkVerify from 'modules/Profile/assets/verify.svg';
import LightVerify from 'modules/Profile/assets/light-verify.svg';
import { getCollectionActivies } from 'modules/CollectionDetail/actions/collectionAction';
import { BlockchainNetworkId, getBlockChainExplorerAddress } from 'modules/common/conts';
// import { Button } from 'modules/uiKit/Button';
import { CollectionDetalilRoutesConfig } from 'modules/CollectionDetail/Routes';
import { useDispatchRequest, useQuery } from '@redux-requests/react';
import React, { useEffect, useState } from 'react';
// import downImg from '../../assets/down.png';
import BigNumber from 'bignumber.js';
import { useIsSMDown, useIsMDDown } from 'modules/themes/useTheme';
import { useHistory } from 'react-router-dom';
import { ViewAll } from 'modules/Market/components/ViewAll';
import { CurrentTheme, Themes } from '../../../themes/types';
import DarkSale from 'modules/Profile/assets/sale.svg';
import LightSale from 'modules/Profile/assets/light-sale.svg';
import { TokenImgComp } from '../../../common/components/TokenImg';
import { t } from 'modules/i18n/utils/intl';
export const Activities: React.FC<{
  reload?: () => void;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  timers: string;
}> = function ({ reload, setPage, page, timers }) {
  const classes = useActivitiesStyles();
  const dispatch = useDispatchRequest();
  const history = useHistory();
  const [dataList, setDataList] = useState([]);
  const { address: contractAddress } = CollectionDetalilRoutesConfig.nftList.useParams();
  const loadingMore = () => {
    dispatch(getCollectionActivies({ address: contractAddress, page: page + 1, pageSize: 50 }));
    setPage(page + 1);
  }
  const { data: activitiesList } = useQuery<any>({
    type: getCollectionActivies.toString(),
  });

  useEffect(() => {
    if (activitiesList) {
      page === 1 ? setDataList(activitiesList) : setDataList(dataList.concat(activitiesList));
    }
    // eslint-disable-next-line
  }, [activitiesList, setDataList]);
  const isMobile = useIsSMDown();
  const mdMobile = useIsMDDown();

  const baseEnv = process.env.REACT_APP_BASE_ENV;
  return (
    <Box mt={4}>
      <Box className={classes.root}>
        <Box className={classes.activitiesList}>
          {dataList && dataList.map((item: any) => {
            return isMobile ? <Box display={'flex'} mb={4} key={item.id} flexDirection={'column'}>
              <Box mb={1}>
                <Typography className={classes.types}>
                  <img src={CurrentTheme === Themes.dark ? DarkSale : LightSale} alt="" />
                  {t('collectionDetail.transferred')}
                </Typography>
              </Box>
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Box display={'flex'} alignItems={'center'}>
                  <Box className={classes.collectionContent}
                    onClick={() => {
                      history.push(`/nft-detail/${contractAddress}/${item && item.tokenId}/type`);
                    }}
                  >
                    <img src={item.image || CollectionIcon} alt="" />
                    <Box>
                      <Typography className={classes.collectionDetail} >{item.name}
                        <img src={CurrentTheme === Themes.dark ? DarkVerify : LightVerify} alt="" />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.values} >
                  <a
                    href={getBlockChainExplorerAddress(baseEnv === 'DEV' ? BlockchainNetworkId.goerli : BlockchainNetworkId.mainnet) + 'tx/' + item.transactionHash}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.tokenId}
                  ><Typography className={classes.ethValues}>
                      <TokenImgComp size={'mini'} token={item.priceToken} />
                      {item.priceAmount}
                      <img src={CurrentTheme === Themes.dark ? DarkShare : LightShare} alt="" /></Typography>
                  </a>
                  <Typography className={classes.usdtValues}>${new BigNumber(item.usPrice).toFormat(4, 1)}</Typography>
                </Box>
              </Box>
            </Box>
              :
              <Box display={'flex'} mb={4} key={item.id} justifyContent={'space-between'}>
                <Box display={'flex'} alignItems={'center'}>
                  <Typography className={classes.types}>
                    <img src={CurrentTheme === Themes.dark ? DarkSale : LightSale} alt="" />
                    {t('collectionDetail.transferred')}
                  </Typography>
                  <Box className={classes.collectionContent}
                    onClick={() => {
                      history.push(`/nft-detail/${contractAddress}/${item && item.tokenId}/type`);
                    }}
                  >
                    <img src={item.image || CollectionIcon} alt="" />
                    <Box>
                      {/* <Typography className={classes.tokenId}>#{item.tokenId}</Typography> */}
                      <Typography className={classes.collectionDetail} >{item.name}
                        <img src={CurrentTheme === Themes.dark ? DarkVerify : LightVerify} alt="" />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {!mdMobile &&
                  <Box className={classes.addressList}>
                    <Box>
                      <Typography className={classes.fromAddress}>{item.from.slice(0, 6) + '...' + item.from.slice(item.from.length - 4)}</Typography>
                      <Typography className={classes.address}>From</Typography>
                    </Box>
                    <Box>
                      <Typography className={classes.fromAddress}>{item.to.slice(0, 6) + '...' + item.to.slice(item.to.length - 4)}</Typography>
                      <Typography className={classes.address}>To</Typography>
                    </Box>
                  </Box>
                }
                <Box className={classes.values} >
                  <a
                    href={getBlockChainExplorerAddress(baseEnv === 'DEV' ? BlockchainNetworkId.goerli : BlockchainNetworkId.mainnet) + 'tx/' + item.transactionHash}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.tokenId}
                  ><Typography className={classes.ethValues}>
                      <TokenImgComp size={'mini'} token={item.priceToken} />
                      {item.priceAmount}
                      <img src={CurrentTheme === Themes.dark ? DarkShare : LightShare} alt="" /></Typography>
                  </a>
                  <Typography className={classes.usdtValues}>${new BigNumber(item.usPrice).toFormat(4, 1)}</Typography>
                </Box>
              </Box>
          })}
        </Box>
        {activitiesList && activitiesList.length > 49 && <Box display={'flex'} justifyContent={'center'} mt={8}>
          <ViewAll text={t('public.loadMore')} emitClick={loadingMore} />
        </Box>
        }
      </Box>

    </Box>
  );
};
