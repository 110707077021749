import { Button } from '@material-ui/core';
import { useViewAllStyle } from './useViewAllStyle';
// import LoadMore from 'modules/Market/assets/loadmore.png';

interface ViewAllProps {
  text: string;
  emitClick: () => void;
}

export const ViewAll = (
  {
    text,
    emitClick,
  }: ViewAllProps,
) => {
  const classes = useViewAllStyle();
  return (
    <Button className={classes.root} onClick={emitClick}>
      {/*<img className={classes.loadMore} src={LoadMore} alt='' />*/}
      <span className={classes.text}>{text}</span>
    </Button>
  );
};
