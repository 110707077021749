import { makeStyles, Theme, fade } from '@material-ui/core';
export const useFooterIconStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent:'flex-end',
    '& a': {
      marginLeft: '26px',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '20px',
      display: 'inline-block',
      color: fade(theme.palette.common.white, .8),
    },
    [theme.breakpoints.up('xs')]: {
      justifyContent:'center',
      '& a': {
        marginLeft: '15px',
      }
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent:'center',
      '& a': {
        marginLeft: '15px',
      },
    },
    [theme.breakpoints.up('md')]: {
      justifyContent:'flex-end',
      '& a': {
        marginLeft: '20px',
      },
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent:'flex-end',
      '& a': {
        marginLeft: '20px',
      },
    },
    [theme.breakpoints.up('xl')]: {
      justifyContent:'flex-end',
      '& a': {
        marginLeft: '26px',
      },
    },
    [theme.breakpoints.up('HD')]: {
      justifyContent:'flex-end',
      '& a': {
        marginLeft: '26px',
      },
    },
    [theme.breakpoints.up('WXGAPlus')]: {
      justifyContent:'flex-end',
      '& a': {
        marginLeft: '26px',
      },
    },
  },
  contact:{
    marginLeft: '26px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
    display: 'inline-block',
    cursor: 'pointer',
    color: fade(theme.palette.common.white, .8),
    [theme.breakpoints.up('xs')]: {
        marginLeft: '15px',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: '15px',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '20px',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '20px',
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: '26px',
    },
    [theme.breakpoints.up('HD')]: {
      marginLeft: '26px',
    },
    [theme.breakpoints.up('WXGAPlus')]: {
      marginLeft: '26px',
    },
  },
  rightContent: {
    paddingTop: '25px',
    [theme.breakpoints.up('md')]: {
      paddingTop: '0px',
    },
  },

  discord: {
    width: '20px',
    height: '15.24px',
  },
  twitter: {
    width: '20px',
    height: '20px',
  },
  instagram: {
    width: '18.33px',
    height: '18.33px',
  },
  facebook: {
    width: '18px',
    height: '18px',
  },
  telegram: {
    width: '20px',
    height: '20px',
  },
  rootH5:{
    display:'flex',
    flexDirection:'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  }

}));
