import React, { useState } from 'react';
import { styles } from './useHeaderNavationDrawerStyles';
import { Box, SwipeableDrawer, withStyles } from '@material-ui/core';
import { DraweCloseComp } from 'modules/common/components/HeaderDrawer/components/DrawerClose';
import { IDfdunkTabDate } from 'modules/common/actions/commonRequest';
import { Button } from 'modules/uiKit/Button';
import { NavLink } from 'react-router-dom';
import { useLocale } from 'modules/i18n/utils/useLocale';
import { CurrentTheme, Themes } from 'modules/themes/types';
import DrawerImg from 'assets/img/header/drawer.png';
import LightDrawerImg from 'assets/img/header/light-drawer.png';

type Anchor = 'right';
const lindSpaceStyles = {};
const HeaderDrawerNavComponents = ({ navData, classes = {} }: { classes: any, navData: IDfdunkTabDate[] }) => {


  const [isOpen, setIsOpen] = useState(false);
  const { locale } = useLocale();
  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setIsOpen(open);
  };
  return (
    <>
      <Box
        className={classes.root}
        onClick={toggleDrawer('right', true)}
        onKeyDown={toggleDrawer('right', false)}
      >
        <img src={CurrentTheme === Themes.dark ? DrawerImg : LightDrawerImg} alt='' className={classes.userIcon} />
      </Box>
      <SwipeableDrawer
        anchor={'right'}
        open={isOpen}
        onClose={toggleDrawer('right', false)}
        onOpen={toggleDrawer('right', true)}
      >
        <Box className={classes.drawerBody}>
          <DraweCloseComp toggleDrawer={toggleDrawer('right', false)} />
          <Box className={classes.linkWrap}>
            {
              navData?.map(d => {
                return (
                  d.status ? <Button
                    key={d.link}
                    component={NavLink}
                    variant='text'
                    style={lindSpaceStyles}
                    activeClassName={classes.activeLink}
                    className={classes.link}
                    to={d.link}
                  >
                    {locale === 'en-US' ? d.nameEn : locale === 'zh-CN' ? d.nameZh : locale === 'ja-JP' ? d.nameJp : ''}
                  </Button> : null
                );
              })
            }
          </Box>


        </Box>
      </SwipeableDrawer>
    </>
  );
};

const HeaderNavationDrawer = withStyles(styles)(HeaderDrawerNavComponents);
export { HeaderNavationDrawer };
