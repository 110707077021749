import { makeStyles, Theme } from '@material-ui/core';

export const useNoDataStyle = makeStyles<Theme>(theme => ({
  root: {
    width: '100%',
    height: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color:theme.palette.grey[50]
  },
}));
