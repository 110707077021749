import { makeStyles, Theme } from '@material-ui/core';
import { CurrentTheme, Themes } from '../../../themes/types';

export const useCollectionFilterDialogStyle = makeStyles<Theme>(theme => ({
  root: {
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: `${CurrentTheme === Themes.dark ? theme.palette.grey[50] : theme.palette.grey[200]}`,
      opacity: 1
    },
    '& .MuiSwitch-switchBase.MuiSwitch-switchBase': {
      padding: '0',
      color: '#fff',
      marginTop: '2px',
      marginLeft: '2px'
    },
    '& .MuiSwitch-switchBase.MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(22px)',
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 0
    },
    '& .MuiCheckbox-root': {
      color: '#fff'
    },
    '& .MuiSwitch-thumb': {
      color: '#fff',
      width: '20px',
      height: "20px"
    },
    '& .MuiSwitch-track': {
      height: '20px',
      width: '42px',
      marginTop: '2px',
      marginLeft: '2px'
    },
    '& .Mui-checked .MuiIconButton-label': {
      // color: '#2EF2F1'
    },
  },
  drawerBackdrop: {
    position: 'absolute',
  },
  drawerPaper: {
    position: 'absolute',
    width: '100%',
    background: theme.palette.common.black,
    color: theme.palette.common.white,
    maxWidth: 410,
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  titles: {
    fontSize: '20px',
    textAlign: 'center',
    marginBottom: '16px',
    borderBottom: '1px solid #E6E8EC',
    height: '84px',
    display: 'flex',
    alignItems: 'center'
  },
  close: {
    position: 'absolute',
    top: '20px',
    right: '12px',
    width: '48px',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& p': {
      width: '32px',
      height: '32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '16px',
    }
  },
  filterImg: {
    width: '28px',
    height: '28px',
    border: '2px solid #F8F050',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: '16px',
      display: 'block'
    }
  },
  switchRoot: {
    width: '50px',
    height: '28px',
    border: '2px solid #FFFFFF',
    borderRadius: '14px',
    boxSizing: 'border-box',
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: `${CurrentTheme === Themes.dark ? theme.palette.grey[50] : theme.palette.grey[200]}`,
      opacity: 1
    },
    '& .MuiSwitch-switchBase.MuiSwitch-switchBase': {
      padding: '0',
      color: '#fff',
      marginTop: '2px',
      marginLeft: '2px'
    },
    '& .MuiSwitch-switchBase.MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(22px)',
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 0
    },
    '& .MuiCheckbox-root': {
      color: '#fff'
    },
    '& .MuiSwitch-thumb': {
      color: '#fff',
      width: '20px',
      height: "20px"
    },
    '& .MuiSwitch-track': {
      height: '20px',
      width: '42px',
      marginTop: '2px',
      marginLeft: '2px'
    },
    '& .MuiSwitch-root': {
      width: '50px',
      height: '28px',
      border: '2px solid #FFFFFF',
      borderRadius: '14px',
      boxSizing: 'border-box',
    },
  },
  switchChecked: {
    color: '#2EF2F1!important',
    transform: 'translateX(22px) !important',
  },
  saleTitle: {
    marginTop: '26px',
    fontSize: '15px',
    fontWeight: 400,
    color: '#fff',
    marginRight: '30px'
  },
  switchContent: {
    paddingBottom: '26px'
  },
  inputs: {
    height: '42px',
    lineHeight: '42px',
    '& input': {
      minHeight: '42px',
      height: '42px',
    }
  },
  collectionItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: "8px"
  },
  collectionMsg: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    '& img': {
      width: '32px',
      height: '32px',
      borderRadius: '50%',
      marginRight: '12px'
    }
  },
  collectionNum: {
    fontSize: '13px',
    color: '#fff',
    fontWeight: 400
  },
  filterRoot: {
    width: '100%',
    height: '100%',
    marginTop: '-24px',
    // paddingRight: '32px',
  },
  switchBase: {
    padding: '0 !important'
  },
  chectboxRoot: {
    color: '#fff'
  },
  chectboxChecked: {
    color: '#2EF2F1 !important'
  }
}));
