import { makeStyles, Theme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core';

export const useShowMoreStyle = makeStyles<Theme>(theme => ({
  root: {
    fontSize: '15px',
    lineHeight: '20px',
    color: fade(theme.palette.common.white, .8),
    fontWeight: 400,
    fontFamily: 'Roboto Condensed',
    wordBreak: 'break-all',
    [theme.breakpoints.down('md')]: {
      paddingTop:'10px',
      fontSize: '12px',
      lineHeight: '18px',
    },

  },
  showMoreText: {
    display:'block',
    color: `${theme.palette.grey[200]}`,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    cursor:'pointer',
    paddingTop:'6px',
    [theme.breakpoints.down('md')]: {
      fontSize: '13px',
    },
  },
}));
