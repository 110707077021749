// import { NoItems } from 'modules/common/components/NoItems';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { ProductCards } from 'modules/Profile/components/ProductCards';
import { ProductCardSkeleton } from 'modules/Profile/components/ProductCards/ProductCardSkeleton';
import { NftItemCard } from 'modules/CollectionDetail/components/NftItemCard';
import { Box, Typography } from '@material-ui/core';
import { useCollectedStyles } from './useCollectedStyles';
import Filters from 'modules/Profile/assets/filter.svg';
import LightFilter from 'modules/Profile/assets/light-filter.svg';
// import Layout from 'modules/Profile/assets/layout.svg';
// import LayoutMore from 'modules/Profile/assets/layoutMore.svg';
import { CollectedFilter } from 'modules/CollectionDetail/components/CollectedFilter';
import { Select } from 'modules/uiKit/Select';
import { getCollectionNFTList, getCollectionProperties } from 'modules/CollectionDetail/actions/collectionAction';
// import { Button } from 'modules/uiKit/Button';
// import LiveFeed from 'modules/CollectionDetail/assets/down.png';
import { CollectionDetalilRoutesConfig } from 'modules/CollectionDetail/Routes';
import { useDispatchRequest, useQuery } from '@redux-requests/react';
import { ViewAll } from 'modules/Market/components/ViewAll';
// import downImg from '../../assets/down.png';
import { useIsMDDown } from 'modules/themes/useTheme';
// import { CollectionInfoInterface } from '../../../Collection/interface/collectionInterface';
// import { getCollectionsList } from 'modules/Collection/actions/collection';
import { CollectionFilterDialog } from 'modules/CollectionDetail/components/CollectionFilterDialog/CollectionFilterDialog';
import { NoDataComp } from '../../../common/components/NoData';
import { CurrentTheme, Themes } from '../../../themes/types';
import { t } from 'modules/i18n/utils/intl';

// const chainsList = [
//   {
//     value: 'price_asc',
//     label: t('collectionDetail.lowToHigh'),
//   },
//   {
//     value: 'price_desc',
//     label: t('collectionDetail.highToLow'),
//   },
//   {
//     value: 'create_time_desc',
//     label: t('collectionDetail.recentlyList'),
//   },
//   {
//     value: 'deadline_time_asc',
//     label: t('collectionDetail.endingSoon'),
//   },
// ]


// export const Collected: React.FC<{
//   filterVal: string;
//   setFilterVal: React.Dispatch<React.SetStateAction<string>>
// }> = function (filterVal, setFilterVal) {


export const Collected = (
  {
    filterVal,
    setFilterVal,
    setPage,
    page,
  }: {
    filterVal: string;
    setFilterVal: React.Dispatch<React.SetStateAction<string>>;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    page: number;
  }
) => {
  const chainsList = useMemo(
    () => [
      {
        value: 'price_asc',
        label: t('collectionDetail.lowToHigh'),
      },
      {
        value: 'price_desc',
        label: t('collectionDetail.highToLow'),
      },
      {
        value: 'create_time_desc',
        label: t('collectionDetail.recentlyList'),
      },
      {
        value: 'deadline_time_asc',
        label: t('collectionDetail.endingSoon'),
      },
    ],
    [],
  );
  const { address: contractAddress } = CollectionDetalilRoutesConfig.nftList.useParams();
  const [layoutNum, setLayoutNum] = useState(sessionStorage.getItem('collectionFilterFlag') === 'true' ? 4 : 6);
  const [filterFlag, setFilterFlag] = useState(sessionStorage.getItem('collectionFilterFlag') === 'true');
  const [dataList, setDataList] = React.useState([]);
  const [attributesObj, setAttributesObj] = React.useState({});
  const classes = useCollectedStyles();
  // const [collectionList, setCollectionList] = useState<CollectionInfoInterface[]>([]);
  const [dialogFlag, setDialogFlag] = useState(sessionStorage.getItem('collectionDialogFlag') === 'true');

  const dispatch = useDispatchRequest();

  const handleChains = useCallback((event: ChangeEvent<{ value: unknown }>) => {
    setDataList([])
    setFilterVal(event.target.value as string);
    const queryParameters = new URLSearchParams(window.location.search);
    const collectedFilterType = queryParameters.get('collectedFilterType') ?? '';
    const collectedFilterCurrencyList = queryParameters.get('collectedFilterCurrencyList') ?? '';
    const collectedFilterattributesList = queryParameters.get('collectedFilterattributesList') ?? '';
    dispatch(getCollectionNFTList({
      address: contractAddress,
      off_index: page,
      off_size: 50,
      order_by: event.target.value as string || '',
      list_type: collectedFilterType,
      currency_addresses: collectedFilterCurrencyList,
      properties: collectedFilterattributesList,
    }));
  },
    [contractAddress, dispatch, page, setFilterVal],
  );

  const { data: nftList } = useQuery<any>({
    type: getCollectionNFTList.toString(),
  });
  const isMobile = useIsMDDown();
  useEffect(() => {
    if (nftList) {
      page === 1 ? setDataList(nftList) : setDataList(dataList.concat(nftList));
    }
    // eslint-disable-next-line
  }, [nftList, setDataList]);

  const loadingMore = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const collectedFilterType = queryParameters.get('collectedFilterType') ?? '';
    const collectedFilterCurrencyList = queryParameters.get('collectedFilterCurrencyList') ?? '';
    const collectedFilterattributesList = queryParameters.get('collectedFilterattributesList') ?? '';
    dispatch(getCollectionNFTList({
      address: contractAddress,
      off_index: page + 1, off_size: 50,
      order_by: filterVal,
      list_type: collectedFilterType,
      currency_addresses: collectedFilterCurrencyList,
      properties: collectedFilterattributesList,
    }));
    setPage(page + 1);
  }

  useEffect(() => {
    async function loadAttributes() {
      const data: any = await getCollectionProperties({ address: contractAddress });
      let obj: any = {};
      data && Object.keys(data).map((d: string) => {
        let arr = [];
        for (let i in data[d]) {
          arr.push({
            label: i,
            value: data[d][i],
            active: `${d}:::::${i}`
          })
        }
        obj[d] = arr;
        return '';
      })
      setAttributesObj(obj);
    }
    loadAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {


  //   async function loadData() {
  //     const data: any = await getCollectionsList({ page: 1, page_size: 100 });
  //     if (data.code === 200) {
  //       setCollectionList(data.data);
  //     }
  //   }
  //
  //   loadData();
  // }, []);
  const close = () => {
    setDialogFlag(false);
    if (isMobile) {
      sessionStorage.setItem('collectionDialogFlag', (false).toString())
    } else {
      sessionStorage.setItem('collectionFilterFlag', (false).toString())
    }
  }
  return (
    <>
      <Box display={'flex'} mt={3}>
        <Box>
          {filterFlag && !isMobile &&
            <CollectedFilter
              attributesObj={attributesObj}
              price={filterVal}
              contractAddress={contractAddress}
              setPage={setPage}
            />}
        </Box>
        <Box style={{ width: "100%" }}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={3} pr={isMobile ? 0 : 2} style={{ width: "100%" }}>
            <Box display={'flex'} alignItems={'center'}>
              <Typography className={classes.borders}>
                <img src={CurrentTheme === Themes.dark ? Filters : LightFilter} alt=""
                  onClick={() => {
                    setLayoutNum(filterFlag ? 6 : 4);
                    console.log(!dialogFlag)
                    if (isMobile) {
                      setDialogFlag(!dialogFlag)
                      sessionStorage.setItem('collectionDialogFlag', (!dialogFlag).toString())
                    } else {
                      setFilterFlag(!filterFlag);
                      sessionStorage.setItem('collectionFilterFlag', (!filterFlag).toString())
                    }
                  }}
                /></Typography>
              {/* <Typography className={classes.borders}>
                <img src={Layout} onClick={() => { setLayoutNum(4) }} alt="" />
                <img src={LayoutMore} onClick={() => { setLayoutNum(6) }} className={classes.layoutMore} alt="" />
              </Typography> */}
              <Box>
                <Select
                  value={filterVal}
                  onChange={handleChains}
                  options={chainsList}
                  classes={{
                    root: classes.selectRoot,
                    icon: classes.selectIcon,
                  }}
                />
              </Box>
            </Box>
            {/* <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Box>
                <Typography className={classes.result}>{items} results</Typography>
                <Typography className={classes.seconds}>{timers}</Typography>
              </Box>
              <img src={downImg} className={classes.icons} alt="" />
            </Box> */}
          </Box>
          <ProductCards
            // isLoading={loading}
            layoutNum={layoutNum}
          >
            {(false) ? (
              <ProductCardSkeleton />
            ) : (
              dataList && dataList.length > 0 && dataList.map((item: any, index) => (
                <NftItemCard
                  key={item.TokenId}
                  layoutNum={layoutNum}
                  item={item}
                />
              ))
            )}
          </ProductCards>
          {
            dataList && dataList.length === 0 && <NoDataComp />
          }

          {nftList && nftList.length > 49 && <Box display={'flex'} justifyContent={'center'} mt={8}>
            <ViewAll text={t('public.loadMore')} emitClick={loadingMore} />
          </Box>
          }
        </Box>
        <CollectionFilterDialog
          openDialog={dialogFlag}
          onDialogClose={close}
          attributesObj={attributesObj}
          price={filterVal}
          contractAddress={contractAddress}
          setPage={setPage}
        />
      </Box>

    </>
  );
};
