import { useNoDataStyle } from './useNoDataStyle';
import { Box } from '@material-ui/core';
import { t } from 'modules/i18n/utils/intl';
interface NoDataCompInterface {
  text?: string
}

export const NoDataComp = (
  {
    text = t('public.notItem'),
  }: NoDataCompInterface,
) => {

  const classes = useNoDataStyle();

  return (
    <Box className={classes.root}>
      {text}
    </Box>
  );

};
