import { Container } from '@material-ui/core';
import { useFooterStyles } from './FooterStyles';
import { Typography } from '@material-ui/core';

import { LogoComp } from '../DFduckHeader/components/Logo';
import { FooterIcon } from './components';
import React from 'react';
// import { IDfdunkLogoData } from '../../../common/actions/commonRequest';
// import { useLocale } from 'modules/i18n/utils/useLocale';
import {t} from 'modules/i18n/utils/intl'
export const Footer = () => {

  const classes = useFooterStyles();
  // const [logoData, setLogoData] = useState<IDfdunkLogoData | null>({
  //   h5Logo: '',
  //   logo: '',
  //   nameEn: '',
  //   nameJp: '',
  //   nameZh: '',
  // });
  // const { locale } = useLocale();
  return (
    <footer className={classes.root}>
      <Container className={classes.container}>
        <div className={classes.copyright}>
          <Typography className={classes.flex}>
            <LogoComp/>
            <span className={classes.none}>© 2023 {process.env.REACT_APP_BASE_ENV_BRANCH === 'DFDUNK' ? 'Hotluuu' : 'HOTLuuu-idol'}</span>
          </Typography>
          <Typography className={classes.projectContent}>
            <span className={classes.fastNft}>
              {t('footer.fastNFT',{name: process.env.REACT_APP_BASE_ENV_BRANCH === 'DFDUNK' ? 'Hotluuu' : 'HOTLuuu-idol'})}
            </span>
          </Typography>
        </div>

        <FooterIcon />

      </Container>
    </footer>
  );
};
