import { Theme, fade } from '@material-ui/core';
import { StyleRules } from '@material-ui/styles';

export const styles = (theme: Theme): StyleRules => ({
  root: {
    width: '30px',
    height: '30px',
    border: `1px solid ${theme.palette.grey[50]}`,
    display: 'none',
    cursor: 'pointer',
    marginLeft: '10px',
    textAlign: 'center',
    [theme.breakpoints.up('xs')]: {
      width: '20px',
      height: '20px',
      display: 'block',
    },
    [theme.breakpoints.up('sm')]: {
      width: '20px',
      height: '20px',
      display: 'block',
    },
    [theme.breakpoints.up('md')]: {
      width: '30px',
      height: '30px',
      display: 'none',
    },
  },
  userIcon: {
    width: '15px',
    height: '17px',
    margin: 'auto',
    [theme.breakpoints.up('xs')]: {
      width: '11px',
      height: '11px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '11px',
      height: '11px',
    },
    [theme.breakpoints.up('md')]: {
      width: '17px',
      height: '17px',
    },
  },
  drawerBody: {
    padding: '0 20px',
    width: '200px',
    height: '100%',
    backgroundColor: theme.palette.background.default,
    borderLeft: `1px solid ${theme.palette.grey[50]}`,
    '& a:hover': {
      color: fade(theme.palette.common.white, .8),
    },
  },
  link: {
    color: theme.palette.common.white,
    '& span': {
      justifyContent: 'start',
    },
  },
  linkWrap: {
    display: 'flex',
    flexDirection: 'column',
  },
  activeLink: {
    color: fade(theme.palette.common.white, .8),
  },
});
