import { makeStyles, Theme, fade } from '@material-ui/core';

const PROFILE_AVATAR_SIZE = 145;

export const useCollectionNftListStyle = makeStyles<Theme>((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '0 auto',
    // paddingBottom: '144px',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.common.white,
    minHeight: '700px',
    paddingTop: 0,
  },
  title: {
    // fontSize: '50px',
    letterSpacing: '-0.01em',
    color: '#23262F',
    fontWeight: 700,
    paddingTop: '50px',
    fontFamily: 'Pretendard',
  },
  content: {
    fontSize: '12px',
    lineHeight: '16px',
    color: '#23262F',
    paddingTop: '25px',
    maxWidth: '643px',
    fontFamily: 'Roboto Condensed',
  },
  showMore: {
    color: '#2865EA',
    fontSize: '16px',
    lineHeight: '24px',
    cursor: 'pointer',
    fontFamily: 'Roboto Condensed',
  },
  avatar: {
    marginTop: -PROFILE_AVATAR_SIZE / 2,
    // marginBottom: theme.spacing(5),
    zIndex: 2,
    width: 125,
    height: 125,
  },
  namse: {
    marginTop: '32px',
    marginBottom: '32px',

    // position: 'relative',
    // top: '-30px',
    // left: '130px',
    // display: 'inline-block'
  },
  collectionItemName: {
    wordBreak: 'break-word',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: '40px',
    color: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  },
  address: {
    fontSize: '16px',
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    '& span': {
      fontWeight: 700,
      marginLeft: '4px',
    },
  },
  describe: {
    fontSize: '14px',
    lineHeight: '18px',
    color: fade(theme.palette.common.white, .8),
    fontWeight: 400,
    fontFamily: 'Roboto Condensed',
    [theme.breakpoints.down('sm')]: {
      marginTop: '8px',
      wordBreak: 'break-all'
    },
  },
  joined: {
    fontSize: '14px',
    color: '#697077',
    marginLeft: '10px',
  },
  tabContent: {
    position: 'relative',
  },
  tips: {
    color: 'red',
    position: 'absolute',
    top: '14px',
    left: '290px',
    [theme.breakpoints.down('sm')]: {
      top: '56px',
      left: 0,
    },
  },
  link: {
    marginLeft: '6px',
    fontWeight: 800,
    fontFamily: '"Graphik",sans-serif',
    textDecoration: 'underline',
    color: 'rgb(0,0,238)',
    '&:hover': {
      color: 'rgb(0,0,238) !important',
      opacity: '0.7',
    },
  },
  verify: {
    margin: '0 20px',
  },
  detail: {
    fontSize: '16px',
    lineHeight: '20px',
    marginTop: '12px',
    marginBottom: '16px',
    color: theme.palette.common.white,
    '& span': {
      fontWeight: 700,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  msgBox: {
    padding: '0 24px',
    // [theme.breakpoints.down('md')]: {
    //   width: '46%',
    // },
    [theme.breakpoints.down('sm')]: {
      width: '46%',
    },
  },
  diffBox: {
    paddingLeft: 0,
  },
  msgtitles: {
    color: fade(theme.palette.common.white, .8),
    fontSize: '12px',
    lineHeight: '12px',
    marginBottom: '6px',
  },
  msgVal: {
    color: theme.palette.common.white,
    fontSize: '32px',
    lineHeight: '40px',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '20px',
      height: '40px',
      marginLeft: '2px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
      '& img': {
        width: '15px',
        height: '30px',
      },
    },
  },
  lines: {
    width: '1px',
    backgroundColor: '#E6E8EC',
    height: '56px',
  },
  loadMore: {
    backgroundColor: '#fff',
    border: '2px solid #E6E8EC',
    borderRadius: '8px',
    height: '40px',
    fontSize: '14px',
    color: '#23262F',
  },
}));

