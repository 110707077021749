import { makeStyles, Theme } from '@material-ui/core';

export const useUserAddressStyle = makeStyles<Theme>((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  left: {
    paddingLeft: '55px',
    position: 'relative',
  },
  avatar: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    position: 'absolute',
    left: '0',
    top: '0',
  },

  logOutImg: {
    width: '26px',
    height: '26px',
    cursor:'pointer',
  },
  address: {
    fontSize: '20px',
    color: theme.palette.common.white,
    fontFamily: 'Pretendard',
    fontStyle: 'normal',
    fontWeight: 700,
  },

}));

