import { makeStyles, Theme } from '@material-ui/core/styles';

export const useDFduckLayoutStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 375,
    background: theme.palette.background.default,
  },
  mainRoot: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 375,
    background: theme.palette.background.default,
    paddingBottom:'50px'
  },

  darkBg: {
    background: theme.palette.background.default,
  },

  main: {
    flexGrow: 1,
  },
}));
