import { fade, makeStyles, Theme } from '@material-ui/core/styles';
import SearchInputBg from 'modules/layout/components/DFduckHeader/assets/search.png';
import LightSearchInputBg from 'modules/layout/components/DFduckHeader/assets/light-search.png';
import { CurrentTheme, Themes } from 'modules/themes/types';

export const useFilterSearchStyle = makeStyles<Theme>(theme => ({
  root: {
    background: 'transparent',
    border: 'none',
    color: theme.palette.common.black,
    fontSize: '15px',
    padding: 0,
    width: '100%',
    [theme.breakpoints.up('xs')]: {},
    [theme.breakpoints.up('sm')]: {},
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {},
    [theme.breakpoints.up('xl')]: {},
    [theme.breakpoints.up('HD')]: {},
    [theme.breakpoints.up('WXGAPlus')]: {},

    '& [class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
    '& .MuiAutocomplete-endAdornment-66': {
      display: 'none',
    },
    [theme.breakpoints.up('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  paper: {
    borderRadius: 0,
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.grey[50]}`,
    margin: 0,
    '& .jss60':{
      color:theme.palette.grey[50],
    },
    '& MuiAutocomplete-noOptions-90':{
      color:theme.palette.grey[50],
    }
  },
  listbox: {
    display: 'flex',
    flexFlow: 'row wrap',
    '& li': {
      backgroundColor: 'transparent',
      padding: '0',
      borderRadius: '60px',
      margin: '6px',
    },
    '.MuiAutocomplete-option-84[data-focus="true"]': {
      backgroundColor: 'transparent !important',
    },
  },
  loading: {
    color:theme.palette.grey[50],
  },
  noOptions: {
    color:`${theme.palette.grey[50]} !important`,
    fontSize:'12px'
  },
  endAdornment: {
    display: 'none',
  },
  item: {
    minWidth: '208px',
    height: '56px',
    display: 'flex',
    flexDirection: 'column',
    padding: '9px 10px 10px 60px',
    // backgroundColor: fade(theme.palette.common.white, .08),
    border: 0,
    borderRadius: 60,
    position: 'relative',
  },
  collectorLogoWrap: {
    position: 'absolute',
    width: '38px',
    height: '38px',
    borderRadius: '50%',
    // border: `1px solid ${theme.palette.common.white}`,
    overflow: 'hidden',
    display: 'flex',
    left: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  collectorLogo: {
    borderRadius: '50%',
    margin: 'auto',
    width: '38px',
    height: '38px',
  },
  // itemWrap: {
  //   width: '100%',
  //   height: '100%',
  //   borderRadius: 0,
  //   backgroundColor: theme.palette.background.default,
  // },
  collectorRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  collectorName: {
    fontFamily: 'Pretendard',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.white,
    paddingBottom: '5px',
    '& img': {
      width: '13px',
      height: '13px',
      marginLeft: '10px',
    },
  },
  collectorInfo: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Pretendard',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: fade(theme.palette.common.white, .7),
  },

  collectorPrice: {
    marginLeft: '8px',
    display:'flex',
    alignItems:'center',
  },
  baseName:{
    fontSize:'11px',
    zoom:0.8,
    marginLeft:'5px'
  },
  searchInput: {
    height: '42px',
    width: '100%',
    backgroundImage: `url(${CurrentTheme === Themes.dark ? SearchInputBg : LightSearchInputBg})`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    color: theme.palette.common.black,
    '& input': {
      minHeight: '42px',
      'padding': '0 20px !important',
    },
  },
}));
