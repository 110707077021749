import { default as ActressMintRaw } from './actress_contract.json';
import { default as DfdunkMintRaw } from './dfdunk_contract.json';
import { default as MarketRaw } from './market_contract.json';
import { default as ERC20Raw } from './erc20_contract.json';
import { AbiItem } from 'web3-utils';


export const ActressMint: AbiItem = ActressMintRaw as unknown as AbiItem;
export const DfdunkMint: AbiItem = DfdunkMintRaw as unknown as AbiItem;
export const MarketABI: AbiItem = MarketRaw as unknown as AbiItem;
export const ERC20ABI: AbiItem = ERC20Raw as unknown as AbiItem;
