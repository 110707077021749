import { Container } from '@material-ui/core';
import { useAccount } from 'modules/account/hooks/useAccount';
import {
  Box
} from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { uid } from 'react-uid';
import { useCollectionNftListStyle } from './useCollectionNftListStyle';
import { TabPanel } from 'modules/Profile/components/TabPanel';
import { Tabs } from 'modules/Profile/components/Tabs';
import { Tab } from 'modules/Profile/components/Tabs/Tab';
import { Section } from 'modules/uiKit/Section';

import { CollectionListRoutesConfig } from '../Routes';
import { Collected } from '../components/collected';
import { t } from 'modules/i18n/utils/intl';
export enum CollectionTab {
  collections = 'Items',
  activity = 'Activity',
}

export const CollectionNftList = () => {

  const { tab } = CollectionListRoutesConfig.nftList.useParams();
  const classes = useCollectionNftListStyle();
  const { address, chainId, } = useAccount();
  const { push } = useHistory();

  const updateData = useCallback(
    (value: CollectionTab) => {
      if (!address) {
        return;
      }
      switch (value) {
        case CollectionTab.collections: {
          // dispatch(queryMyBrandItem(address));
          break;
        }
        default: {
          console.error('not match tab', value);
        }
      }
    },
    [address,
      // dispatch, chainId
    ],
  );

  const onTabsChange = useCallback((_, value) => {
    push(CollectionListRoutesConfig.nftList.generatePath(value));
    updateData(value);
  },
    [push, updateData],
  );

  useEffect(() => {
    updateData(tab);
  }, [updateData, tab, chainId]);


  const tabs = useMemo(
    () => [
      {
        value: CollectionTab.collections,
        label:t('collectionDetail.items'),
      }
      // , {
      //   value: CollectionTab.activity,
      //   label: 'Activities',
      // },
    ],
    [],
  );

  return (
    <Section className={classes.root}>
      <Container>
        <Box className={classes.tabContent}>
          <Tabs
            className={classes.tabs}
            onChange={onTabsChange as any}
            value={tab}
          >
            {tabs.map(tabProps => (
              <Tab key={uid(tabProps)} {...tabProps} />
            ))}
          </Tabs>
        </Box>

        <TabPanel value={tab} index={CollectionTab.collections}>
          <Collected />
        </TabPanel>
      </Container>
    </Section>
  )
};
