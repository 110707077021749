// import { NoItems } from 'modules/common/components/NoItems';
import { uid } from 'react-uid';
import { ProductCards } from 'modules/Profile/components/ProductCards';
import { ProductCardSkeleton } from 'modules/Profile/components/ProductCards/ProductCardSkeleton';
import { NftItemCard } from 'modules/Profile/components/NftItemCard';
import { Box } from '@material-ui/core';
import { useFavoritedStyles } from './useFavoritedStyles';
import { useQuery } from '@redux-requests/react';
import { getUserFavorite } from 'modules/Profile/actions/profile';


export const Favorited: React.FC<{
  reload?: () => void;
}> = function ({ reload }) {
  const classes = useFavoritedStyles();
  // const dataList = [{}, {}, {}, {}, {}, {}, {}];

  const { data: dataFavorited } = useQuery<any>({
    type: getUserFavorite.toString(),
  });
  return (
    <>
      <Box className={classes.root}>
        <ProductCards
          // isLoading={loading}
          layoutNum={6}
        >
          {(false) ? (
            <ProductCardSkeleton />
          ) : (
            dataFavorited && dataFavorited.assetList && dataFavorited.assetList?.map((item: any) => (
              <NftItemCard
                key={uid(item)}
                layoutNum={6}
                // reload={reload}
                item={item}
                types={false}
              />
            ))
          )}
        </ProductCards>
      </Box>

    </>
  );
};
