import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { Route } from 'react-router-dom';
import { useParams } from 'react-router';
import { generatePath } from 'react-router-dom';

const PATH_INFEX = '/nft-detail/:contractAddress/:tokenId/:type';

export const NftDetailRoutesConfig: { [key: string]: RouteConfiguration } = {
  NftDetail: {
    path: PATH_INFEX,
    generatePath: (contractAddress: string, tokenId: string, type: string) => generatePath(PATH_INFEX, { contractAddress, tokenId, type }),
    useParams: () => {
      const { contractAddress, tokenId, type } = useParams<{
        contractAddress: string;
        tokenId: string;
        type: string;
      }>();
      return {
        contractAddress,
        tokenId,
        type
      };
    },
  },
};


const LoadableNftDetailContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens').then(module => module.NftDetail),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export function NftDetailRoutes() {
  return (
    <>
      <Route
        path={NftDetailRoutesConfig.NftDetail.path}
        exact={true}
        component={LoadableNftDetailContainer}
      />
    </>
  );
}
