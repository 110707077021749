import { makeStyles, Theme } from '@material-ui/core';
import { CurrentTheme, Themes } from '../../../themes/types';

export const useCollectedFilterStyle = makeStyles<Theme>(theme => ({
  root: {
    width: '300px',
    borderRight: `1px solid ${theme.palette.grey[50]}`,
    height: '100%',
    marginTop: '-24px',
    paddingRight: '32px',
    marginRight: '16px',
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: `${CurrentTheme === Themes.dark ? theme.palette.grey[50] : theme.palette.grey[200]}`,
      opacity: 1
    },
    '& .MuiSwitch-switchBase.MuiSwitch-switchBase': {
      padding: '0',
      color: '#fff',
      marginTop: '2px',
      marginLeft: '2px'
    },
    '& .MuiSwitch-switchBase.MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(22px)',
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 0
    },
    '& .MuiCheckbox-root': {
      color: '#fff'
    },
    '& .MuiSwitch-thumb': {
      color: '#fff',
      width: '20px',
      height: "20px"
    },
    '& .MuiSwitch-track': {
      height: '20px',
      width: '42px',
      marginTop: '2px',
      marginLeft: '2px'
    },
    '& .MuiSwitch-root': {
      width: '50px',
      height: '28px',
      border: '2px solid #FFFFFF',
      borderRadius: '14px',
      boxSizing: 'border-box',
    },
    '& .Mui-checked .MuiIconButton-label': {
      // color: '#2EF2F1'
    },
  },
  switchRoot: {
    width: '50px',
    height: '28px',
    border: '2px solid #FFFFFF',
    borderRadius: '14px',
    boxSizing: 'border-box'
  },
  switchChecked: {
    color: '#fff!important',
  },
  saleTitle: {
    marginTop: '26px',
    fontSize: '15px',
    fontWeight: 400,
    color: '#fff',
    marginRight: '30px'
  },
  switchContent: {
    paddingBottom: '26px'
  },
  inputs: {
    height: '42px',
    lineHeight: '42px',
    '& input': {
      minHeight: '42px',
      height: '42px',
    }
  },
  collectionItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: "8px"
  },
  collectionMsg: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    '& img': {
      width: '32px',
      height: '32px',
      borderRadius: '50%',
      marginRight: '12px'
    }
  },
  collectionNum: {
    fontSize: '13px',
    color: '#fff',
    fontWeight: 400
  },
  checkouts: {
  },
  filter: {
    borderTop: '1px solid #fff',
    padding: '10px 0'
  }
}));

