import { makeStyles, Theme } from '@material-ui/core';

export const useNftAttributesStyle = makeStyles<Theme>((theme: any) => ({
  root: {
    width: '100%',
    marginTop: '12px',
    color: '#fff'
  },
  activity: {
    width: '100%'
  },
  activityItem: {
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    marginBottom: '16px',
    '&:last-child': {
      marginBottom: '0',
    },
  },
  activityType: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: '16px',
    '& img': {
      marginRight: '12px'
    }
  },
  ethVal: {
    fontSize: '15px',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginTop: '-4px',
      width: '10px',
      marginRight: '6px'
    }
  },
  usdtVal: {
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: '12px',
    marginTop: '4px'
  },
  link: {
    width: '34px',
    height: '34px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  itemSummary: {
    height: '58px',
    lineHeight: '58px',
  },
  accordion: {
    margin: '0!important',
    borderRadius: '0px!important',
    overflow: 'hidden',
    backgroundColor: "transparent !important",
    borderTop: '1px solid #fff',
  },
  itemExpanded: {
    minHeight: '58px!important',
    paddingLeft: 0,
    paddingRight: '10px',
    '& p': {
      lineHeight: '58px',
    }
  },
  collectionNum: {
    display:'flex',
    alignItems:'center',
    color: '#fff'
  },
  accordionDetail: {
    padding: '24px 0',
    backgroundColor: 'transparent'
  },
}));
