import { useNftItemCardStyle } from './useNftItemCardStyle';
import { Box, Typography } from '@material-ui/core';
// import ETH from 'modules/Profile/assets/eth.svg';
import DarkSell from 'modules/Profile/assets/sell.svg';
import LightSell from 'modules/Profile/assets/light-sell.svg';
import { useHistory } from 'react-router-dom';
import { CollectionDetalilRoutesConfig } from 'modules/CollectionDetail/Routes';
import { getCurrentSymbol } from 'modules/common/utils/getUSDTprice';
import { getCollectionDetail } from 'modules/CollectionDetail/actions/collectionAction';
import { useQuery } from '@redux-requests/react';
import { TokenImgComp } from 'modules/common/components/TokenImg';
import { fromatTimersStyle } from 'modules/common/utils/getTimeRemaining';
import { CurrentTheme, Themes } from '../../../themes/types';
import { Skeleton } from '@material-ui/lab';

export const NftItemCard = ({
  item,
  layoutNum,
  types,
}: {
  item: any;
  layoutNum: number;
  types?: boolean;
}) => {
  const classes = useNftItemCardStyle();
  const history = useHistory();
  const { address: contractAddress } = CollectionDetalilRoutesConfig.nftList.useParams();
  const lastPriceAmount = item.last_price_amount || item.LastPrice || (item.recently_deal && item.recently_deal.price_amount) || 0;
  const lastPriceADDress = item.last_price_address || item.LastPriceToken || (item.recently_deal && item.recently_deal.price_contract);
  const { data: collectionItem } = useQuery<any>({
    type: getCollectionDetail.toString(),
  });

  return (
    <Box className={classes.root} onClick={() => {
      history.push(`/nft-detail/${contractAddress}/${item && item.TokenId}/type`);
    }}>

      <Box className={classes.card}>
        <Box className={classes.imgBox}>
          {(item && item.Image)? <img
            src={item && item.Image}
            className={classes.nft} alt='' />
            :<Skeleton className={classes.media} variant="rect" animation="wave"  width={'100%'} height={'100%'}/>}
        </Box>
      </Box>
      <Box className={classes.box}>
        <Typography className={classes.collectionName}>{collectionItem && collectionItem.name}</Typography>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography className={classes.id}>{item && item.Name}</Typography>
          <Typography className={classes.price}>
            {item && (item.Price > 0) && <>
              {item.PriceToken && <TokenImgComp size={'mini'} token={item.PriceToken} />}
              {item.Price}</>}
          </Typography>
        </Box>
        <Box className={classes.sellBox}>
          {item.Deadline !== 0 ? (<Typography className={classes.lastPrice}>
            Sale ends at {fromatTimersStyle(parseInt(item.Deadline.toString() + '000') || 0)}
          </Typography>) :
            (lastPriceAmount > 0 && lastPriceADDress &&
              <Typography className={classes.lastPrice}>
                Last sale: {lastPriceAmount + ' ' + getCurrentSymbol(lastPriceADDress)}
              </Typography>)
          }
          <Box className={classes.sell}>
            <Box className={classes.flex}>
              <img src={CurrentTheme === Themes.dark ? DarkSell: LightSell} alt='' />{types ? 'Sell' : 'Buy'}
            </Box>
            <Box className={classes.flex}>
              Floor:&nbsp;&nbsp;
              <TokenImgComp size={'mini'} token={'ETH'} />
              {collectionItem?.saleInfo?.floorETH.toFixed(4) < 0.00001 ? '<0.0001' : collectionItem?.saleInfo?.floorETH.toFixed(4)}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
