import { fade, makeStyles, Theme } from '@material-ui/core';
import { CurrentTheme, Themes } from '../../../themes/types';
import ConnectButton from 'modules/layout/components/DFduckHeader/assets/connectButton.png';
import LightConnectButton from 'modules/layout/components/DFduckHeader/assets/light-connectButton.png';
export const HEADER_HEIGHT_XS = 80;
export const HEADER_HEIGHT_XL = 80;


export const useHeaderStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    height: HEADER_HEIGHT_XS,
    zIndex: 999,
    top: 0,
    position: 'sticky',
    [theme.breakpoints.up('xl')]: {
      // padding: theme.spacing(2, 0),
      height: HEADER_HEIGHT_XL,
    },
  },
  left: {
    flex: 1,
    // height: '100%',
    display: 'flex',
    flexDirection:'row',
    alignItems: 'center',
  },
  right: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0, 2),
    gridTemplateColumns: 'auto auto',
    justifyContent: 'space-between',
    position: 'relative',
    height: '100%',
    // paddingLeft: theme.spacing(13.5),
    // paddingRight: theme.spacing(13.5),
    [theme.breakpoints.up('xl')]: {
      // gridTemplateColumns: 'auto auto 1fr repeat(2, auto)',
      // justifyContent: 'initial',
      // paddingLeft: theme.spacing(13.5),
      // paddingRight: theme.spacing(13.5),
    },

    // [theme.breakpoints.up('WXGAPlus')]: {
    //   paddingLeft: theme.spacing(5.5),
    //   paddingRight: theme.spacing(5.5),
    // },
  },

  link: {
    fontSize: '16px',
    paddingLeft: '20px',
    paddingRight: '20px',
    fontFamily: '"Roboto Condensed",Pretendard',
    color: theme.palette.common.white,
    '&:hover': {
      opacity: '0.6',
      color: fade(theme.palette.common.white, 0.9),
    },

    [theme.breakpoints.up('xs')]: {
      display:'none'
    },
    [theme.breakpoints.up('sm')]: {
      display:'none'
    },
    [theme.breakpoints.up('md')]: {
      display:'none'
    },
    [theme.breakpoints.up('lg')]: {
      display:'block'
    },
    [theme.breakpoints.up('xl')]: {
      display:'block'
    },
    [theme.breakpoints.up('HD')]: {
      display:'block'
    },
    [theme.breakpoints.up('WXGAPlus')]: {
      display:'block'
    },


  },
  activeLink: {
    '&:hover': {
      opacity: '0.6',
      color: fade(theme.palette.common.white, 0.9),
    },
  },
  connect: {
    height: '40px',
    width: '140px',
    backgroundImage: `url(${CurrentTheme===Themes.dark? ConnectButton: LightConnectButton})`,
    backgroundColor: 'transparent',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    marginLeft: '42px',
    borderRadius:0,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('md')]: {
      height: '30px',
      width: '90px',
      marginLeft: '12px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '30px',
      width: '90px',
      marginLeft: '0',
    },
  },


  loading: {
    animationName: '$spin',
    animationDuration: '1s',
    animationDelay: '0s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    willChange: 'transform',
    marginLeft: '10px',
  },
  '@keyframes spin': {
    '100%': { transform: 'rotate(360deg)' },
  },

}));
